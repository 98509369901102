import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[solStopClickPropagation]"
})
export class SolStopClickPropagationDirective {
  @HostListener("click", ["$event"]) onClick(e: Event): void {
    this.stopClickPropagation(e);
  }

  stopClickPropagation(event: Event): void {
    event.stopPropagation();
  }
}
