import { NgModule } from "@angular/core";
import { SolDatePipe } from "./date.pipe";
import { SolDateTimePipe } from "./date-time.pipe";
import { SolTimePipe } from "./time.pipe";
import { SolTruncatePipe } from "./truncate.pipe";
import { SolFrenchBooleanPipe } from "./french-boolean.pipe";
import { SolNullablePipe } from "./nullable.pipe";
import { SolLineFeedPipe } from "./linefeed.pipe";
import { SolDecimalPipe } from "./decimal.pipe";

const PIPES = [
  SolDatePipe,
  SolTimePipe,
  SolDateTimePipe,
  SolTruncatePipe,
  SolNullablePipe,
  SolFrenchBooleanPipe,
  SolLineFeedPipe,
  SolDecimalPipe
];

@NgModule({
  declarations: [PIPES],
  exports: [PIPES]
})
export class SolPipesModule {}
