/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - association-remote.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {BaseResourceType} from "../../../models/dto/base-resource.model";
import {CollectionTyped} from "../../../models/dto/collection-typed.model";
import {QueryParameters} from "../../../models/query-parameters/query-parameters.model";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../models/stores/base.action";
import {AssociationRemoteNameSpace} from "./association-remote-namespace.model";

export namespace AssociationRemoteAction {
  export class GetAll extends BaseAction {
    static readonly type: string = "[{0}] Association Remote : Get All";

    constructor(public parentId: string, public queryParameters?: QueryParameters, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetAllSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetAll> {
    static readonly type: string = "[{0}] Association Remote : Get All Success";

    constructor(public parentAction: GetAll, public list: CollectionTyped<TResource>) {
      super(parentAction);
    }
  }

  export class GetAllFail extends BaseSubActionFail<GetAll> {
    static readonly type: string = "[{0}] Association Remote : Get All Fail";
  }

  export class GetById extends BaseAction {
    static readonly type: string = "[{0}] Association Remote : Get By Id";

    constructor(public parentId: string, public resId: string, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetByIdSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetById> {
    static readonly type: string = "[{0}] Association Remote : Get By Id Success";

    constructor(public parentAction: GetById, public model: TResource) {
      super(parentAction);
    }
  }

  export class GetByIdFail extends BaseSubActionFail<GetById> {
    static readonly type: string = "[{0}] Association Remote : Get By Id Fail";
  }

  export class Create extends BaseAction {
    static readonly type: string = "[{0}] Association Remote : Create";

    constructor(public parentId: string, public listResId: string[]) {
      super();
    }
  }

  export class CreateSuccess extends BaseSubActionSuccess<Create> {
    static readonly type: string = "[{0}] Association Remote : Create Success";
  }

  export class CreateFail extends BaseSubActionFail<Create> {
    static readonly type: string = "[{0}] Association Remote : Create Fail";
  }

  export class Delete extends BaseAction {
    static readonly type: string = "[{0}] Association Remote : Delete";

    constructor(public parentId: string, public resId: string) {
      super();
    }
  }

  export class DeleteSuccess extends BaseSubActionSuccess<Delete> {
    static readonly type: string = "[{0}] Association Remote : Delete Success";
  }

  export class DeleteFail extends BaseSubActionFail<Delete> {
    static readonly type: string = "[{0}] Association Remote : Delete Fail";
  }

  export class DeleteList extends BaseAction {
    static readonly type: string = "[{0}] Association Remote : Delete List";

    constructor(public parentId: string, public listResId: string[]) {
      super();
    }
  }

  export class DeleteListSuccess extends BaseSubActionSuccess<DeleteList> {
    static readonly type: string = "[{0}] Association Remote : Delete  List Success";
  }

  export class DeleteListFail extends BaseSubActionFail<DeleteList> {
    static readonly type: string = "[{0}] Association Remote : Delete  List Fail";
  }

  export class Update extends BaseAction {
    static readonly type: string = "[{0}] Association Remote : Update";

    constructor(public parentId: string, public newResId: string[]) {
      super();
    }
  }

  export class UpdateSuccess extends BaseSubActionSuccess<Update> {
    static readonly type: string = "[{0}] Association Remote : Update Success";

    constructor(public parentAction: Update, public parentId: string) {
      super(parentAction);
    }
  }

  export class UpdateFail extends BaseSubActionFail<Update> {
    static readonly type: string = "[{0}] Association Remote : Update Fail";

    constructor(public parentAction: Update, public parentId: string) {
      super(parentAction);
    }
  }
}

export const associationRemoteActionNameSpace: AssociationRemoteNameSpace = AssociationRemoteAction;
