/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - constants.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

export const SOLIDIFY_CONSTANTS = {
  SEPARATOR: "/",
  URL_SEPARATOR: "/",
  FILE_SEPARATOR: "/",
  DATA_TEST_ATTRIBUTE_NAME: "data-test",
  RES_ID: "resId",
  ID: "id",
  URL_PARAM_ID: ":id",
  STRING_EMPTY: "",
  STRING_TRUE: "true",
  STRING_FALSE: "false",
  COMMA: ",",
  SEMICOLON: ";",
  EQUAL: "=",
  DOT: ".",
  UNDERSCORE: "_",
  DASH: "-",
  HASHTAG: "#",
  HREF: "href",
  PARENT_ID: "parentId",
  SUB_RESOURCE: "subResource",
  FORM_STATUS_INVALID: "INVALID",
  FORM_STATUS_VALID: "VALID",
  LINE_BREAK: "\n",
  SPACE: " ",
  DEFAULT_ENUM_VALUE_PAGE_SIZE_OPTION: 10000,
};
