/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - icon-name-partial.enum.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {PropertyName} from "../../decorators/property-name.decorator";

// @dynamic
export abstract class IconNamePartialEnum {
  @PropertyName() static readonly internalLink: string;
  @PropertyName() static readonly closeChip: string;
  @PropertyName() static readonly close: string;
  @PropertyName() static readonly defaultValue: string;
  @PropertyName() static readonly externalLink: string;
  @PropertyName() static readonly clear: string;
  @PropertyName() static readonly sortUndefined: string;
  @PropertyName() static readonly sortAscending: string;
  @PropertyName() static readonly sortDescending: string;
  @PropertyName() static readonly trueValue: string;
  @PropertyName() static readonly falseValue: string;
  @PropertyName() static readonly refresh: string;
  @PropertyName() static readonly resId: string;
  @PropertyName() static readonly more: string;
  @PropertyName() static readonly copyToClipboard: string;
  @PropertyName() static readonly offline: string;
  @PropertyName() static readonly maintenance: string;
  @PropertyName() static readonly down: string;
  @PropertyName() static readonly send: string;
  @PropertyName() static readonly search: string;
  @PropertyName() static readonly star: string;
  @PropertyName() static readonly starEmpty: string;
  @PropertyName() static readonly success: string;
  @PropertyName() static readonly information: string;
  @PropertyName() static readonly dashboard: string;
  @PropertyName() static readonly warning: string;
  @PropertyName() static readonly error: string;
  @PropertyName() static readonly history: string;
  @PropertyName() static readonly back: string;
  @PropertyName() static readonly create: string;
  @PropertyName() static readonly delete: string;
  @PropertyName() static readonly save: string;
  @PropertyName() static readonly edit: string;
  @PropertyName() static readonly downArrow: string;
  @PropertyName() static readonly zoomOut: string;
  @PropertyName() static readonly zoomIn: string;
  @PropertyName() static readonly unableToLoadApp: string;
  @PropertyName() static readonly orcid: string;
  @PropertyName() static readonly dragToSort: string;
  @PropertyName() static readonly oaiSets: string;
  @PropertyName() static readonly oaiMetadataPrefixes: string;
  @PropertyName() static readonly uploadImage: string;
}
