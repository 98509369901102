<sol-container layout="full">
  <div class="content">
    <sol-person-search-form-container></sol-person-search-form-container>
    <div class="results-and-details">
      <sol-person-search-results-container
        *ngIf="isDetailsContentVisible()"
      ></sol-person-search-results-container>
      <div class="details-content">
        <div *ngIf="isDetailsContentVisible()">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</sol-container>
