/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - abstract-base.service.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Directive,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import {
  Observable,
  Subscription,
} from "rxjs";
import {SubscriptionManager} from "../models/managers/subscription-manager.model";

@Directive()
export abstract class AbstractBaseService implements OnDestroy {
  protected readonly _subscriptionManager: SubscriptionManager = new SubscriptionManager();

  private _destroyed: boolean = false;

  private readonly _ngOnDestroyEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly ngOnDestroyObs: Observable<boolean> = this._ngOnDestroyEventEmitter.asObservable();

  get destroyed(): boolean {
    return this._destroyed;
  }

  ngOnDestroy(): void {
    if (!this._destroyed) {
      this._destroyed = true;
      this._ngOnDestroyEventEmitter.emit(true);
    } else {
      this._ngOnDestroyEventEmitter.emit(false);
    }
    this._subscriptionManager.clearAndUnsubscribeAll();
  }

  subscribe<T>(observable: Observable<T>,
               onNext?: (value: T) => void,
               onError?: (error: Error) => void,
               onComplete?: () => void): Subscription {
    return this._subscriptionManager.subscribe(observable, onNext, onError, onComplete);
  }
}
