<mat-form-field>
  <mat-select class="selection" [formControl]="control" placeholder="Plage horaire" multiple>
    <app-times-slider
      [maxControl]="maxControl"
      [minControl]="minControl"
      class="not-display-in-small-screen"
      data-test="app-times-slider"
    ></app-times-slider>
    <mat-option *ngFor="let day of days" [value]="day"> {{day}} </mat-option>
  </mat-select>
</mat-form-field>
