/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - basic.state.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {StateContext} from "@ngxs/store";
import {BaseStateModel} from "../../../models/stores/base-state.model";
import {AbstractBaseService} from "../../../services/abstract-base.service";
import {StoreUtil} from "../../../utils/stores/store.util";

export abstract class BasicState<TStateModel extends BaseStateModel> extends AbstractBaseService {
  protected readonly _state: TStateModel;
  protected readonly _stateName: string;

  get stateName(): string {
    return this._stateName;
  }

  protected constructor() {
    super();
    this._state = StoreUtil.getStateFromInstance(this);
    this._stateName = StoreUtil.getStateNameFromInstance(this);
  }

  protected _incrementLoadingCounter(ctx: StateContext<BaseStateModel>): void {
    BasicState._updateLoadingCounter(ctx, 1);
  }

  protected _decrementLoadingCounter(ctx: StateContext<BaseStateModel>): void {
    BasicState._updateLoadingCounter(ctx, -1);
  }

  private static _updateLoadingCounter(ctx: StateContext<BaseStateModel>, value: 1 | -1): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + value,
    });
  }
}
