import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CookieService } from "ngx-cookie-service";
import { SolLoginAsComponent } from "./login-as.component";

const components = [SolLoginAsComponent];
const containers = [];
const routables = [];

@NgModule({
  declarations: [...components, ...containers, ...routables],
  imports: [
    CommonModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  exports: [...components, ...containers, ...routables],
  providers: [CookieService]
})
export class SolLoginAsModule {}
