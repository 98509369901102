import { SolSort } from "common-ng/lib/models/sort";
import { SolApiResponse, SolApiRespPageInfo } from "common-ng";
import { Teaching } from "../../models/teaching.model";
import { SearchForm } from "../../models/search-form.model";

export class SearchTeachings {
  static readonly type: string = "[Teachings] Search Teachings";

  constructor(public payload?: SearchTeachingsPayload | undefined) {}
}

export interface SearchTeachingsPayload {
  sort?: SolSort;
  pagination?: SolApiRespPageInfo;
  model?: SearchForm;
}

export class SearchTeachingsSuccess {
  static readonly type: string = "[Teachings] Search Teachings success";

  constructor(public payload: SolApiResponse<Teaching>) {}
}

export class SearchTeachingsFail {
  static readonly type: string = "[Teachings] Search Teachings fail";
}

export class UpdatePagination {
  static readonly type: string = "[Teachings] Update pagination";

  constructor(public payload: SolApiRespPageInfo) {}
}

export class UpdateSorting {
  static readonly type: string = "[Teachings] Update sorting";

  constructor(public payload: SolSort) {}
}
