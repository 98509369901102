const id: string = ":id";

export enum AppRoutesEnum {
  root = "",
  home = "home",
  teachings = "teachings",
  studyPlans = "study-plans",
  mySchedule = "my-schedule",
  myCalendar = "my-calendar"
}

export enum TeachingRoutesEnum {
  details = "details"
}

export class RoutesEnum implements RoutesEnum {
  static root: string = AppRoutesEnum.root;
  static home: string = AppRoutesEnum.home;

  static teachings: string = AppRoutesEnum.teachings;
  static teachingsDetails: string = AppRoutesEnum.teachings + "/" + TeachingRoutesEnum.details;

  static studyPlans: string = AppRoutesEnum.studyPlans;
  static mySchedule: string = AppRoutesEnum.mySchedule;
}
