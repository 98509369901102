import { SearchForm } from "../models/search-form.model";
import { HttpParams } from "@angular/common/http";
import { SolApiRespPageInfo } from "common-ng";
import { SelectOptionEnum } from "../enums/select-option.enum";

export class SearchServiceBase {
  protected createHttpParams(
    filters?: SearchForm | null,
    pagination?: SolApiRespPageInfo | null | undefined
  ): HttpParams {
    let httpParams = new HttpParams();
    httpParams = this._addSearchParams(filters, httpParams);

    if (
      pagination &&
      Number.isInteger(pagination.currentPage) &&
      Number.isInteger(pagination.sizePage)
    ) {
      httpParams = httpParams
        .set("page", pagination.currentPage.toString())
        .set("size", pagination.sizePage.toString());
    }
    return httpParams;
  }

  private _addSearchParams(filters: SearchForm, httpParams: HttpParams): HttpParams {
    if (!filters) {
      return;
    }
    Object.entries(filters)
      .filter(([key, value]) => SearchServiceBase._isValid(value))
      .forEach(([key, value]) => (httpParams = httpParams.set(key, value)));
    return httpParams;
  }

  private static _isValid(value: any): boolean {
    if (value === SelectOptionEnum.defaultOption) {
      return false;
    }
    if (this._isEmptyArray(value)) {
      return false;
    }
    if (value === 0) {
      return true;
    }
    return !!value;
  }

  private static _isEmptyArray(value: any): boolean {
    return Array.isArray(value) && value.length === 0;
  }
}
