import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SolRibbonComponent } from "./ribbon.component";
import { SolPipesModule } from "../../../pipes/pipes.module";

@NgModule({
  imports: [SolPipesModule, CommonModule],
  declarations: [SolRibbonComponent],
  exports: [SolRibbonComponent]
})
export class SolRibbonModule {}
