<form [formGroup]="form" (ngSubmit)="onSubmit()" data-test="search-form" novalidate>
  <div class="form-fields">
    <ng-container
      *ngIf="isActiveSearchType(searchTypeEnum.studyPlans) || isActiveSearchType(searchTypeEnum.teachings) || forTutorial"
    >
      <mat-form-field class="not-display-in-small-screen">
        <mat-label>Niveau d'études</mat-label>
        <mat-select
          [formControlName]="formDefinition.studyLevelId"
          data-test="search-select-study-level"
        >
          <mat-option [value]="selectOptionEnum.defaultOption">Tout niveau d'études</mat-option>
          <mat-option
            *ngFor="let studyLevel of studyLevelsObs | async"
            [value]="studyLevel.levelId"
          >
            {{ studyLevel.editedLevel }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="isActiveSearchType(searchTypeEnum.teachings)">
      <app-periodicities-selector
        [control]="form.get(formDefinition.shortPeriodicities)"
        class="not-display-in-small-screen"
      >
      </app-periodicities-selector>
      <mat-form-field class="not-display-in-small-screen">
        <input
          (blur)="resetFormValueIfNotInteger(formDefinition.personId)"
          (focus)="resetFormValue(formDefinition.personId,teachers,teacher)"
          [formControlName]="formDefinition.personId"
          [matAutocomplete]="teacher"
          data-test="teacher-input"
          matInput
          placeholder="Enseignant-e"
        />
        <button
          (click)="resetFormValue(formDefinition.personId,teachers, teacher)"
          [disableRipple]="true"
          mat-button
          mat-icon-button
          matSuffix
          type="button"
        >
          <div class="mat-select-arrow arrow-down-icon"></div>
        </button>
      </mat-form-field>
      <mat-autocomplete
        #teacher="matAutocomplete"
        [displayWith]="displayTeacherName.bind(this)"
        autoActiveFirstOption
      >
        <mat-option *ngIf="isLoadingTeachers">
          <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
        </mat-option>
        <ng-container *ngIf="!isLoadingTeachers">
          <mat-option *ngFor="let teacher of teachers" [value]="teacher.personId">
            <span>{{ teacher.publicId }}</span>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
      <app-days-selector
        [control]="form.get(formDefinition.days)"
        [maxControl]="form.get(formDefinition.endHour)"
        [minControl]="form.get(formDefinition.startHour)"
        class="not-display-in-small-screen"
      >
      </app-days-selector>
      <app-languages-selector
        [control]="form.get(formDefinition.activityLanguages)"
        class="not-display-in-small-screen"
      ></app-languages-selector>
    </ng-container>
  </div>
  <div class="form-fields" class="not-display-in-small-screen">
    <ng-container *ngIf="isYearAfter2022() && isActiveSearchType(searchTypeEnum.teachings)">
      <mat-form-field>
        <mat-select
          [formControl]="form.get(formDefinition.remoteOnly)"
          placeholder="En présentiel / à distance"
          data-test="remoteOnly-select"
        >
          <mat-option [value]="selectOptionEnum.defaultOption">Tous</mat-option>
          <mat-option value="true">A distance uniquement</mat-option>
          <mat-option value="false">En présentiel</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-select
          [formControl]="form.get(formDefinition.recorded)"
          placeholder="Enregistrement"
          data-test="recorded-select"
        >
          <mat-option [value]="selectOptionEnum.defaultOption">Tous</mat-option>
          <mat-option value="true">Enregistré</mat-option>
          <mat-option value="false">Non enregistré</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
</form>
