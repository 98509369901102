/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - subscription.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {Subscription} from "rxjs";
import {
  isFunction,
  isReference,
  isTruthy,
} from "../tools/is/is.tool";

export class SubscriptionUtil {

  static isUnsubscribable(subscription: Subscription): boolean {
    return isReference(subscription) && isFunction(subscription.unsubscribe) && !subscription.closed;
  }

  static unsubscribe(subscription: Subscription): boolean {
    if (this.isUnsubscribable(subscription)) {
      subscription.unsubscribe();
      return isTruthy(subscription.closed);
    }
    return false;
  }

}
