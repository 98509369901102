/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - api.service.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import {
  Injectable,
  Optional,
} from "@angular/core";
import {Observable} from "rxjs";
import {ResourceApiPartialEnum} from "../enums/partial/resource-api-partial.enum";
import {CollectionTyped} from "../models/dto/collection-typed.model";
import {QueryParameters} from "../models/query-parameters/query-parameters.model";
import {AbstractBaseService} from "../services/abstract-base.service";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "../tools/is/is.tool";
import {ExtendEnum} from "../types/extend-enum.type";
import {MappingObjectUtil} from "../utils/mapping-object.util";
import {QueryParametersUtil} from "../utils/query-parameters.util";
import {SsrUtil} from "../utils/ssr.util";
import {Configuration} from "./configuration";
import {CustomHttpUrlEncodingCodec} from "./encoder";

@Injectable({
  providedIn: "root",
})
export class ApiService extends AbstractBaseService {
  public defaultHeaders: HttpHeaders = new HttpHeaders();
  public configuration: Configuration = new Configuration();

  constructor(protected readonly _httpClient: HttpClient, @Optional() configuration: Configuration) {
    super();
    if (configuration) {
      this.configuration = configuration;
    }
  }

  /**
   * GET
   * To get a collection of resource
   *
   * @param path The resource path
   * @param queryParameters The query parameters (page size, current page, sort field)
   * @param customParameters To apply n other query parameters.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCollection<T>(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                          observe?: "body", reportProgress?: boolean): Observable<CollectionTyped<T>>;
  public getCollection<T>(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                          observe?: "response", reportProgress?: boolean): Observable<HttpResponse<CollectionTyped<T>>>;
  public getCollection<T>(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                          observe?: "events", reportProgress?: boolean): Observable<HttpEvent<CollectionTyped<T>>>;
  public getCollection<T>(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters?: QueryParameters, customParameters?: { [key: string]: string },
                          observe: any = "body", reportProgress: boolean = false): Observable<any> {
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, queryParameters);
    const headers = this.getHeaders();

    return this._httpClient.get<CollectionTyped<T>>(`${path}`,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * GET
   * To get a list of resource
   *
   * @param path The resource path
   * @param queryParameters The query parameters (page size, current page, sort field)
   * @param customParameters To apply n other query parameters.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getList<T>(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                    observe?: "body", reportProgress?: boolean): Observable<T[]>;
  public getList<T>(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                    observe?: "response", reportProgress?: boolean): Observable<HttpResponse<T[]>>;
  public getList<T>(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                    observe?: "events", reportProgress?: boolean): Observable<HttpEvent<T[]>>;
  public getList<T>(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters?: QueryParameters, customParameters?: { [key: string]: string },
                    observe: any = "body", reportProgress: boolean = false): Observable<any> {
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, queryParameters);
    const headers = this.getHeaders();

    return this._httpClient.get<T[]>(`${path}`,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * HEAD for collection
   * To know if endpoint is authorize
   *
   * @param path The resource path
   * @param queryParameters The query parameters (page size, current page, sort field)
   * @param customParameters To apply n other query parameters.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public headCollection(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                        observe?: "body", reportProgress?: boolean): Observable<void>;
  public headCollection(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                        observe?: "response", reportProgress?: boolean): Observable<HttpResponse<void>>;
  public headCollection(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters: QueryParameters, customParameters?: { [key: string]: string },
                        observe?: "events", reportProgress?: boolean): Observable<HttpEvent<void>>;
  public headCollection(path: ExtendEnum<ResourceApiPartialEnum>, queryParameters?: QueryParameters, customParameters?: { [key: string]: string },
                        observe: any = "body", reportProgress: boolean = false): Observable<any> {
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, queryParameters);
    const headers = this.getHeaders();

    return this._httpClient.head<void>(`${path}`,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * GET BY ID
   * To get a specific resource
   *
   * @param path The resource path
   * @param resId Resource Id (GUID)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getById<T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<T>;
  public getById<T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<T>>;
  public getById<T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<T>>;
  public getById<T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    if (resId === null || resId === undefined) {
      throw new Error(`Required parameter resId was null or undefined when calling ${path}GetById.`);
    }
    const headers = this.getHeaders();

    return this.get<T>(`${path}/${SsrUtil.window?.encodeURIComponent(String(resId))}`, customParameters, observe, reportProgress);
  }

  /**
   * HEAD BY ID
   * To know if endpoint is authorize
   *
   * @param path The resource path
   * @param resId Resource Id (GUID)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public headById(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<void>;
  public headById(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<void>>;
  public headById(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<void>>;
  public headById(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    if (resId === null || resId === undefined) {
      throw new Error(`Required parameter resId was null or undefined when calling ${path}GetById.`);
    }
    const headers = this.getHeaders();

    return this.head(`${path}/${SsrUtil.window?.encodeURIComponent(String(resId))}`, customParameters, observe, reportProgress);
  }

  /**
   * GET BY ID
   * To get a specific resource
   *
   * @param path The resource path
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public get<T>(path: ExtendEnum<ResourceApiPartialEnum>, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<T>;
  public get<T>(path: ExtendEnum<ResourceApiPartialEnum>, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<T>>;
  public get<T>(path: ExtendEnum<ResourceApiPartialEnum>, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<T>>;
  public get<T>(path: ExtendEnum<ResourceApiPartialEnum>, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    const headers = this.getHeaders();
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, undefined);

    return this._httpClient.get<T>(path.toString(),
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * HEAD
   * To know if endpoint is authorize
   *
   * @param path The resource path
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public head(path: ExtendEnum<ResourceApiPartialEnum>, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<void>;
  public head(path: ExtendEnum<ResourceApiPartialEnum>, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<void>>;
  public head(path: ExtendEnum<ResourceApiPartialEnum>, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<void>>;
  public head(path: ExtendEnum<ResourceApiPartialEnum>, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    const headers = this.getHeaders();
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, undefined);

    return this._httpClient.head<void>(path.toString(),
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * DELETE
   * To delete an existing resource
   *
   * @param path The resource path
   * @param resId Resource Id (GUID)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteById<T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<T>;
  public deleteById<T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<T>>;
  public deleteById<T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<T>>;
  public deleteById<T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    if (resId === null || resId === undefined) {
      throw new Error(`Required parameter resId was null or undefined when calling ${path}DeleteById.`);
    }
    const headers = this.getHeaders();
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, undefined);

    return this._httpClient.delete<T>(`${path}/${encodeURIComponent(String(resId))}`,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    );
  }

  /**
   * PATCH
   * To edit a existing resource
   *
   * @param path The resource path
   * @param resId Resource Id (GUID)
   * @param body The body to object to update
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchById<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, body?: T, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<U>;
  public patchById<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, body?: T, customParameters?: { [key: string]: string },
                             observe?: "response", reportProgress?: boolean): Observable<HttpResponse<U>>;
  public patchById<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, body?: T, customParameters?: { [key: string]: string },
                             observe?: "events", reportProgress?: boolean): Observable<HttpEvent<U>>;
  public patchById<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, resId: string, body?: T, customParameters?: { [key: string]: string },
                             observe: any = "body", reportProgress: boolean = false): Observable<any> {
    if (resId === null || resId === undefined) {
      throw new Error("Required parameter resId was null or undefined when calling PatchById method.");
    }
    let headers = this.getHeaders();
    headers = this.addContentType(headers);
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, undefined);

    return this._httpClient.patch<T>(`${path}/${SsrUtil.window?.encodeURIComponent(String(resId))}`,
      body,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    ) as Observable<HttpEvent<U>>;
  }

  /**
   * PATCH
   * To edit a existing resource
   *
   * @param full path The resource path containing ResourceId
   * @param body The body to object to update
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patch<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<U>;
  public patch<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<U>>;
  public patch<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<U>>;
  public patch<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    let headers = this.getHeaders();
    headers = this.addContentType(headers);
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, undefined);

    return this._httpClient.patch<T>(`${path}`,
      body,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    ) as Observable<HttpEvent<U>>;
  }

  /**
   * POST
   * To create a new resource
   *
   * @param path The resource path
   * @param body The body of object to create
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public post<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<U>;
  public post<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<U>>;
  public post<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<U>>;
  public post<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    let headers = this.getHeaders();
    headers = this.addContentType(headers);
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, undefined);

    return this._httpClient.post<T>(`${path}`,
      body,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    ) as Observable<HttpEvent<U>>;
  }

  /**
   * POST
   * To create a new resource
   *
   * @param path The resource path
   * @param body The body of object to create
   * @param queryParameters The query parameters (page size, current page, sort field)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postQueryParameters<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, queryParameters?: QueryParameters, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<U>;
  public postQueryParameters<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, queryParameters?: QueryParameters, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<U>>;
  public postQueryParameters<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, queryParameters?: QueryParameters, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<U>>;
  public postQueryParameters<T, U = T>(path: ExtendEnum<ResourceApiPartialEnum>, body?: T, queryParameters?: QueryParameters, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    let headers = this.getHeaders();
    headers = this.addContentType(headers);
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, queryParameters);

    return this._httpClient.post<T>(`${path}`,
      body,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
      },
    ) as Observable<HttpEvent<U>>;
  }

  public delete<T, U = T>(path: string, body?: T, customParameters?: { [key: string]: string }, observe?: "body", reportProgress?: boolean): Observable<U>;
  public delete<T, U = T>(path: string, body?: T, customParameters?: { [key: string]: string }, observe?: "response", reportProgress?: boolean): Observable<HttpResponse<U>>;
  public delete<T, U = T>(path: string, body?: T, customParameters?: { [key: string]: string }, observe?: "events", reportProgress?: boolean): Observable<HttpEvent<U>>;
  public delete<T, U = T>(path: string, body?: T, customParameters?: { [key: string]: string }, observe: any = "body", reportProgress: boolean = false): Observable<any> {
    let headers = this.getHeaders();
    headers = this.addContentType(headers);
    const queryParametersHttp = ApiService.getQueryParameters(customParameters, undefined);

    return this._httpClient.delete<T>(`${path}`,
      {
        params: queryParametersHttp,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
        body,
      } as any,
    ) as Observable<HttpEvent<U>>;
  }

  public upload<T>(path: string, data: FormData): Observable<HttpEvent<T>> {
    let headers = this.defaultHeaders;
    headers = headers.set("ngsw-bypass", "true");
    return this._httpClient.post<any>(`${path}`, data, {
      reportProgress: true,
      observe: "events",
      headers,
    });
  }

  static getQueryParameters(customParameters: { [p: string]: string }, queryParameters: QueryParameters = undefined): HttpParams {
    let queryParametersHttp = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (isNotNullNorUndefined(customParameters)) {
      // eslint-disable-next-line guard-for-in
      for (const key in customParameters) {
        queryParametersHttp = queryParametersHttp.set(key, customParameters[key] as any);
      }
    }

    if (isNullOrUndefined(queryParameters)) {
      return queryParametersHttp;
    }

    const size = QueryParametersUtil.getPageSize(queryParameters);
    if (isNotNullNorUndefined(size)) {
      queryParametersHttp = queryParametersHttp.set("size", size as any);
    }

    const page = QueryParametersUtil.getPageIndex(queryParameters);
    if (isNotNullNorUndefined(page)) {
      queryParametersHttp = queryParametersHttp.set("page", page as any);
    }

    const sort = QueryParametersUtil.getSortString(queryParameters);
    if (isNotNullNorUndefined(sort)) {
      queryParametersHttp = queryParametersHttp.set("sort", sort as any);
    }

    const searchFilters = queryParameters.search;
    if (isNotNullNorUndefined(searchFilters)) {
      MappingObjectUtil.forEach(searchFilters.searchItems, (value: string, field: string) => {
        queryParametersHttp = queryParametersHttp.set(field, value as any);
      });
    }

    return queryParametersHttp;
  }

  private getHeaders(): HttpHeaders {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      "application/json",
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }
    return headers;
  }

  private addContentType(headers: HttpHeaders): HttpHeaders {
    const consumes: string[] = [
      "application/json",
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }
    return headers;
  }
}
