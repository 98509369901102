import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  SearchStudyPlans,
  SearchStudyPlansFail,
  SearchStudyPlansSuccess,
  UpdatePaginationStudyPlans
} from "./study-plans.action";
import { tap } from "rxjs/operators";
import { StudyPlan } from "../../models/study-plan.model";
import { SolApiResponse, SolApiRespPageInfo, SolSort, SolSortOrder } from "common-ng";
import { StudyPlanService } from "../../services/study-plan.service";
import { SearchForm } from "../../models/search-form.model";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class StudyPlansStateModel {
  studyPlans: StudyPlan[];
  isLoading: boolean;
  sort: SolSort;
  pagination: SolApiRespPageInfo;
  academicYearValueOfStudyPlans: string;
}

const defaults: StudyPlansStateModel = {
  studyPlans: [],

  isLoading: false,

  sort: {
    field: "",
    order: SolSortOrder.ASC
  } as SolSort,

  pagination: {
    currentPage: 0,
    sizePage: 10,
    totalItems: 0,
    totalPages: 0
  } as SolApiRespPageInfo,
  academicYearValueOfStudyPlans: ""
};

@State<StudyPlansStateModel>({
  name: "studyPlans",
  defaults
})
@Injectable()
export class StudyPlansState {
  constructor(private _store: Store, private _studyPlanService: StudyPlanService) {}

  @Selector()
  static getAcademicYearValueOfStudyPlans(state: StudyPlansStateModel): string {
    return state.academicYearValueOfStudyPlans;
  }

  @Selector()
  static getStudyPlans(state: StudyPlansStateModel): StudyPlan[] {
    return state.studyPlans;
  }

  @Selector()
  static getLoading(state: StudyPlansStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static getPagination(state: StudyPlansStateModel): SolApiRespPageInfo {
    return state.pagination;
  }

  @Selector()
  static getSort(state: StudyPlansStateModel): SolSort {
    return state.sort;
  }

  @Action(SearchStudyPlans, { cancelUncompleted: true })
  searchStudyPlans(
    { getState, setState, patchState, dispatch }: StateContext<StudyPlansStateModel>,
    { payload }: SearchStudyPlans
  ): Observable<SolApiResponse<StudyPlan>> {
    const model: SearchForm = payload.model;
    let selectedYear = "";
    if (model) {
      selectedYear = model.academicalYear;
    }
    patchState({
      isLoading: true,
      academicYearValueOfStudyPlans: selectedYear
    });

    let pagination: SolApiRespPageInfo = {
      ...defaults.pagination,
      sizePage: getState().pagination.sizePage
    };
    let sort = defaults.sort;

    if (payload) {
      if (payload.pagination) {
        pagination = payload.pagination;
      }
      if (!payload.sort) {
        sort = this._store.selectSnapshot(StudyPlansState.getSort);
      } else {
        sort = payload.sort;
      }
    }
    return this._studyPlanService.searchStudyPlans(model, pagination, sort).pipe(
      tap(
        (data: SolApiResponse<StudyPlan>) => {
          dispatch(new SearchStudyPlansSuccess(data));
        },
        error => {
          dispatch(new SearchStudyPlansFail());
          throw error;
        }
      )
    );
  }

  @Action(SearchStudyPlansSuccess)
  searchStudyPlansSuccess(
    { setState, getState, patchState, dispatch }: StateContext<StudyPlansStateModel>,
    { payload }: SearchStudyPlansSuccess
  ): void {
    patchState({
      studyPlans: payload._data,
      isLoading: false
    });

    dispatch(
      new UpdatePaginationStudyPlans({
        ...payload._page
      })
    );

    //dispatch(
    //  new UpdateSortingStudyPlans({
    //    //...payload._page // TODO : ...payload._page ?
    //  })
    //);
  }

  @Action(UpdatePaginationStudyPlans)
  updatePagination(
    { getState, patchState }: StateContext<StudyPlansStateModel>,
    { payload }: UpdatePaginationStudyPlans
  ): void {
    patchState({
      pagination: {
        ...defaults.pagination,
        ...payload
      }
    });
  }
}
