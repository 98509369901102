<div>
  <mat-label>
    Heure : {{displayTimesRange()}}
    <span
      class="icon-info"
      matTooltip="Fonctionnalité non disponible pour la Faculté de Sciences et la FTI."
      matTooltipPosition="right"
      matTooltipClass="no-print"
    ></span>
  </mat-label>
  <p-slider
    (onChange)="updateControlValue()"
    [(ngModel)]="values"
    [min]="MINIMUM"
    [max]="MAXIMUM"
    [step]="1"
    [range]="true"
  ></p-slider>
</div>
