import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { SearchTypeEnum } from "../../enums/search-type.enum";
import {
  GetFaculties,
  GetFacultiesFail,
  GetFacultiesSuccess,
  GetStudyLevels,
  GetStudyLevelsFail,
  GetStudyLevelsSuccess,
  GetStudyPlanDepartments,
  GetStudyPlanDepartmentsFail,
  GetStudyPlanDepartmentsSuccess,
  GetTeachingDepartments,
  GetTeachingDepartmentsFail,
  GetTeachingDepartmentsSuccess,
  UpdateActiveSearchType
} from "./search.action";
import { SearchForm } from "../../models/search-form.model";
import { Faculty } from "../../models/faculty.model";
import { StudyLevel } from "../../models/study-level.model";
import { SolApiResponse } from "common-ng";
import { tap } from "rxjs/operators";
import { StudyLevelService } from "../../services/study-level.service";
import { FacultyService } from "../../services/faculty.service";
import { StudyPlanDepartmentService } from "../../services/study-plan-department.service";
import { StudyPlanDepartment } from "../../models/study-plan-department.model";
import { TeachingDepartment } from "../../models/teaching-department.model";
import { TeachingDepartmentService } from "../../services/teaching-department.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { SelectOptionEnum } from "../../enums/select-option.enum";

export class SearchStateModel {
  form: {
    model: SearchForm;
    dirty: boolean;
    status: string;
    errors: {};
  };

  activeSearchType: SearchTypeEnum;

  faculties: Faculty[];
  studyLevels: StudyLevel[];
  studyPlanDepartments: StudyPlanDepartment[];
  teachingDepartments: TeachingDepartment[];
}

const defaults: SearchStateModel = {
  form: {
    model: new SearchForm(undefined, undefined, SelectOptionEnum.defaultOption),
    dirty: false,
    status: "",
    errors: {}
  },

  activeSearchType: SearchTypeEnum.teachings,

  faculties: [],
  studyLevels: [],
  studyPlanDepartments: [],
  teachingDepartments: []
};

@State<SearchStateModel>({
  name: "search",
  defaults
})
@Injectable()
export class SearchState {
  constructor(
    private _store: Store,
    private _facultyService: FacultyService,
    private _studyLevelService: StudyLevelService,
    private _studyPlanDepartmentService: StudyPlanDepartmentService,
    private _teachingDepartmentService: TeachingDepartmentService
  ) {}

  @Selector()
  static getForm(state: SearchStateModel): any {
    return state.form;
  }

  @Selector()
  static getFormModel(state: SearchStateModel): SearchForm {
    return state.form.model;
  }

  @Selector()
  static getActiveSearchType(state: SearchStateModel): SearchTypeEnum {
    return state.activeSearchType;
  }

  @Selector()
  static getFaculties(state: SearchStateModel): Faculty[] {
    return state.faculties;
  }

  @Selector()
  static getStudyLevels(state: SearchStateModel): StudyLevel[] {
    return state.studyLevels;
  }

  @Selector()
  static getStudyPlanDepartments(state: SearchStateModel): StudyPlanDepartment[] {
    return state.studyPlanDepartments;
  }

  @Selector()
  static getTeachingDepartments(state: SearchStateModel): TeachingDepartment[] {
    return state.teachingDepartments;
  }

  @Action(UpdateActiveSearchType)
  updateActiveSearchType(
    { patchState }: StateContext<SearchStateModel>,
    { searchType }: any
  ): void {
    patchState({
      activeSearchType: searchType
    });
  }

  @Action(GetFaculties, { cancelUncompleted: true })
  getFaculties(
    { getState, dispatch }: StateContext<SearchStateModel>,
    { payload }: GetFaculties
  ): Observable<SolApiResponse<Faculty>> {
    return this._facultyService.getByAcademicalYear(payload.academicalYear).pipe(
      tap(
        (data: SolApiResponse<Faculty>) => {
          dispatch(new GetFacultiesSuccess(data));
        },
        error => {
          dispatch(new GetFacultiesFail());
          throw error;
        }
      )
    );
  }

  @Action(GetFacultiesSuccess)
  getFacultiesSuccess(
    { setState, getState, patchState, dispatch }: StateContext<SearchStateModel>,
    { payload }: GetFacultiesSuccess
  ): void {
    patchState({
      faculties: payload._data
    });
  }

  @Action(GetStudyLevels, { cancelUncompleted: true })
  getStudyLevels(
    { getState, dispatch }: StateContext<SearchStateModel>,
    { payload }: GetStudyLevels
  ): Observable<SolApiResponse<StudyLevel>> {
    return this._studyLevelService.getByStructure(payload.structureId, payload.academicalYear).pipe(
      tap(
        (data: SolApiResponse<StudyLevel>) => {
          dispatch(new GetStudyLevelsSuccess(data));
        },
        error => {
          dispatch(new GetStudyLevelsFail());
          throw error;
        }
      )
    );
  }

  @Action(GetStudyLevelsSuccess)
  getStudyLevelsSuccess(
    { setState, getState, patchState, dispatch }: StateContext<SearchStateModel>,
    { payload }: GetStudyLevelsSuccess
  ): void {
    patchState({
      studyLevels: payload._data
    });
  }

  @Action(GetStudyPlanDepartments, { cancelUncompleted: true })
  getStudyPlanDepartments(
    { getState, dispatch }: StateContext<SearchStateModel>,
    { payload }: GetStudyPlanDepartments
  ): Observable<SolApiResponse<StudyPlanDepartment>> {
    return this._studyPlanDepartmentService
      .getByStructure(payload.structureId, payload.academicalYear)
      .pipe(
        tap(
          (data: SolApiResponse<StudyPlanDepartment>) => {
            dispatch(new GetStudyPlanDepartmentsSuccess(data));
          },
          error => {
            dispatch(new GetStudyPlanDepartmentsFail());
            throw error;
          }
        )
      );
  }

  @Action(GetStudyPlanDepartmentsSuccess)
  getStudyPlanDepartmentsSuccess(
    { setState, getState, patchState, dispatch }: StateContext<SearchStateModel>,
    { payload }: GetStudyPlanDepartmentsSuccess
  ): void {
    patchState({
      studyPlanDepartments: payload._data
    });
  }

  @Action(GetTeachingDepartments, { cancelUncompleted: true })
  getTeachingDepartments(
    { getState, dispatch }: StateContext<SearchStateModel>,
    { payload }: GetTeachingDepartments
  ): Observable<SolApiResponse<TeachingDepartment>> {
    return this._teachingDepartmentService
      .getByStructure(payload.structureId, payload.academicalYear)
      .pipe(
        tap(
          (data: SolApiResponse<StudyPlanDepartment>) => {
            dispatch(new GetTeachingDepartmentsSuccess(data));
          },
          error => {
            dispatch(new GetTeachingDepartmentsFail());
            throw error;
          }
        )
      );
  }

  @Action(GetTeachingDepartmentsSuccess)
  getTeachingDepartmentsSuccess(
    { setState, getState, patchState, dispatch }: StateContext<SearchStateModel>,
    { payload }: GetTeachingDepartmentsSuccess
  ): void {
    patchState({
      teachingDepartments: payload._data
    });
  }
}
