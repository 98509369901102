/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - error.helper.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  HttpErrorResponse,
  HttpStatusCode,
} from "@angular/common/http";
import {SolidifyError} from "../errors/solidify.error";
import {
  ErrorDto,
  ValidationErrorDto,
} from "../models/errors/error-dto.model";
import {SolidifyHttpErrorResponseModel} from "../models/errors/solidify-http-error-response.model";
import {
  isNonEmptyArray,
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "../tools/is/is.tool";

export class ErrorHelper {
  static readonly INVALID_TOKEN_ERROR: string = "invalid_token";

  static extractValidationErrors(error: SolidifyHttpErrorResponseModel | SolidifyError | Error | HttpErrorResponse): ValidationErrorDto[] {
    if (!(error instanceof HttpErrorResponse) || !ErrorHelper.isValidationErrors(error.error)) {
      return [];
    }

    return error.error.validationErrors;
  }

  static isValidationErrors(value: any): value is ErrorDto {
    return isNotNullNorUndefined(value as ErrorDto)
      && (value as ErrorDto).statusCode === HttpStatusCode.BadRequest
      && isNonEmptyArray((value as ErrorDto).validationErrors);
  }

  static isErrorToTreat(error: SolidifyHttpErrorResponseModel, httpErrorKeyToSkipInErrorHandler: string[]): boolean {
    if (error.status === HttpStatusCode.Unauthorized) {
      return true;
    }
    if (ErrorHelper.isValidationErrors(error.error)) {
      return false;
    }
    if (![
      HttpStatusCode.BadRequest,
      HttpStatusCode.NotFound,
      HttpStatusCode.InternalServerError,
      HttpStatusCode.Forbidden,
    ].includes(error.status)) {
      return false;
    }
    const errorDto = error.error as ErrorDto;
    if (isNullOrUndefined(errorDto)) {
      return false;
    }
    if (isNonEmptyArray(httpErrorKeyToSkipInErrorHandler) && httpErrorKeyToSkipInErrorHandler.includes(errorDto.message)) {
      return false;
    }
    return true;
  }
}
