import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class RoutingHistoryService {
  private _urls: string[] = [];

  constructor(private _router: Router) {}

  recordUrls(): void {
    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ url }: NavigationEnd) => this._urls.push(url));
  }

  hasPreviousUrl(): boolean {
    return this._urls.length > 1;
  }
}
