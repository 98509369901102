/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - object.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  isNullOrUndefined,
  isObject,
  isTrue,
  isTruthyObject,
  isWhiteString,
} from "../tools/is/is.tool";
import {SolidifyObject} from "../types/solidify-object.type";

// @dynamic
export class ObjectUtil {
  /**
   * Warning, by default it's not a deep copy ! Deep copy involve low performance ! Use with caution
   *
   * @param object
   * @param isDeep
   */
  static clone<T>(object: T, isDeep: boolean = false): T {
    if (isTrue(isDeep)) {
      return this._cloneDeep(object);
    }
    return Object.assign({}, object);
  }

  private static _cloneDeep<T>(object: T): T {
    return this.mergeDeep({}, object as SolidifyObject) as T;
  }

  static getValue(object: SolidifyObject, key: string, defaultValue: any = undefined): any {
    if (isNullOrUndefined(object)) {
      return defaultValue;
    }
    return object[key];
  }

  static getNestedValue(object: SolidifyObject, nestedKey: string, defaultValue: any = undefined): any {
    if (isNullOrUndefined(nestedKey) || isWhiteString(nestedKey) || isNullOrUndefined(object)) {
      return defaultValue;
    }
    nestedKey = nestedKey.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    nestedKey = nestedKey.replace(/^\./, "");           // strip a leading dot
    const listKey = nestedKey.split(".");
    for (let i = 0, n = listKey.length; i < n; ++i) {
      const key = listKey[i];
      if (isNullOrUndefined(object) || isNullOrUndefined(key)) {
        return defaultValue;
      }
      if (key in object) {
        object = object[key] as SolidifyObject;
      } else {
        return defaultValue;
      }
    }
    return object;
  }

  static keys(object: SolidifyObject): string[] {
    if (!isTruthyObject(object)) {
      return [];
    } else {
      return Object.keys(object);
    }
  }

  static values(object: SolidifyObject): any[] {
    if (!isTruthyObject(object)) {
      return [];
    } else {
      return Object.values(object);
    }
  }

  static hasKey(object: SolidifyObject, key: string): boolean {
    const keys = this.keys(object);
    return keys.includes(key);
  }

  static clearKey(object: SolidifyObject, key: string): boolean {
    return this.hasKey(object, key) && delete object[key];
  }

  static clearKeys(object: SolidifyObject): number {
    return this.keys(object).reduce((result, key) => {
      if (delete object[key]) {
        return result + 1;
      } else {
        return result;
      }
    }, 0);
  }

  static merge(target: SolidifyObject, ...sources: SolidifyObject[]): SolidifyObject {
    return Object.assign(target, ...sources);
  }

  /**
   * Deep merge two objects.
   *
   * @param target
   * @param ...sources
   */
  static mergeDeep(target: SolidifyObject, ...sources: SolidifyObject[]): SolidifyObject {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, {[key]: {}});
          }
          this.mergeDeep(target[key] as SolidifyObject, source[key] as SolidifyObject);
        } else {
          Object.assign(target, {[key]: source[key]});
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }
}

