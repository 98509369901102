import { SearchTypeEnum } from "../../enums/search-type.enum";
import { SolApiResponse } from "common-ng";
import { Faculty } from "../../models/faculty.model";
import { StudyLevel } from "../../models/study-level.model";
import { StudyPlanDepartment } from "../../models/study-plan-department.model";
import { TeachingDepartment } from "../../models/teaching-department.model";

export class UpdateActiveSearchType {
  static readonly type: string = "[Search] Update ActiveSearchType";

  constructor(public searchType: SearchTypeEnum) {}
}

export class GetFaculties {
  static readonly type: string = "[Search] Get Faculties";

  constructor(public payload?: GetFacultiesPayload | undefined) {}
}

export interface GetFacultiesPayload {
  academicalYear?: string;
}

export class GetFacultiesSuccess {
  static readonly type: string = "[Search] Get Faculties Success";

  constructor(public payload: SolApiResponse<Faculty>) {}
}

export class GetFacultiesFail {
  static readonly type: string = "[Search] Get Faculties Fail";
}

export class GetStudyLevels {
  static readonly type: string = "[Search] Get StudyLevels";

  constructor(public payload?: GetStudyLevelsPayload | undefined) {}
}

export interface GetStudyLevelsPayload {
  structureId?: string;
  academicalYear?: string;
}

export class GetStudyLevelsSuccess {
  static readonly type: string = "[Search] Get StudyLevels Success";

  constructor(public payload: SolApiResponse<StudyLevel>) {}
}

export class GetStudyLevelsFail {
  static readonly type: string = "[Search] Get StudyLevels Fail";
}

export class GetStudyPlanDepartments {
  static readonly type: string = "[Search] Get StudyPlanDepartments";

  constructor(public payload?: GetStudyPlanDepartmentsPayload | undefined) {}
}

export interface GetStudyPlanDepartmentsPayload {
  structureId?: string;
  academicalYear?: string;
}

export class GetStudyPlanDepartmentsSuccess {
  static readonly type: string = "[Search] Get StudyPlanDepartments Success";

  constructor(public payload: SolApiResponse<StudyPlanDepartment>) {}
}

export class GetStudyPlanDepartmentsFail {
  static readonly type: string = "[Search] Get StudyPlanDepartments Fail";
}
export class GetTeachingDepartments {
  static readonly type: string = "[Search] Get TeachingDepartments";

  constructor(public payload?: GetTeachingDepartmentsPayload | undefined) {}
}

export interface GetTeachingDepartmentsPayload {
  structureId?: string;
  academicalYear?: string;
}

export class GetTeachingDepartmentsSuccess {
  static readonly type: string = "[Search] Get TeachingDepartments Success";

  constructor(public payload: SolApiResponse<TeachingDepartment>) {}
}

export class GetTeachingDepartmentsFail {
  static readonly type: string = "[Search] Get TeachingDepartments Fail";
}
