import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "app-tutorial-presentational",
  templateUrl: "./tutorial.presentational.html",
  styleUrls: ["./tutorial.presentational.scss"]
})
export class TutorialPresentational implements OnInit {
  private readonly _dismissEventEmitter: EventEmitter<undefined> = new EventEmitter<undefined>();
  currentPage: number = 1;
  finalPage: number = 11;

  @Output("dismiss")
  readonly dismissObs: Observable<undefined> = this._dismissEventEmitter.asObservable();

  constructor() {}

  ngOnInit(): void {}

  onDismissClick(): void {
    this._dismissEventEmitter.emit();
  }

  onPageClick(page: number): void {
    this.currentPage = page;
  }

  onPreviousClick(): void {
    this.currentPage--;
  }

  onNextClick(): void {
    if (this.currentPage === this.finalPage) {
      this.onDismissClick();
    } else {
      this.currentPage++;
    }
  }
}
