<input
  #fileInput
  (change)="onFileChange($event)"
  (click)="onClick()"
  [accept]="acceptedFormat"
  class="input-file"
  type="file"
/>
<input type="text" [(ngModel)]="value" class="input-tmp" />
<button type="button" mat-raised-button (click)="fileInput.click()">
  <mat-icon>folder_open</mat-icon>
  <span i18n>Choisir un fichier</span>
</button>
