<div class="container" [class.background-container]="!showNavigation()">
  <app-navigation-container
    *ngIf="showNavigation()"
    [class.shadow]="scrolled"
  ></app-navigation-container>
  <div class="content" [class.content-schedule]="getContentScheduleClass()">
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <a id="help" (click)="toggleHelp()" *ngIf="showNavigation()">
      <img src="assets/images/help.svg" />
      <span>Besoin d’aide?</span>
    </a>
  </div>
  <div class="footer-mobile" [class.absolute]="!showNavigation()">
    <a href="https://www.unige.ch/" class="link">Unige.ch</a>
    <a href="https://portail.unige.ch/" class="link">Portail</a>
    <div class="copyright">© 2020 Université de Genève</div>
  </div>
</div>
<app-tutorial-presentational
  *ngIf="showTutorial && showNavigation()"
  (dismiss)="toggleHelp()"
></app-tutorial-presentational>
