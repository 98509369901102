import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SolMaterialModule } from "./material.module";

const SHARED_MODULES = [CommonModule, RouterModule, SolMaterialModule, ReactiveFormsModule];

@NgModule({
  imports: [SHARED_MODULES],
  exports: [SHARED_MODULES]
})
export class SolCommonSharedModule {}
