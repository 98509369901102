import { Injectable, Optional } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ErrorMessageUtil } from "./error-message-util";
import { SolSnackBarService } from "../services/snack-bar/snack-bar.service";
import { SolSnackBarMessageType } from "../services/snack-bar/snack-bar-message-type";
import { SolSessionTimeoutService } from "../services/session-timeout/session-timeout.service";

/**
 * Intercept HTTP requests and responses in order to add new headers, modify the url to
 * automatically adding a root API url or event display snack-bar component on the UI to display
 * error message
 */
@Injectable()
export class SolHttpInterceptor implements HttpInterceptor {
  constructor(
    public matSnackBar: MatSnackBar,
    private _snackBar: SolSnackBarService,
    @Optional() private _sessionTimeoutService: SolSessionTimeoutService
  ) {}

  private _previousError: HttpErrorResponse = new HttpErrorResponse({});

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Is activated ony when the sessionTimeoutService is provided inside an application NgModule
    // "providers" property
    if (this._sessionTimeoutService) {
      // Cancel any Http request if the session has timed out
      if (this._sessionTimeoutService.isTimeoutExpired()) {
        return EMPTY;
      }
      // Any new Http request reset the session timer time
      this._sessionTimeoutService.resetTimer();
    }

    // When the request expect a JSON response from the server ensure the content-type header is
    // correctly set
    if (req.responseType === "json" && !(req.body instanceof FormData)) {
      req = req.clone({
        headers: req.headers.set("Content-Type", "application/json; charset=UTF-8")
      });

      // Use to ensure Apache OIDC module (mod_auth_openidc) return a 401 Unauthorized error when
      // OIDC session is not valid. It avoid creation of multiple state cookies which should not
      // be created when executing XHR requests. See following doc for more
      // information: https://github.com/zmartzone/mod_auth_openidc/wiki/Cookies
      req = req.clone({
        headers: req.headers.set("X-Requested-With", "XMLHttpRequest")
      });
    }

    return next.handle(req).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          // On back-end validation error: throw error and don't display _snackBar
          if (error.status === 400 && error.error && error.error.validationErrors) {
            return throwError(error);
          }
          // When refresh token error returned by the back-end: throw error and don't display _snackBar
          if (error.status === 498) {
            return throwError(error);
          }

          const config = {
            data: {
              message: ErrorMessageUtil.getErrorMessage(error)
            }
          };

          let displayReloadPageActionConfig = {};
          // error.status 0 is returned by Shibboleth when there is a session timeout.
          // error.status 401 is returned by OIDC when there is a session timeout.
          // error.status 412 is returned when there is an optimistic and pessimistic DB lock error
          // message coming from the API.
          if (error.status === 0 || error.status === 412 || error.status === 401) {
            displayReloadPageActionConfig = {
              reloadPageAction: true
            };
          }

          config.data = { ...config.data, ...displayReloadPageActionConfig };

          // Use _previousError variable to avoid displaying multiple time the same snackbar
          if (this._previousError.status !== error.status) {
            this._previousError = error;
            this._snackBar.open(this.matSnackBar, SolSnackBarMessageType.ERROR, config);
          }
        }
        return throwError(error);
      })
    );
  }
}
