import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  // Ensure previous Weblogic context still referenced in Google search and hyperlinks in documentations
  // redirect correctly to the corresponding Angular route.
  // TODO: When no more link to https://pgc.unige.ch/cursus/programme-des-cours/web... are referenced
  //   in Google search and other UNIGE docs, the following 3 paths can be removed.
  { path: "cursus/programme-des-cours/web/teachings", redirectTo: "teachings" },
  { path: "cursus/programme-des-cours/web/study-plans", redirectTo: "study-plans" },
  {
    path: "cursus/programme-des-cours/web/study-plans/details/:id",
    redirectTo: "study-plans/details/:id"
  },
  {
    path: "home",
    loadChildren: () => import("./features/home/home.feature.module").then(m => m.HomeFeatureModule)
  },
  {
    path: "teachings",
    loadChildren: () =>
      import("./features/teachings-list/teachings-list.feature.module").then(
        m => m.TeachingsListFeatureModule
      )
  },
  {
    path: "study-plans",
    loadChildren: () =>
      import("./features/study-plans-list/study-plans-list.feature.module").then(
        m => m.StudyPlansListFeatureModule
      )
  },
  {
    path: "my-schedule",
    loadChildren: () =>
      import("./features/my-schedule/my-schedule.feature.module").then(
        m => m.MyScheduleFeatureModule
      )
  },
  {
    path: "study-plans/details/:id",
    loadChildren: () =>
      import("./features/study-plan-detail/study-plan-detail.feature.module").then(
        m => m.StudyPlanDetailFeatureModule
      )
  },
  {
    path: "study-plans/details/:id/print",
    loadChildren: () =>
      import("./features/study-plan-print/study-plan-print.feature.module").then(
        m => m.StudyPlanPrintFeatureModule
      )
  },
  {
    path: "**",
    redirectTo: "home"
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: "enabled", relativeLinkResolution: "legacy" })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
