/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - button-theme.enum.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

export type ButtonThemeEnum = "button" | "flat-button" | "icon-button" | "raised-button" | "stroked-button" | "mini-fab" | "fab";
// eslint-disable-next-line
export const ButtonThemeEnum = {
  button: "button" as ButtonThemeEnum,
  flatButton: "flat-button" as ButtonThemeEnum,
  iconButton: "icon-button" as ButtonThemeEnum,
  raisedButton: "raised-button" as ButtonThemeEnum,
  strokedButton: "stroked-button" as ButtonThemeEnum,
  miniFab: "mini-fab" as ButtonThemeEnum,
  fab: "fab" as ButtonThemeEnum,
};
