/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - state-partial.enum.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

export enum StatePartialEnum {
  application = "application",
  application_banner = "application_banner",
  application_visualization = "application_visualization",
  application_global_banner = "application_global_banner",
  admin = "admin",
  admin_oaiSet = "admin_oaiSet",
  admin_oaiMetadataPrefix = "admin_oaiMetadataPrefix",
  shared = "shared",
  shared_oaiSet = "shared_oaiSet",
  shared_oaiMetadataPrefix = "shared_oaiMetadataPrefix",
  shared_organizationalUnit = "shared_organizationalUnit",
}
