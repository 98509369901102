import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "SolLineFeed"
})
export class SolLineFeedPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/\r\n/g, "\n").replace(/\r/g, "\n").replace(/\n/g, "<br>");
    } else {
      return null;
    }
  }
}
