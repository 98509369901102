import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Faculty } from "../models/faculty.model";
import { SolApiResponse, SolAppConfigService } from "common-ng";
import { SearchForm } from "../models/search-form.model";
import { SearchServiceBase } from "./search-service-base";

@Injectable({
  providedIn: "root"
})
export class FacultyService extends SearchServiceBase {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {
    super();
  }

  public getByAcademicalYear(academicalYearId: string): Observable<SolApiResponse<Faculty>> {
    const filters = new SearchForm();
    filters.academicalYear = academicalYearId;
    const httpParams = this.createHttpParams(filters);

    return this._http.get<SolApiResponse<Faculty>>(
      this._appConfig.config.apiRootUrl + "faculties?size=10000",
      {
        params: httpParams
      }
    );
  }
}
