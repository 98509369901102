import { NgModule } from "@angular/core";
import { SolCommonSharedModule } from "../../shared/shared.module";
import { SolPipesModule } from "../../pipes/pipes.module";
import { SolSidenavModule } from "./sidenav/sidenav.module";
import { SolLayoutModule } from "./layout/layout.module";
import { SolRibbonModule } from "./ribbon/ribbon.module";
import { SolContainerModule } from "./container/container.module";
import { Sol404ErrorModule } from "./error-pages/404-error/404-error.module";

@NgModule({
  imports: [
    SolCommonSharedModule,
    SolPipesModule,
    SolSidenavModule,
    SolLayoutModule,
    SolRibbonModule,
    SolContainerModule,
    Sol404ErrorModule
  ],
  exports: [
    SolSidenavModule,
    SolLayoutModule,
    SolRibbonModule,
    SolContainerModule,
    Sol404ErrorModule
  ]
})
export class SolApplicationDirectivesModule {}
