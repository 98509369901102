import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { SolAppConfigService } from "common-ng";
import { Observable } from "rxjs";
import { SearchServiceBase } from "./search-service-base";
import { StudentTimetable } from "../models/student-timetable.model";

@Injectable({
  providedIn: "root"
})
export class StudentTimetableService extends SearchServiceBase {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {
    super();
  }

  public getMyTimetableByDateRange(
    minDate: string,
    maxDate: string
  ): Observable<StudentTimetable[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("minDate", minDate);
    httpParams = httpParams.set("maxDate", maxDate);
    return this._http.get<StudentTimetable[]>(this._appConfig.config.apiRootUrl + "timetable/my", {
      params: httpParams
    });
  }

  public getMyTimetableByAcademicalYear(academicalYear: string): Observable<StudentTimetable[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("academicalYear", academicalYear);
    return this._http.get<StudentTimetable[]>(this._appConfig.config.apiRootUrl + "timetable/my", {
      params: httpParams
    });
  }

  public openCalendarIcsUrl(academicalYear: string): void {
    window.open(
      this._appConfig.config.apiRootUrl + "timetable/my/export?academicalYear=" + academicalYear
    );
  }
}
