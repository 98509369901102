import { NgModule } from "@angular/core";
import { TutorialPresentational } from "./tutorial.presentational";
import { SolCommonSharedModule } from "common-ng";
import { AcademicalYearContainerModule } from "../../../academical-year/components/containers/academical-year.container.module";
import { FilterBarContainerModule } from "../../../filter-bar/components/containers/filter-bar.container.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [TutorialPresentational],
  imports: [
    SolCommonSharedModule,
    AcademicalYearContainerModule,
    FilterBarContainerModule,
    TranslateModule
  ],
  exports: [TutorialPresentational]
})
export class TutorialPresentationalModule {}
