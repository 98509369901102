import { StudyPlanNode } from "../../models/study-plan-node.model";

export class GetStudyPlanDetails {
  static readonly type: string = "[StudyPlansDetails] Get StudyPlans Details";

  constructor(public payload: string | undefined) {}
}

export class GetStudyPlanDetailsSuccess {
  static readonly type: string = "[StudyPlansDetails] Get StudyPlans Details success";

  constructor(public payload: StudyPlanNode[]) {}
}

export class GetStudyPlanDetailsFail {
  static readonly type: string = "[StudyPlansDetails] Get StudyPlans Details fail";
}
