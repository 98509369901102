import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from "@angular/core";
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SolUpperCaseNoAccentDirective),
  multi: true
};

@Directive({
  selector: "input[solUppercaseNoAccent]",
  providers: [INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SolUpperCaseNoAccentDirective extends DefaultValueAccessor {
  private _element: HTMLInputElement;

  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
    this._element = elementRef.nativeElement;
  }

  writeValue(value: any): void {
    const transformed = this._transformValue(value);
    super.writeValue(transformed);
  }

  @HostListener("input", ["$event.target.value"])
  onInput(value: any): void {
    const start = this._element.selectionStart;
    const end = this._element.selectionEnd;
    const transformed = this._transformValue(value);
    super.writeValue(transformed);
    this.onChange(transformed);
    this._element.setSelectionRange(start, end);
  }

  private _transformValue(value: any): any {
    const result =
      value && typeof value === "string"
        ? value
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase()
        : value;
    return result;
  }
}
