import { Component, ElementRef, HostListener } from "@angular/core";
import { SolBaseComponent, SolSSOUser } from "common-ng";
import { SearchState } from "../../../../shared/stores/search/search.state";
import { Store } from "@ngxs/store";
import { UpdateActiveSearchType } from "../../../../shared/stores/search/search.action";
import { SearchTypeEnum } from "../../../../shared/enums/search-type.enum";
import { UpdateFormValue } from "@ngxs/form-plugin";
import { SearchForm } from "../../../../shared/models/search-form.model";
import { Router } from "@angular/router";
import { SelectOptionEnum } from "../../../../shared/enums/select-option.enum";
import { StudyPlanDepartment } from "../../../../shared/models/study-plan-department.model";
import { TeachingDepartment } from "../../../../shared/models/teaching-department.model";
import { Location } from "@angular/common";
import { AuthService } from "../../../../shared/services/auth-service";

declare let gtag: Function;

@Component({
  selector: "app-navigation-container",
  templateUrl: "./navigation.container.html",
  styleUrls: ["./navigation.container.scss"]
})
export class NavigationContainer extends SolBaseComponent {
  isSearchVisible: boolean = false;
  public user: any = undefined;

  constructor(
    private _store: Store,
    private _router: Router,
    private _authService: AuthService,
    private _location: Location,
    private _eRef: ElementRef
  ) {
    super();
    this._authService.user$.subscribe((data: SolSSOUser) => {
      if (data.uniqueId) {
        this.user = data;
      } else {
        this.user = undefined;
      }
    });
  }

  public isTeachings(): boolean {
    const activeSearchType = this._store.selectSnapshot(SearchState.getActiveSearchType);
    return activeSearchType === SearchTypeEnum.teachings;
  }

  public isStudyPlans(): boolean {
    const activeSearchType = this._store.selectSnapshot(SearchState.getActiveSearchType);
    return activeSearchType === SearchTypeEnum.studyPlans;
  }

  public isMySchedule(): boolean {
    if (this._router.url.indexOf("/my-schedule") > -1) {
      return true;
    }
  }

  public teachings(): void {
    this._store.dispatch(new UpdateActiveSearchType(SearchTypeEnum.teachings));
    this._router.navigate(["teachings"]);
    const searchForm = Object.assign(
      new SearchForm(),
      this._store.selectSnapshot(SearchState.getFormModel)
    );
    const test: StudyPlanDepartment[] = Object.assign(
      [],
      this._store.selectSnapshot(SearchState.getTeachingDepartments)
    );

    if (searchForm.departmentId && searchForm.departmentId !== SelectOptionEnum.defaultOption) {
      searchForm.departmentId =
        searchForm.departmentId.slice(0, 4) + searchForm.departmentId.slice(5);
    }
    if (test.filter(t => t.entityId === searchForm.departmentId).length <= 0) {
      searchForm.departmentId = null;
    }
    this._store.dispatch(
      new UpdateFormValue({
        path: "search.form",
        value: searchForm
      })
    );
  }

  @HostListener("document:click", ["$event"])
  clickout(event: MouseEvent): void {
    if (this.isMySchedule() && !this._eRef.nativeElement.contains(event.target)) {
      this.isSearchVisible = false;
      this._updateBody();
    }
  }

  public studyPlans(): void {
    this._store.dispatch(new UpdateActiveSearchType(SearchTypeEnum.studyPlans));
    this._router.navigate(["study-plans"]);
    const searchForm = Object.assign(
      new SearchForm(),
      this._store.selectSnapshot(SearchState.getFormModel)
    );
    const test: TeachingDepartment[] = Object.assign(
      [],
      this._store.selectSnapshot(SearchState.getStudyPlanDepartments)
    );

    if (searchForm.departmentId && searchForm.departmentId !== SelectOptionEnum.defaultOption) {
      searchForm.departmentId =
        searchForm.departmentId.slice(0, 4) + "-" + searchForm.departmentId.slice(4);
    }
    if (test.filter(t => t.entityId === searchForm.departmentId).length <= 0) {
      searchForm.departmentId = null;
    }
    this._store.dispatch(
      new UpdateFormValue({
        path: "search.form",
        value: searchForm
      })
    );
  }

  public mySchedule(): void {
    this._store.dispatch(new UpdateActiveSearchType(null));
    this._router.navigate(["my-schedule"]);
  }

  public resetForm(): void {
    const academicalYear = this._store.selectSnapshot(SearchState.getFormModel).academicalYear;
    const searchForm = new SearchForm();
    searchForm.academicalYear = academicalYear;
    gtag("event", "reset_form_clicked", {
      event_category: "navigation",
      event_label: "resetFormClicked"
    });
    this._store.dispatch(
      new UpdateFormValue({
        path: "search.form",
        value: searchForm
      })
    );
  }

  public toggleSearch(event: any): void {
    if (event.target.id === "search-bar" && this.isSearchVisible) {
      this.isSearchVisible = !this.isSearchVisible;
    } else if (event.target.id === "search-mobile") {
      this.isSearchVisible = !this.isSearchVisible;
    }
    this._updateBody();
  }

  public onSubmit(): void {
    if (this.isSearchVisible) {
      this.isSearchVisible = false;
      this._updateBody();
    }
  }

  private _updateBody(): void {
    if (this.isSearchVisible) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }

  hasUser(): boolean {
    return !!this.user;
  }

  login(): void {
    gtag("event", "login_clicked", {
      event_category: "navigation",
      event_label: "loginFromNavigation"
    });
    window.location.href = this._location.prepareExternalUrl(
      "login" + this._location.path().toString()
    );
  }

  logout(): void {
    gtag("event", "logout_clicked", {
      event_category: "navigation",
      event_label: "logout from navigation"
    });
    window.location.href =
      "/oidc-callback?logout=" +
      this._location.prepareExternalUrl(this._location.path().toString());
  }

  public changeSearchVisibility(): void {
    this.isSearchVisible = !this.isSearchVisible;
    this._updateBody();
    location.reload();
  }

  onNavLinkClick(label: string): void {
    gtag("event", "nav_link_click", {
      event_category: "navigation",
      event_label: `nav_link_click_${label}`
    });

    switch (label) {
      case "Cours":
        this.teachings();
        break;
      case "Plans d'études":
        this.studyPlans();
        break;
      case "Mes horaires":
        this.mySchedule();
        break;
    }
  }
}
