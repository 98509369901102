<mat-form-field>
  <mat-select
    [formControl]="control"
    placeholder="Langue d'enseignement"
    multiple
    data-test="language-selector"
  >
    <mat-option *ngFor="let langue of listLanguages" [value]="langue.label">
      {{langue.label | titlecase }}
    </mat-option>
  </mat-select>
</mat-form-field>
