import { SolSort } from "common-ng/lib/models/sort";
import { SolApiResponse, SolApiRespPageInfo } from "common-ng";
import { SearchForm } from "../../models/search-form.model";
import { StudyPlan } from "../../models/study-plan.model";

export class SearchStudyPlans {
  static readonly type: string = "[StudyPlans] Search StudyPlans";

  constructor(public payload?: SearchStudyPlansPayload | undefined) {}
}

export interface SearchStudyPlansPayload {
  sort?: SolSort;
  pagination?: SolApiRespPageInfo;
  model?: SearchForm;
}

export class SearchStudyPlansSuccess {
  static readonly type: string = "[StudyPlans] Search StudyPlans success";
  constructor(public payload: SolApiResponse<StudyPlan>) {}
}

export class SearchStudyPlansFail {
  static readonly type: string = "[StudyPlans] Search StudyPlans fail";
}

export class UpdatePaginationStudyPlans {
  static readonly type: string = "[StudyPlans] Update pagination";

  constructor(public payload: SolApiRespPageInfo) {}
}

export class UpdateSortingStudyPlans {
  static readonly type: string = "[StudyPlans] Update sorting";

  constructor(public payload: SolSort) {}
}
