/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - app-banner.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {BannerColorEnum} from "../../../core/enums/banner-color.enum";
import {StatePartialEnum} from "../../../core/enums/partial/state-partial.enum";
import {SolidifyObject} from "../../../core/types/solidify-object.type";

const state = StatePartialEnum.application_banner;

export namespace AppBannerAction {
  export class Show {
    static readonly type: string = `[${state}] Show`;

    constructor(public message: string, public color: BannerColorEnum, public parameters: SolidifyObject = {}) {
    }
  }

  export class ShowInEditMode {
    static readonly type: string = `[${state}] Show In Edit Mode`;
  }

  export class Hide {
    static readonly type: string = `[${state}] Hide`;
  }
}

export const appBannerActionNameSpace = AppBannerAction;
