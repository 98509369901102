export class SolSnackBarMessageTypeUtil {
  public static isAutodismissingMessageType(msgType: SolSnackBarMessageType): boolean {
    if (msgType === SolSnackBarMessageType.SUCCESS || msgType === SolSnackBarMessageType.INFO) {
      return true;
    } else {
      return false;
    }
  }
}

export enum SolSnackBarMessageType {
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error"
}
