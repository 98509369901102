/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - shortcut.directive.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Directive,
  EventEmitter,
  HostListener,
  Output,
} from "@angular/core";
import {Observable} from "rxjs";
import {isNullOrUndefined} from "../../tools/is/is.tool";
import {CoreAbstractDirective} from "../core-abstract/core-abstract.directive";

@Directive({
  selector: "[solidifyShortCuts]",
})
export class ShortcutDirective extends CoreAbstractDirective {

  private readonly _onEscapeEventEmitter: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @Output("onEscape")
  readonly escapeObs: Observable<KeyboardEvent> = this._onEscapeEventEmitter.asObservable();

  private readonly _onEnterEventEmitter: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @Output("onEnter")
  readonly enterObs: Observable<KeyboardEvent> = this._onEnterEventEmitter.asObservable();

  @HostListener("keydown.escape", ["$event"])
  onEscape(keyboardEvent: KeyboardEvent): void {
    if (keyboardEvent && !keyboardEvent.defaultPrevented) {
      keyboardEvent.preventDefault();
      this._onEscapeEventEmitter.emit(keyboardEvent);
    }
  }

  @HostListener("keydown.enter", ["$event"])
  onEnter(keyboardEvent: KeyboardEvent): void {
    if (keyboardEvent && !keyboardEvent.defaultPrevented
      && (isNullOrUndefined(keyboardEvent.target) || keyboardEvent.target["tagName"] !== "TEXTAREA")) {
      keyboardEvent.preventDefault();
      this._onEnterEventEmitter.emit(keyboardEvent);
    }
  }
}
