/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - resource.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {BaseResourceType} from "../../../models/dto/base-resource.model";
import {CollectionTyped} from "../../../models/dto/collection-typed.model";
import {ModelFormControlEvent} from "../../../models/forms/model-form-control-event.model";
import {QueryParameters} from "../../../models/query-parameters/query-parameters.model";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../models/stores/base.action";
import {ResourceNameSpace} from "./resource-namespace.model";

export namespace ResourceAction {
  export class LoadResource extends BaseAction {
    static readonly type: string = "[{0}] Load resource";
  }

  export class LoadResourceSuccess extends BaseSubActionSuccess<LoadResource> {
    static readonly type: string = "[{0}] Load resource Success";
  }

  export class LoadResourceFail extends BaseSubActionFail<LoadResource> {
    static readonly type: string = "[{0}] Load resource Fail";
  }

  export class ChangeQueryParameters extends BaseAction {
    static readonly type: string = "[{0}] Change Query Parameters";

    constructor(public queryParameters: QueryParameters, public keepCurrentContext: boolean = false, public getAllAfterChange: boolean = true) {
      super();
    }
  }

  export class GetAll extends BaseAction {
    static readonly type: string = "[{0}] Get All";

    constructor(public queryParameters?: QueryParameters, public keepCurrentContext: boolean = false, public cancelIncomplete: boolean = true) {
      super();
    }
  }

  export class GetAllSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetAll> {
    static readonly type: string = "[{0}] Get All Success";

    constructor(public parentAction: GetAll, public list: CollectionTyped<TResource>) {
      super(parentAction);
    }
  }

  export class GetAllFail<TResource extends BaseResourceType> extends BaseSubActionFail<GetAll> {
    static readonly type: string = "[{0}] Get All Fail";
  }

  export class GetByListId extends BaseAction {
    static readonly type: string = "[{0}] Get By List Id";

    constructor(public listResId?: string[], public keepCurrentContext: boolean = false, public cancelIncomplete: boolean = true) {
      super();
    }
  }

  export class GetByListIdSuccess extends BaseSubActionSuccess<GetAll> {
    static readonly type: string = "[{0}] Get By List Id Success";

    constructor(public parentAction: GetAll) {
      super(parentAction);
    }
  }

  export class GetByListIdFail extends BaseSubActionFail<GetAll> {
    static readonly type: string = "[{0}] Get By List Id Fail";
  }

  export class GetById extends BaseAction {
    static readonly type: string = "[{0}] Get By Id";

    constructor(public id: string, public keepCurrentContext: boolean = false, public addInListTemp: boolean = false) {
      super();
    }
  }

  export class GetByIdSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetById> {
    static readonly type: string = "[{0}] Get By Id Success";

    constructor(public parentAction: GetById, public model: TResource) {
      super(parentAction);
    }
  }

  export class GetByIdFail<TResource extends BaseResourceType> extends BaseSubActionFail<GetById> {
    static readonly type: string = "[{0}] Get By Id Fail";
  }

  export class Create<TResource extends BaseResourceType> extends BaseAction {
    static readonly type: string = "[{0}] Create";

    constructor(public modelFormControlEvent: ModelFormControlEvent<TResource>) {
      super();
    }
  }

  export class CreateSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<Create<TResource>> {
    static readonly type: string = "[{0}] Create Success";

    constructor(public parentAction: Create<TResource>, public model: TResource) {
      super(parentAction);
    }
  }

  export class CreateFail<TResource extends BaseResourceType> extends BaseSubActionFail<Create<TResource>> {
    static readonly type: string = "[{0}] Create Fail";
  }

  export class Update<TResource extends BaseResourceType> extends BaseAction {
    static readonly type: string = "[{0}] Update";

    constructor(public modelFormControlEvent: ModelFormControlEvent<TResource>) {
      super();
    }
  }

  export class UpdateSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<Update<TResource>> {
    static readonly type: string = "[{0}] Update Success";

    constructor(public parentAction: Update<TResource>, public model: TResource) {
      super(parentAction);
    }
  }

  export class UpdateFail<TResource extends BaseResourceType> extends BaseSubActionFail<Update<TResource>> {
    static readonly type: string = "[{0}] Update Fail";
  }

  export class Delete extends BaseAction {
    static readonly type: string = "[{0}] Delete";

    constructor(public resId: string) {
      super();
    }
  }

  export class DeleteSuccess extends BaseSubActionSuccess<Delete> {
    static readonly type: string = "[{0}] Delete Success";
  }

  export class DeleteFail extends BaseSubActionFail<Delete> {
    static readonly type: string = "[{0}] Delete Fail";
  }

  export class DeleteList extends BaseAction {
    static readonly type: string = "[{0}] Delete List";

    constructor(public listResId: string[]) {
      super();
    }
  }

  export class DeleteListSuccess extends BaseSubActionSuccess<DeleteList> {
    static readonly type: string = "[{0}] Delete List Success";
  }

  export class DeleteListFail extends BaseSubActionFail<DeleteList> {
    static readonly type: string = "[{0}] Delete List Fail";
  }

  export class AddInList<TResource extends BaseResourceType> extends BaseAction {
    static readonly type: string = "[{0}] Add In List";

    constructor(public model: TResource) {
      super();
    }
  }

  export class AddInListById extends BaseAction {
    static readonly type: string = "[{0}] Add In List By Id";

    constructor(public resId: string, public avoidDuplicate: boolean = false, public replace: boolean = false) {
      super();
    }
  }

  export class AddInListByIdSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<AddInListById> {
    static readonly type: string = "[{0}] Add In List By Id Success";

    constructor(public parentAction: AddInListById, public model: TResource, public indexAlreadyExisting: number) {
      super(parentAction);
    }
  }

  export class AddInListByIdFail<TResource extends BaseResourceType> extends BaseSubActionFail<AddInListById> {
    static readonly type: string = "[{0}] Add In List By Id Fail";
  }

  export class RemoveInListById extends BaseAction {
    static readonly type: string = "[{0}] Remove In List By Id";

    constructor(public resId: string) {
      super();
    }
  }

  export class RemoveInListByListId extends BaseAction {
    static readonly type: string = "[{0}] Remove In List By List Id";

    constructor(public listResId: string[]) {
      super();
    }
  }

  export class LoadNextChunkList extends BaseAction {
    static readonly type: string = "[{0}] Load Next Chunk";

    constructor(public cancelIncomplete: boolean = false) {
      super();
    }
  }

  export class LoadNextChunkListSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<LoadNextChunkList> {
    static readonly type: string = "[{0}] Load Next Chunk Success";

    constructor(public parentAction: LoadNextChunkList, public list: CollectionTyped<TResource>) {
      super(parentAction);
    }
  }

  export class LoadNextChunkListFail extends BaseSubActionFail<LoadNextChunkList> {
    static readonly type: string = "[{0}] Load Next Chunk Fail";
  }

  export class Clean extends BaseAction {
    static readonly type: string = "[{0}] Clean";

    constructor(public preserveList?: boolean, public queryParameter?: QueryParameters | undefined) {
      super();
    }
  }
}

export const resourceActionNameSpace: ResourceNameSpace = ResourceAction;
