import { Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import days from "./days";

@Component({
  selector: "app-days-selector",
  templateUrl: "./days.selector.presentational.html",
  styleUrls: ["./days.selector.presentational.scss"]
})
export class DaysSelectorPresentational {
  @Input() control: UntypedFormControl;

  @Input() minControl: UntypedFormControl;
  @Input() maxControl: UntypedFormControl;

  readonly days: string[] = days;

  constructor() {}
}
