import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SolFooterComponent } from "./footer.component";
import { SolMaterialModule } from "../../../shared/material.module";

@NgModule({
  imports: [CommonModule, SolMaterialModule],
  declarations: [SolFooterComponent],
  exports: [SolFooterComponent]
})
export class SolFooterModule {}
