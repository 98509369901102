<ng-template [ngIf]="innerHtmlOnTitle"
             [ngIfElse]="normalTitle"
>
  <h1 [matTooltip]="tooltipToTranslate | translate"
      [innerHTML]="titleToTranslate | translate:paramMessage"
      mat-dialog-title
  ></h1>
</ng-template>
<ng-template #normalTitle>
  <h1 [matTooltip]="tooltipToTranslate | translate"
      mat-dialog-title
  >{{titleToTranslate | translate:paramMessage}}</h1>
</ng-template>
<div [solidifyFocusFirstElement]="solidifyFocusFirstElement"
     mat-dialog-content
>
  <ng-content></ng-content>
</div>
