import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { SolAppConfigService } from "common-ng";
import { Observable } from "rxjs";
import { SearchServiceBase } from "./search-service-base";
import { StudentScheduleBlock } from "../models/student-schedule-block.model";

@Injectable({
  providedIn: "root"
})
export class StudentScheduleBlockService extends SearchServiceBase {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {
    super();
  }

  public updateMySelection(courseList: Map<string, boolean>): Observable<StudentScheduleBlock[]> {
    const convMap = {};
    courseList.forEach((val: boolean, key: string) => {
      convMap[key] = val;
    });
    return this._http.post<StudentScheduleBlock[]>(
      this._appConfig.config.apiRootUrl + "student-schedule-block/my/update-selection",
      convMap
    );
  }

  public customize(ssb: StudentScheduleBlock[]): Observable<Object> {
    return this._http.post<StudentScheduleBlock[]>(
      this._appConfig.config.apiRootUrl + "student-schedule-block/my/customize",
      ssb
    );
  }

  public getAllMyStudentScheduleBlock(
    academicalYear: string,
    periodicity: string
  ): Observable<StudentScheduleBlock[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("academicalYear", academicalYear);
    httpParams = httpParams.set("periodicity", periodicity);
    return this._http.get<StudentScheduleBlock[]>(
      this._appConfig.config.apiRootUrl + "student-schedule-block/my",
      {
        params: httpParams
      }
    );
  }

  public getMyStudentScheduleBlock(
    academicalYear: string,
    periodicity: string,
    visible: boolean
  ): Observable<StudentScheduleBlock[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("academicalYear", academicalYear);
    httpParams = httpParams.set("periodicity", periodicity);
    httpParams = httpParams.set("visible", visible);
    return this._http.get<StudentScheduleBlock[]>(
      this._appConfig.config.apiRootUrl + "student-schedule-block/my",
      {
        params: httpParams
      }
    );
  }

  public getMyStudentScheduleBlockSelectedByTeachingId(
    teachingId: string
  ): Observable<StudentScheduleBlock[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("teachingId", teachingId);
    return this._http.get<StudentScheduleBlock[]>(
      this._appConfig.config.apiRootUrl + "student-schedule-block/my",
      {
        params: httpParams
      }
    );
  }

  public getMyStudentScheduleBlockSelectedByGroupId(
    groupId: string
  ): Observable<StudentScheduleBlock[]> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set("groupId", groupId);
    return this._http.get<StudentScheduleBlock[]>(
      this._appConfig.config.apiRootUrl + "student-schedule-block/my",
      {
        params: httpParams
      }
    );
  }

  public deleteStudentScheduleBlockList(ssb: string[]): Observable<Object> {
    return this._http.post<string[]>(
      this._appConfig.config.apiRootUrl + "student-schedule-block/my/remove",
      ssb
    );
  }

  public deleteStudentScheduleBlock(courseId: string): Observable<Object> {
    return this._http.delete(
      this._appConfig.config.apiRootUrl + "student-schedule-block/" + courseId
    );
  }
}
