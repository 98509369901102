/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - query-parameters.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {OrderApiEnum} from "../enums/order-api.enum";
import {OrderEnum} from "../enums/order.enum";
import {Paging} from "../models/query-parameters/paging.model";
import {QueryParameters} from "../models/query-parameters/query-parameters.model";
import {
  isFalse,
  isNullOrUndefined,
} from "../tools/is/is.tool";
import {MappingObject} from "../types/mapping-type.type";
import {ObjectUtil} from "./object.util";

export class QueryParametersUtil {
  public static clone(queryParameters: QueryParameters): QueryParameters {
    if (isNullOrUndefined(queryParameters)) {
      return null;
    }
    const newQueryParameters = ObjectUtil.clone(queryParameters);
    if (!isNullOrUndefined(newQueryParameters.paging)) {
      newQueryParameters.paging = ObjectUtil.clone(newQueryParameters.paging);
    }
    if (!isNullOrUndefined(newQueryParameters.sort)) {
      newQueryParameters.sort = ObjectUtil.clone(newQueryParameters.sort);
    }
    if (!isNullOrUndefined(newQueryParameters.search)) {
      newQueryParameters.search = ObjectUtil.clone(newQueryParameters.search);
      if (!isNullOrUndefined(newQueryParameters.search.searchItems)) {
        newQueryParameters.search.searchItems = ObjectUtil.clone(newQueryParameters.search.searchItems);
      }
    }
    return newQueryParameters;
  }

  public static getSortString(queryParameters: QueryParameters): string | null {
    if (isNullOrUndefined(queryParameters) || isNullOrUndefined(queryParameters.sort)) {
      return null;
    }
    return (queryParameters.sort.field as string) + "," + (queryParameters.sort.order === OrderEnum.descending ? OrderApiEnum.descending : OrderApiEnum.ascending);
  }

  public static getPageIndex(queryParameters: QueryParameters): number | null {
    if (isNullOrUndefined(queryParameters) || isNullOrUndefined(queryParameters.paging)) {
      return null;
    }
    return queryParameters.paging.pageIndex;
  }

  public static getPageSize(queryParameters: QueryParameters): number | null {
    if (isNullOrUndefined(queryParameters) || isNullOrUndefined(queryParameters.paging)) {
      return null;
    }
    return queryParameters.paging.pageSize;
  }

  public static getSearchItems(queryParameters: QueryParameters): MappingObject<string, any> | null {
    if (isNullOrUndefined(queryParameters) || isNullOrUndefined(queryParameters.search)) {
      return null;
    }
    return queryParameters.search.searchItems;
  }

  public static resetToFirstPage(queryParameters: QueryParameters, preservePageSize: boolean = true): QueryParameters {
    if (isNullOrUndefined(queryParameters)) {
      return new QueryParameters();
    }
    queryParameters = ObjectUtil.clone(queryParameters);
    if (isNullOrUndefined(queryParameters.paging) || isFalse(preservePageSize)) {
      queryParameters.paging = new Paging();
      return queryParameters;
    }
    const paging = ObjectUtil.clone(queryParameters.paging);
    paging.pageIndex = 0;
    queryParameters.paging = paging;
    return queryParameters;
  }
}
