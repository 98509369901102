/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - subscription-manager.model.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Observable,
  Subscription,
} from "rxjs";
import {SubscriptionUtil} from "../../utils/subscription.util";

export class SubscriptionManager {

  private readonly _subscriptions: Subscription[] = [];

  geSubscriptions(): Subscription[] {
    return this._subscriptions.slice();
  }

  subscribe<T>(observable: Observable<T>,
               onNext?: (value: T) => void,
               onError?: (error: Error) => void,
               onComplete?: () => void): Subscription {
    const subscription = observable.subscribe(onNext, onError, onComplete);
    this.add(subscription);
    return subscription;
  }

  add(subscription: Subscription): boolean {
    if (SubscriptionUtil.isUnsubscribable(subscription)) {
      this._subscriptions.push(subscription);
      return true;
    }
    return false;
  }

  addMultiple(...subscriptions: Subscription[]): number {
    return subscriptions.reduce((seed, next) => seed + (this.add(next) ? 1 : 0), 0);
  }

  remove(subscription: Subscription): boolean {
    const index = this._subscriptions.indexOf(subscription);
    if (index < 0) {
      return false;
    }
    this._subscriptions.splice(index, 1);
    return true;
  }

  clear(): boolean {
    if (this._subscriptions.length === 0) {
      return false;
    }
    this._subscriptions.splice(0);
    return true;
  }

  removeAndUnsubscribe(subscription: Subscription): boolean {
    return this.remove(subscription) && SubscriptionUtil.unsubscribe(subscription);
  }

  clearAndUnsubscribeAll(): number {
    return this._subscriptions.splice(0).reduce((seed, next) => seed + (SubscriptionUtil.unsubscribe(next) ? 1 : 0), 0);
  }

}
