import { NgModule } from "@angular/core";
import { SearchBarContainer } from "./search-bar.container";
import { SharedModule } from "../../../../shared/shared.module";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { SliderModule } from "primeng/slider";
import { FormsModule } from "@angular/forms";
@NgModule({
  declarations: [SearchBarContainer],
  imports: [SharedModule, NgxsFormPluginModule, SliderModule, FormsModule],
  exports: [SearchBarContainer]
})
export class SearchBarContainerModule {}
