/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - abstract-internal.dialog.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Directive,
  Inject,
  Injector,
  Input,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import {AbstractCoreInternalComponent} from "../../abstract-core-internal/abstract-core-internal.component";
import {AbstractDialogInterface} from "../abstract/abstract-dialog-interface.model";

// @dynamic
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractInternalDialog<TData, UResult = void> extends AbstractCoreInternalComponent implements AbstractDialogInterface<TData, UResult> {
  @Input()
  paramMessage: any;

  @Input()
  titleToTranslate: string;

  @Input()
  tooltipToTranslate: string;

  @Input()
  innerHtmlOnTitle: boolean;

  @Input()
  solidifyFocusFirstElement: boolean = true;

  constructor(protected readonly _injector: Injector,
              protected readonly _dialogRef: MatDialogRef<AbstractInternalDialog<TData, UResult>, UResult>,
              @Inject(MAT_DIALOG_DATA) public readonly data: TData) {
    super(_injector);
  }

  close(): void {
    this._dialogRef.close(undefined);
  }

  submit(result: UResult): void {
    this._dialogRef.close(result);
  }
}
