import { Inject, Injectable, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class SolHelpService {
  public static CONFIG_CONTEXT: InjectionToken<string> = new InjectionToken("Help config context");

  constructor(
    private _http: HttpClient,
    @Inject(SolHelpService.CONFIG_CONTEXT)
    private _context: string
  ) {}

  private _root: string = "";

  public get root(): string {
    return this._root;
  }

  public set root(value: string) {
    this._root = value;
  }

  private _lang: string = "fr";

  public get lang(): string {
    return this._lang;
  }

  public get context(): string {
    return this._context;
  }

  public help(key: string): any {
    return this._http.get(this.url(key)).pipe(map((response: Response) => response.text()));
  }

  public url(key: string): string {
    if (!this._root && this._context) {
      const moduleId = this._context;
      const parts = moduleId.split("/");
      parts.pop();
      const root = parts.join("/");
      this._root = root;
    }

    const result = this.root + "/help/" + key + "." + this.lang + ".html";
    return result;
  }
}
