import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SolApiResponse, SolAppConfigService } from "common-ng";
import { StudyLevel } from "../models/study-level.model";
import { SearchForm } from "../models/search-form.model";
import { SearchServiceBase } from "./search-service-base";

@Injectable({
  providedIn: "root"
})
export class StudyLevelService extends SearchServiceBase {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {
    super();
  }

  public getByStructure(
    structureId: string,
    academicalYearId: string
  ): Observable<SolApiResponse<StudyLevel>> {
    const filters = new SearchForm();
    if (structureId) {
      filters.facultyId = structureId;
    }
    filters.academicalYear = academicalYearId;
    const httpParams = this.createHttpParams(filters);

    return this._http.get<SolApiResponse<StudyLevel>>(
      this._appConfig.config.apiRootUrl + "study-levels?size=10000",
      {
        params: httpParams
      }
    );
  }
}
