import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";
import { SolSnackBarMessageComponent } from "./snack-bar-message.component";
import { SolSnackBarMessageType, SolSnackBarMessageTypeUtil } from "./snack-bar-message-type";

@Injectable({ providedIn: "root" })
export class SolSnackBarService {
  constructor() {}

  public open(
    matSnackBar: MatSnackBar,
    msgType: SolSnackBarMessageType,
    config?: any
  ): MatSnackBarRef<SolSnackBarMessageComponent> {
    const autoDismissingConfig: MatSnackBarConfig = {
      duration: 5000
    };

    if (SolSnackBarMessageTypeUtil.isAutodismissingMessageType(msgType)) {
      config = Object.assign({}, autoDismissingConfig, config);
    }

    const defaultConfig: MatSnackBarConfig = {
      duration: 0,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: ["sol-snack-bar-container"]
    };
    const mergedConfig = Object.assign({}, defaultConfig, config);

    if (!mergedConfig.data) {
      mergedConfig.data = {};
    }
    if (msgType) {
      mergedConfig.data.messageType = msgType;
    } else {
      mergedConfig.data.messageType = SolSnackBarMessageType.INFO;
    }

    return matSnackBar.openFromComponent(SolSnackBarMessageComponent, mergedConfig);
  }
}
