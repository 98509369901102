import { Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivityLanguage } from "../../../../../../shared/models/activity-language.model";

@Component({
  selector: "app-languages-selector",
  templateUrl: "./languages.selector.presentational.html",
  styleUrls: ["./languages.selector.presentational.scss"]
})
export class LanguagesSelectorPresentational {
  @Input() control: UntypedFormControl;
  listLanguages: ActivityLanguage[] = [
    { code: "FR", label: "français" },
    { code: "EN", label: "anglais" },
    { code: "ZZ", label: "autre" }
  ];
}
