import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SolApiResponse, SolAppConfigService } from "common-ng";
import { Observable, of } from "rxjs";
import { SearchServiceBase } from "./search-service-base";
import { StringUtil } from "../../../../../ext-admin/src/app/shared/utils/string.util";
import { map } from "rxjs/operators";
import { ActivityTeacher } from "../models/activity-teacher.model";
import { Teacher } from "../models/teacher.model";

@Injectable({
  providedIn: "root"
})
export class TeacherService extends SearchServiceBase {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {
    super();
  }

  public getTeachers(
    search: string,
    academicalYear: string
  ): Observable<ActivityTeacher[]> | Observable<null> {
    if (StringUtil.isInteger(search)) {
      return of(null);
    }
    const url: string =
      this._appConfig.config.apiRootUrl +
      "teachers/search?search=i-publicId¬" +
      search +
      ",academicalYear:" +
      academicalYear +
      "&sort=publicId";
    return this._http
      .get<SolApiResponse<ActivityTeacher>>(url)
      .pipe(map(response => response._data));
  }

  public findByPersonId(personId: string, academicYear: string): Observable<Teacher[]> {
    const url: string = `${this._appConfig.config.apiRootUrl}teachers/search?search=personId:${personId},academicalYear:${academicYear}`;
    return this._http.get<SolApiResponse<Teacher>>(url).pipe(map(response => response._data));
  }
}
