import { NgModule } from "@angular/core";
import {
  SolMessageComponent,
  SolMessageContentDirective,
  SolMessageTitleDirective
} from "./message.component";
import { SolCommonSharedModule } from "../../shared/shared.module";

@NgModule({
  imports: [SolCommonSharedModule],
  declarations: [SolMessageComponent, SolMessageContentDirective, SolMessageTitleDirective],
  exports: [SolMessageComponent, SolMessageContentDirective, SolMessageTitleDirective]
})
export class SolMessageModule {}
