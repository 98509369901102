/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - date.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import moment from "moment";
import {SolidifyError} from "../errors/solidify.error";
import {
  isNullOrUndefined,
  isNullOrUndefinedOrWhiteString,
  isWhiteString,
} from "../tools/is/is.tool";

export class DateUtil {
  private static readonly _LOCAL_DATE_DATE_SIMPLE: string = "YYYY-MM-DD";
  private static readonly _OFFSET_DATE_TIME_DATE_ISO8601: string = "YYYY-MM-DDTHH:mm:ss.SSSZZ";
  private static readonly _FORMAT_TIME_ONLY: string = "LTS";
  private static readonly _FORMAT_DATE_ONLY: string = "L";
  private static readonly _SWISS_DATE_LONG: string = "DD.MM.YYYY";
  private static readonly _SWISS_DATETIME_LONG: string = "DD.MM.YYYY HH:mm";

  private static _MOMENT: typeof moment;

  constructor() {
    // NEED TO INSTANCIATE DATE UTIL ONCE TO ALLOW TO USE STATIC METHOD
    DateUtil._MOMENT = moment;
  }

  static get momentDate(): typeof moment {
    if (isNullOrUndefined(this._MOMENT)) {
      throw new SolidifyError("You need to instanciate moment with 'new DateUtil()' at least once time in the app before using DateUtil");
    }
    return this._MOMENT;
  }

  static convertOffsetDateTimeIso8601ToDate(dateTimeOffsetIso8601: string): Date | null {
    // date format received "2019-07-27T00:00:00.000+0200"
    if (isNullOrUndefined(dateTimeOffsetIso8601) || isWhiteString(dateTimeOffsetIso8601)) {
      return null;
    }
    const dateParsed = Date.parse(dateTimeOffsetIso8601);
    if (isNaN(dateParsed)) {
      return null;
    }
    return new Date(dateParsed);
  }

  static getTimeFromOffsetDateTimeIso8601(dateTimeOffsetIso8601: string): string {
    const date = this.convertOffsetDateTimeIso8601ToDate(dateTimeOffsetIso8601);
    let hours = "" + date.getHours();
    if (hours.length === 1) {
      hours = "0" + hours;
    }
    let minutes = "" + date.getMinutes();
    if (minutes.length === 1) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
  }

  static convertDateToDateString(date: Date, defaultValue: string = ""): string {
    if (isNullOrUndefined(date)) {
      return defaultValue;
    }
    return this.momentDate(date).format(this._FORMAT_DATE_ONLY);
  }

  static convertDateToTimeString(date: Date, defaultValue: string = ""): string {
    if (isNullOrUndefined(date)) {
      return defaultValue;
    }
    return this.momentDate(date).format(this._FORMAT_TIME_ONLY);
  }

  static convertDateToDateTimeString(date: Date, defaultValue: string = ""): string {
    if (isNullOrUndefined(date)) {
      return defaultValue;
    }
    return this.momentDate(date).format(this._FORMAT_DATE_ONLY) + " " + this.momentDate(date).format(this._FORMAT_TIME_ONLY);
  }

  static extractYearFromDate(date: Date | string): string | null {
    const convertedDate = DateUtil.convertToLocalDateDateSimple(date);
    if (isNullOrUndefined(convertedDate)) {
      return null;
    }
    return this.momentDate(convertedDate).year().toString();
  }

  static convertToLocalDateDateSimple(dateString: Date | string): string | null {
    return this.convertDateToString(dateString, this._LOCAL_DATE_DATE_SIMPLE);
  }

  static convertToOffsetDateTimeIso8601(dateString: Date | string): string | null {
    // expected format "2019-07-27T00:00:00.000+0200"
    return this.convertDateToString(dateString, this._OFFSET_DATE_TIME_DATE_ISO8601);
  }

  static convertToSwissDateLongString(dateString: Date | string): string | null {
    return this.convertDateToString(dateString, this._SWISS_DATE_LONG);
  }

  static convertToSwissDateTimeLongString(dateString: Date | string): string | null {
    return this.convertDateToString(dateString, this._SWISS_DATETIME_LONG);
  }

  static convertSwissDateLongStringToDate(dateString: string): Date | null {
    return this.convertStringToDate(dateString, this._SWISS_DATE_LONG);
  }

  static convertSwissDateTimeLongStringToDate(dateString: string): Date | null {
    return this.convertStringToDate(dateString, this._SWISS_DATETIME_LONG);
  }

  static convertDateToString(date: Date | string, format: string): string | null {
    if (isNullOrUndefined(date) || isWhiteString(date)) {
      return null;
    }
    const momentDate = this.momentDate(date);
    if (!momentDate.isValid()) {
      return null;
    }
    return momentDate.format(format);
  }

  static convertStringToDate(dateString: string, formatToParse: string): Date | null {
    if (isNullOrUndefinedOrWhiteString(dateString)) {
      return null;
    }
    const momentDate = this.momentDate(dateString, formatToParse);
    if (!momentDate.isValid()) {
      return null;
    }
    return momentDate.toDate();
  }

}
