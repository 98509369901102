/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - shared-oai-set.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {TypeDefaultAction} from "../../../core/decorators/type-default-action.decorator";
import {StatePartialEnum} from "../../../core/enums/partial/state-partial.enum";
import {ResourceNameSpace} from "../../../core/stores/abstract/resource/resource-namespace.model";
import {ResourceAction} from "../../../core/stores/abstract/resource/resource.action";
import {OaiSet} from "../models/oai-set.model";

const state = StatePartialEnum.shared_oaiSet;

export namespace SharedOaiSetAction {
  @TypeDefaultAction(state)
  export class LoadResource extends ResourceAction.LoadResource {
  }

  @TypeDefaultAction(state)
  export class LoadResourceSuccess extends ResourceAction.LoadResourceSuccess {
  }

  @TypeDefaultAction(state)
  export class LoadResourceFail extends ResourceAction.LoadResourceFail {
  }

  @TypeDefaultAction(state)
  export class ChangeQueryParameters extends ResourceAction.ChangeQueryParameters {
  }

  @TypeDefaultAction(state)
  export class GetAll extends ResourceAction.GetAll {
  }

  @TypeDefaultAction(state)
  export class GetAllSuccess extends ResourceAction.GetAllSuccess<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class GetAllFail extends ResourceAction.GetAllFail<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class GetByListId extends ResourceAction.GetByListId {
  }

  @TypeDefaultAction(state)
  export class GetByListIdSuccess extends ResourceAction.GetByListIdSuccess {
  }

  @TypeDefaultAction(state)
  export class GetByListIdFail extends ResourceAction.GetByListIdFail {
  }

  @TypeDefaultAction(state)
  export class GetById extends ResourceAction.GetById {
  }

  @TypeDefaultAction(state)
  export class GetByIdSuccess extends ResourceAction.GetByIdSuccess<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class GetByIdFail extends ResourceAction.GetByIdFail<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class Create extends ResourceAction.Create<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class CreateSuccess extends ResourceAction.CreateSuccess<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class CreateFail extends ResourceAction.CreateFail<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class Update extends ResourceAction.Update<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class UpdateSuccess extends ResourceAction.UpdateSuccess<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class UpdateFail extends ResourceAction.UpdateFail<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class Delete extends ResourceAction.Delete {
  }

  @TypeDefaultAction(state)
  export class DeleteSuccess extends ResourceAction.DeleteSuccess {
  }

  @TypeDefaultAction(state)
  export class DeleteFail extends ResourceAction.DeleteFail {
  }

  @TypeDefaultAction(state)
  export class DeleteList extends ResourceAction.DeleteList {
  }

  @TypeDefaultAction(state)
  export class DeleteListSuccess extends ResourceAction.DeleteListSuccess {
  }

  @TypeDefaultAction(state)
  export class DeleteListFail extends ResourceAction.DeleteListFail {
  }

  @TypeDefaultAction(state)
  export class AddInList extends ResourceAction.AddInList<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class AddInListById extends ResourceAction.AddInListById {
  }

  @TypeDefaultAction(state)
  export class AddInListByIdSuccess extends ResourceAction.AddInListByIdSuccess<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class AddInListByIdFail extends ResourceAction.AddInListByIdFail<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class RemoveInListById extends ResourceAction.RemoveInListById {
  }

  @TypeDefaultAction(state)
  export class RemoveInListByListId extends ResourceAction.RemoveInListByListId {
  }

  @TypeDefaultAction(state)
  export class LoadNextChunkList extends ResourceAction.LoadNextChunkList {
  }

  @TypeDefaultAction(state)
  export class LoadNextChunkListSuccess extends ResourceAction.LoadNextChunkListSuccess<OaiSet> {
  }

  @TypeDefaultAction(state)
  export class LoadNextChunkListFail extends ResourceAction.LoadNextChunkListFail {
  }

  @TypeDefaultAction(state)
  export class Clean extends ResourceAction.Clean {
  }
}

export const sharedOaiSetActionNameSpace: ResourceNameSpace = SharedOaiSetAction;
