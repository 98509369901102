/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - base.state.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Actions,
  Store,
} from "@ngxs/store";
import {ApiService} from "../../../http/api.service";
import {NotifierService} from "../../../models/services/notifier-service.model";
import {BaseStateModel} from "../../../models/stores/base-state.model";
import {BaseStoreNameSpace} from "../../../models/stores/base-store-namespace.model";
import {isNullOrUndefined} from "../../../tools/is/is.tool";
import {StoreUtil} from "../../../utils/stores/store.util";
import {BaseOptions} from "./base-options.model";
import {BasicState} from "./basic.state";

export const defaultBaseStateInitValue: () => BaseStateModel = () =>
  ({
    isLoadingCounter: 0,
  });

export abstract class BaseState<TStateModel extends BaseStateModel> extends BasicState<TStateModel> {
  protected abstract get _urlResource(): string;

  protected readonly _state: TStateModel;
  protected readonly _stateName: string;
  protected readonly _nameSpace: BaseStoreNameSpace;

  get stateName(): string {
    return this._stateName;
  }

  protected readonly _optionsState: BaseOptions;

  protected constructor(protected _apiService: ApiService,
                        protected _store: Store,
                        protected _notificationService: NotifierService,
                        protected _actions$: Actions,
                        protected _options: BaseOptions,
                        protected _baseStateConstructor: typeof BaseState | any) {
    super();
    this._optionsState = Object.assign(_baseStateConstructor._getDefaultOptions(), _options);
    _baseStateConstructor._checkOptions(this._stateName, this._options);
    this._state = StoreUtil.getStateFromInstance(this);
    this._stateName = StoreUtil.getStateNameFromInstance(this);
    this._nameSpace = _options.nameSpace;
    StoreUtil.initState(_baseStateConstructor, this.constructor, this._nameSpace);
  }

  protected static _getDefaultOptions(): BaseOptions | any {
    return {};
  }

  protected static _checkOptions(stateName: string, options: BaseOptions): void {
    const errorMessage = `Invalid options for resource state '${stateName}':`;
    if (isNullOrUndefined(options.nameSpace)) {
      throw new Error(`${errorMessage} resourceNameSpace should be defined`);
    }
  }
}
