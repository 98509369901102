/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - store.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {HttpErrorResponse} from "@angular/common/http";
import {
  ElementRef,
  Type,
} from "@angular/core";
import {AbstractControl} from "@angular/forms";
import {Navigate} from "@ngxs/router-plugin";
import {
  ActionCompletion,
  ActionOptions,
  Actions,
  ensureStoreMetadata,
  StateContext,
  Store,
} from "@ngxs/store";
import _ from "lodash";
import {
  defer,
  from,
  merge,
  MonoTypeOperatorFunction,
  Observable,
  of,
  pipe,
  timeout,
  zip,
} from "rxjs";
import {
  catchError,
  concatMap,
  filter,
  map,
  take,
  takeUntil,
  tap,
  toArray,
} from "rxjs/operators";
import {FORM_CONTROL_ELEMENT_REF} from "../../directives/form-control-element-ref/form-control-element-ref.directive";
import {RoutesPartialEnum} from "../../enums/partial/routes-partial.enum";
import {SolidifyError} from "../../errors/solidify.error";
import {ErrorHelper} from "../../helpers/error.helper";
import {
  BaseResource,
  BaseResourceType,
} from "../../models/dto/base-resource.model";
import {CollectionTyped} from "../../models/dto/collection-typed.model";
import {ValidationErrorDto} from "../../models/errors/error-dto.model";
import {FormError} from "../../models/errors/form-error.model";
import {SolidifyHttpErrorResponseModel} from "../../models/errors/solidify-http-error-response.model";
import {ModelFormControlEvent} from "../../models/forms/model-form-control-event.model";
import {QueryParameters} from "../../models/query-parameters/query-parameters.model";
import {NotifierService} from "../../models/services/notifier-service.model";
import {BaseResourceState} from "../../models/stores/base-resource.state";
import {BaseStateModel} from "../../models/stores/base-state.model";
import {
  ActionSubActionCompletionsWrapper,
  BaseAction,
  BaseSubAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
  MultiActionsCompletionsResult,
} from "../../models/stores/base.action";
import {StoreActionClass} from "../../models/stores/state-action.model";
import {SubResourceUpdateModel} from "../../models/stores/sub-resource-update.model";
import {AssociationNoSqlReadOnlyStateModel} from "../../stores/abstract/association-no-sql-read-only/association-no-sql-read-only-state.model";
import {BasicState} from "../../stores/abstract/base/basic.state";
import {CompositionStateModel} from "../../stores/abstract/composition/composition-state.model";
import {ResourceStateModel} from "../../stores/abstract/resource/resource-state.model";
import {
  isFalse,
  isFunction,
  isInstanceOf,
  isNonEmptyArray,
  isNonEmptyString,
  isNotNullNorUndefined,
  isNullOrUndefined,
  isTrue,
  isTruthyObject,
} from "../../tools/is/is.tool";
import {ExtendEnum} from "../../types/extend-enum.type";
import {SolidifyObject} from "../../types/solidify-object.type";
import {ObjectUtil} from "../object.util";
import {SsrUtil} from "../ssr.util";
import {StringUtil} from "../string.util";
import {SOLIDIFY_ERRORS} from "../validations/validation.util";
import {SolidifyMetadataUtil} from "./solidify-metadata.util";
import {
  ofSolidifyActionCompleted,
  ofSolidifyActionDispatched,
} from "./store.tool";

// @dynamic
export class StoreUtil {
  private static readonly _BULK_DISPATCH_TIMEOUT_FOR_NON_DISPATCHED_SUB_ACTION_BROWSER: number = 20000;
  private static readonly _BULK_DISPATCH_TIMEOUT_FOR_NON_DISPATCHED_SUB_ACTION_SERVER: number = 2000;
  private static readonly _BULK_DISPATCH_TIMEOUT_FOR_NON_DISPATCHED_SUB_ACTION: number = SsrUtil.isServer ? this._BULK_DISPATCH_TIMEOUT_FOR_NON_DISPATCHED_SUB_ACTION_SERVER : this._BULK_DISPATCH_TIMEOUT_FOR_NON_DISPATCHED_SUB_ACTION_BROWSER;
  private static readonly _ACTION_TYPE_ATTRIBUTE_NAME: string = "type";
  private static readonly _ATTRIBUTE_STATE_NAME: string = "name";
  private static readonly _NGXS_META_OPTIONS_KEY: string = "NGXS_OPTIONS_META";

  // TODO : Enhance typing ?
  // eslint-disable-next-line @typescript-eslint/ban-types
  static initState<T>(baseConstructor: Function, constructor: Function, nameSpace: T): void {
    if (SsrUtil.isServer && !SsrUtil.isSsrFirstCall) {
      // Allow to avoid SSR multiple call on states methods with annotation @RegisterDefaultAction
      return;
    }
    const baseMeta = SolidifyMetadataUtil.ensureStoreSolidifyMetadata<T>(baseConstructor as any);
    const defaultActions = baseMeta.defaultActions;
    if (Array.isArray(defaultActions)) {
      const meta = SolidifyMetadataUtil.ensureStoreSolidifyMetadata<T>(constructor as any);
      const excludedRegisteredDefaultActionFns = meta.excludedRegisteredDefaultActionFns;
      const safeExcludedFns = Array.isArray(excludedRegisteredDefaultActionFns) ? excludedRegisteredDefaultActionFns : [];
      defaultActions.forEach(({fn, callback, options}) => {
        if (safeExcludedFns.indexOf(fn) < 0) {
          this.initDefaultAction(constructor, fn, callback(nameSpace), options);
        }
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static getDefaultData<TResource extends BaseResourceType>(constructor: Function): ResourceStateModel<TResource> {
    const meta = ensureStoreMetadata(constructor as any);
    return meta.defaults;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  static initDefaultAction(constructor: Function, fn: string, storeActionClass: StoreActionClass, options?: ActionOptions): void {
    if (storeActionClass === undefined) {
      return;
    }
    const meta = ensureStoreMetadata(constructor as any);
    const type = storeActionClass.type;
    if (!type) {
      throw new Error(`Action ${storeActionClass.name} is missing a static "type" property`);
    }
    if (!meta.actions[type]) {
      meta.actions[type] = [];
    }
    meta.actions[type].push({
      fn,
      options: options || {},
      type,
    });
  }

  static getQueryParametersToApply(queryParameters: QueryParameters, ctx: StateContext<BaseResourceState>): QueryParameters {
    return queryParameters == null ? ctx.getState().queryParameters : queryParameters;
  }

  static updateQueryParameters<TResource>(ctx: StateContext<ResourceStateModel<TResource>> | StateContext<CompositionStateModel<TResource>> | StateContext<AssociationNoSqlReadOnlyStateModel<TResource>>, list: CollectionTyped<TResource> | null | undefined): QueryParameters {
    const queryParameters = ObjectUtil.clone(ctx.getState().queryParameters);
    const paging = ObjectUtil.clone(queryParameters.paging);
    paging.length = list === null || list === undefined ? 0 : list._page.totalItems;
    queryParameters.paging = paging;
    return queryParameters;
  }

  static dispatchActionAndWaitForSubActionCompletion<TResource extends BaseResource, UBaseAction extends BaseAction, VBaseSubAction extends BaseSubAction<UBaseAction>, WBaseSubAction extends BaseSubAction<UBaseAction>>(
    ctx: StateContext<ResourceStateModel<TResource>> | Store,
    actions$: Actions,
    action: UBaseAction,
    subActionSuccess: Type<VBaseSubAction>,
    callbackSuccess: (res: VBaseSubAction) => void,
    subActionFail?: Type<WBaseSubAction>,
    callbackFail?: (res: WBaseSubAction) => void,
  ): Observable<VBaseSubAction | WBaseSubAction> {
    const listActionSuccess = [subActionSuccess] as any;
    if (isNotNullNorUndefined(subActionFail)) {
      listActionSuccess.push(subActionFail);
    }
    const observable = actions$.pipe(
      ofSolidifyActionCompleted(...listActionSuccess),
      filter(result => result.action.parentAction === action && result.result.successful),
      take(1),
      map(result => {
        if (isInstanceOf(result.action, subActionSuccess)) {
          callbackSuccess(result.action);
          return result.action;
        } else if (isNotNullNorUndefined(subActionFail) && isInstanceOf(result.action, subActionFail) && isFunction(callbackFail)) {
          callbackFail(result.action);
          return result.action;
        }
        return;
      }),
    );

    ctx.dispatch(action);
    return observable;
  }

  static waitForSubActionCompletion<TResource extends BaseResource, UBaseAction extends BaseAction, VBaseSubAction extends BaseSubAction<UBaseAction>>(
    actions$: Actions,
    action: UBaseAction,
    subActionSuccess: Type<VBaseSubAction>,
    callback: (res: VBaseSubAction) => void,
  ): Observable<VBaseSubAction> {
    const observable = actions$.pipe(
      ofSolidifyActionCompleted(subActionSuccess),
      filter(result => result.action.parentAction === action && result.result.successful),
      take(1),
      map(result => {
        callback(result.action);
        return result.action;
      }),
    );
    return observable;
  }

  static dispatchParallelActionAndWaitForSubActionsCompletion<TResource>(ctx: StateContext<TResource> | Store,
                                                                         actionSubActionCompletionsWrappers: ActionSubActionCompletionsWrapper[],
                                                                         timeoutForNonDispatchedSubAction: number = this._BULK_DISPATCH_TIMEOUT_FOR_NON_DISPATCHED_SUB_ACTION): Observable<MultiActionsCompletionsResult> {
    if (actionSubActionCompletionsWrappers.length === 0) {
      return of({
        success: true,
        listActionFail: [],
        listActionSuccess: [],
        listActionGoalUndefined: [],
        listActionWithoutSubAction: [],
        listActionInError: [],
        rawResult: [],
      } as MultiActionsCompletionsResult);
    }

    const actions = new Array(actionSubActionCompletionsWrappers.length);
    const subActionCompletionObservables = [];
    actionSubActionCompletionsWrappers.forEach(
      (actionSubActionCompletionsWrapper, i) => {
        actions[i] = actionSubActionCompletionsWrapper.action;
        if (isNonEmptyArray(actionSubActionCompletionsWrapper.subActionCompletions)) {
          subActionCompletionObservables.push(
            merge(...actionSubActionCompletionsWrapper.subActionCompletions)
              .pipe(
                filter(actionCompletion => this.hasParentAction(actionCompletion.action, actionSubActionCompletionsWrapper.action)),
              ),
          );
        }
      },
    );
    if (subActionCompletionObservables.length === 0) {
      ctx.dispatch(actions);
      return of(this._formatBulkActionExecutionResult([], actionSubActionCompletionsWrappers));
    }
    return zip(
      zip(...subActionCompletionObservables),
      defer(() => ctx.dispatch(actions)),
    ).pipe(
      timeout(timeoutForNonDispatchedSubAction),
      map(values => {
        const listSubActionResult = values[0] as ActionCompletion<BaseSubAction<BaseAction>, Error>[];
        return this._formatBulkActionExecutionResult(listSubActionResult, actionSubActionCompletionsWrappers);
      }),
      catchError(err => {
        if (err.name === "TimeoutError") {
          // eslint-disable-next-line no-console
          console.error("Timeout on parallel dispatch, no expected sub action dispatched for at least one action : ", actions);
          throw new SolidifyError("Timeout on parallel dispatch, no expected sub action dispatched for at least one action", err);
        }
        throw err;
      }),
      catchError(err => of({
        success: false,
        error: err,
      } as MultiActionsCompletionsResult)),
    );
  }

  static dispatchSequentialActionAndWaitForSubActionsCompletion<TResource>(ctx: StateContext<TResource> | Store,
                                                                           actionSubActionCompletionsWrappers: ActionSubActionCompletionsWrapper[],
                                                                           timeoutForNonDispatchedSubAction: number = this._BULK_DISPATCH_TIMEOUT_FOR_NON_DISPATCHED_SUB_ACTION): Observable<MultiActionsCompletionsResult> {
    const obs = from(actionSubActionCompletionsWrappers)
      .pipe(
        concatMap((action) => {
          let subActionCompletionObs: Observable<ActionCompletion<BaseSubAction<BaseAction>, Error>> = of(undefined);

          if (isNonEmptyArray(action.subActionCompletions)) {
            subActionCompletionObs = merge(...action.subActionCompletions)
              .pipe(
                filter(actionCompletion => this.hasParentAction(actionCompletion.action, action.action)),
              );
          }

          return zip(
            zip(subActionCompletionObs),
            defer(() => ctx.dispatch(action.action)),
          ).pipe(
            timeout(timeoutForNonDispatchedSubAction),
            map(values => {
              const listSubActionResult = values[0] as ActionCompletion<BaseSubAction<BaseAction>, Error>[];
              return listSubActionResult[0];
            }),
            catchError(err => {
              if (err.name === "TimeoutError") {
                // eslint-disable-next-line no-console
                console.error("Timeout on sequential dispatch, no expected sub action dispatched for action", action.action);
                throw new SolidifyError("Timeout on sequential dispatch, no expected sub action dispatched for action : " + action.action.constructor[this._ACTION_TYPE_ATTRIBUTE_NAME], err);
              }
              throw err;
            }),
          );
        }),
      );

    return obs.pipe(
      toArray(),
      map((listSubActionResult: ActionCompletion<BaseSubAction<BaseAction>, Error>[]) => {
        listSubActionResult = listSubActionResult.filter(s => isNotNullNorUndefined(s));
        return this._formatBulkActionExecutionResult(listSubActionResult, actionSubActionCompletionsWrappers);
      }),
      catchError(err => of({
        success: false,
        error: err,
      } as MultiActionsCompletionsResult)),
    );
  }

  private static _formatBulkActionExecutionResult(listSubActionResult: ActionCompletion<BaseSubAction<BaseAction>, Error>[],
                                                  actionSubActionCompletionsWrappers: ActionSubActionCompletionsWrapper[]): MultiActionsCompletionsResult {
    const result = {
      success: true,
      listActionSuccess: [],
      listActionFail: [],
      listActionGoalUndefined: [],
      listActionWithoutSubAction: [],
      rawResult: listSubActionResult,
    } as MultiActionsCompletionsResult;

    listSubActionResult.forEach(s => {
      if (isNotNullNorUndefined(s.result.error)) {
        throw s.result.error;
      }
      if (s.result.successful) {
        if (s.action instanceof BaseSubActionSuccess) {
          result.listActionSuccess.push(s.action);
        } else if (s.action instanceof BaseSubActionFail) {
          result.listActionFail.push(s.action);
        } else {
          result.listActionGoalUndefined.push(s.action);
        }
      } else {
        result.listActionInError.push(s.action);
      }
    });

    const listActionWithoutSubAction = actionSubActionCompletionsWrappers.map(s => s.action);
    actionSubActionCompletionsWrappers.some(s => {
      if (isFalse(result.success)) {
        return true; // = break
      }
      const subActionDispatched: BaseSubAction<any> = listSubActionResult.find(r => r.action.parentAction === s.action)?.action;
      if (isNotNullNorUndefined(subActionDispatched)) {

        const indexOfActionWithoutSubAction = listActionWithoutSubAction.indexOf(s.action);
        if (indexOfActionWithoutSubAction !== -1) {
          listActionWithoutSubAction.splice(indexOfActionWithoutSubAction, 1);
        }

        if (subActionDispatched instanceof BaseSubActionFail) {
          result.success = false;
          return false; // = continue
        }
      }
      const needSubAction = isNonEmptyArray(s.subActionCompletions);
      if (!needSubAction) {
        return false; // = continue
      }
      result.success = isNotNullNorUndefined(subActionDispatched);
    });

    result.listActionWithoutSubAction = listActionWithoutSubAction;
    return result;
  }

  static hasParentAction(action: BaseAction, parentAction: BaseAction): boolean {
    if (!isTruthyObject(action) || !isTruthyObject(parentAction)) {
      return false;
    }
    const parents: BaseAction[] = [];
    let currentAction = action;
    while (isTruthyObject(currentAction.parentAction)) {
      const currentParentAction = currentAction.parentAction;
      if (currentParentAction === parentAction) {
        return true;
      }
      if (currentParentAction === action || parents.includes(currentParentAction)) {
        return false;
      }
      parents.push(currentParentAction);
      currentAction = currentParentAction;
    }
    return false;
  }

  static isLoadingState(baseState: BaseStateModel): boolean {
    return baseState.isLoadingCounter > 0;
  }

  static cancelUncompleted<T>(ctx: StateContext<BaseStateModel>, actions$: Actions, allowedTypes: any[], noLoadingCounterDecrement?: boolean): MonoTypeOperatorFunction<T> {
    return pipe(
      takeUntil(
        actions$.pipe(
          ofSolidifyActionDispatched(...allowedTypes),
          tap(() => {
              if (!noLoadingCounterDecrement) {
                ctx.patchState({
                  isLoadingCounter: ctx.getState().isLoadingCounter - 1,
                });
              }
            },
          ),
        ),
      ),
    );
  }

  static determineSubResourceChange(oldList: string[], newList: string[], updateAvailable: boolean = false): SubResourceUpdateModel {
    const subResourceUpdate: SubResourceUpdateModel = new SubResourceUpdateModel();
    const diff: string[] = _.xor(oldList, newList);
    diff.forEach(d => {
      if (_.includes(oldList, d)) {
        subResourceUpdate.resourceToRemoved.push(d);
      } else {
        subResourceUpdate.resourceToAdd.push(d);
      }
    });
    if (updateAvailable && isNonEmptyArray(subResourceUpdate.resourceToRemoved) && isNonEmptyArray(subResourceUpdate.resourceToAdd)) {
      subResourceUpdate.resourceToRemoved = [];
      subResourceUpdate.resourceToUpdate = subResourceUpdate.resourceToAdd;
      subResourceUpdate.resourceToAdd = [];
    }
    return subResourceUpdate;
  }

  static catchValidationErrors<T>(ctx: StateContext<ResourceStateModel<T>>, modelFormControlEvent: ModelFormControlEvent<T>, notifierService: NotifierService, autoScrollToFirstValidationError: boolean): MonoTypeOperatorFunction<T> {
    return catchError((error: SolidifyHttpErrorResponseModel | SolidifyError | Error | HttpErrorResponse) => {
      let errorToTreat = error;
      if (error instanceof SolidifyError) {
        errorToTreat = error.nativeError;
      }
      const validationErrors = ErrorHelper.extractValidationErrors(errorToTreat);
      if (isNonEmptyArray(validationErrors)) {
        if (isTruthyObject(modelFormControlEvent.formControl)) {
          const unbindValidationError = this._applyValidationErrorsOnFormControl(modelFormControlEvent, validationErrors, autoScrollToFirstValidationError);
          if (isNonEmptyArray(unbindValidationError)) {
            let errorMessages = StringUtil.stringEmpty;
            const errorMessageSeparator = ". ";
            unbindValidationError.forEach(validationError => {
              if (isNonEmptyString(errorMessages)) {
                errorMessages = errorMessageSeparator;
              }
              if (isNotNullNorUndefined(validationError.errorMessages)) {
                errorMessages = errorMessages + validationError.errorMessages.join(errorMessageSeparator);
              }
            });
            this.notifyError(notifierService, errorMessages);
          }
        }
      }
      throw error;
    });
  }

  private static _applyValidationErrorsOnFormControl<T>(modelFormControlEvent: ModelFormControlEvent<T>, validationErrors: ValidationErrorDto[], autoScrollToFirstValidationError: boolean): ValidationErrorDto[] {
    Object.keys(modelFormControlEvent.formControl.value).forEach(fieldName => {
      const formControl = modelFormControlEvent.formControl.get(fieldName);
      if (!isTruthyObject(formControl)) {
        return;
      }
      const hasToUpdateValueAndValidity = (isTruthyObject(formControl[SOLIDIFY_ERRORS]) && (formControl[SOLIDIFY_ERRORS] as FormError).errorsFromBackend !== null) || !isNullOrUndefined(formControl.errors);
      delete formControl[SOLIDIFY_ERRORS];
      if (hasToUpdateValueAndValidity) {
        formControl.updateValueAndValidity();
      }
    });
    return this.iterateOverValidationErrorToBindIntoFormControlIfFoundIt(modelFormControlEvent, validationErrors, autoScrollToFirstValidationError);
  }

  static iterateOverValidationErrorToBindIntoFormControlIfFoundIt<T>(modelFormControlEvent: ModelFormControlEvent<T>, validationErrors: ValidationErrorDto[], autoScrollToFirstValidationError: boolean): ValidationErrorDto[] {
    const unbindValidationErrors = [];
    let firstValidationErrorFc: AbstractControl;
    validationErrors.forEach(error => {
      if (isNotNullNorUndefined(modelFormControlEvent.listFieldNameToDisplayErrorInToast)
        && modelFormControlEvent.listFieldNameToDisplayErrorInToast.indexOf(error.fieldName) !== -1) {
        unbindValidationErrors.push(error);
        return;
      }
      const formControl = modelFormControlEvent.formControl.get(error.fieldName);
      if (isNullOrUndefined(formControl)) {
        unbindValidationErrors.push(error);
        // eslint-disable-next-line no-console
        console.warn(`Unable to bind error for field name "${error.fieldName}". No FormControl exist in this form with this name.`);
        return;
      }
      if (isTrue(autoScrollToFirstValidationError) && isNullOrUndefined(firstValidationErrorFc)) {
        const elementRef = this.getElementRefOnCurrentAbstractControlOrOnParents(formControl);
        if (isNotNullNorUndefined(elementRef?.nativeElement)) {
          firstValidationErrorFc = formControl;
          elementRef.nativeElement.scrollIntoView({behavior: "smooth", block: "center"});
        }
      }
      let errors = formControl.errors;
      if (isNullOrUndefined(errors)) {
        errors = {};
      }
      formControl[SOLIDIFY_ERRORS] = {errorsFromBackend: error.errorMessages} as FormError;
      Object.assign(errors, {errorsFromBackend: error.errorMessages});
      formControl.setErrors(errors);
      formControl.markAsTouched();
      // formControl.updateValueAndValidity(); ==> Remove error set with formControl.setErrors(errors);...
    });
    modelFormControlEvent?.changeDetectorRef?.detectChanges();
    return unbindValidationErrors;
  }

  static getElementRefOnCurrentAbstractControlOrOnParents(abstractControl: AbstractControl): ElementRef | undefined {
    const elementRef = abstractControl[FORM_CONTROL_ELEMENT_REF] as ElementRef;
    if (isNotNullNorUndefined(elementRef)) {
      return elementRef;
    }
    if (isNullOrUndefined(abstractControl.parent)) {
      return undefined;
    }
    return this.getElementRefOnCurrentAbstractControlOrOnParents(abstractControl.parent);
  }

  static notifySuccess(notifierService: NotifierService, textToTranslate: string, messageParam: SolidifyObject | undefined = undefined): boolean {
    if (isTruthyObject(notifierService)) {
      if (isNonEmptyString(textToTranslate)) {
        notifierService.showSuccess(textToTranslate, messageParam);
        return true;
      }
    }
    return false;
  }

  static notifyError(notifierService: NotifierService, textToTranslate: string, messageParam: SolidifyObject | undefined = undefined): boolean {
    if (isTruthyObject(notifierService)) {
      if (isNonEmptyString(textToTranslate)) {
        notifierService.showError(textToTranslate, messageParam);
        return true;
      }
    }
    return false;
  }

  static navigateIfDefined(ctx: StateContext<any>, route: ExtendEnum<RoutesPartialEnum> | undefined | ((resId: string) => string), resId: string | undefined, replaceUrl: boolean = false): void {
    if (!isNullOrUndefined(route)) { // TODO MANAGE CASE DELETE WITHOUT RESID
      if (isFunction(route)) {
        route = route(resId);
      }
      ctx.dispatch(new Navigate([route], {}, {replaceUrl: replaceUrl}));
    }
  }

  static navigateCompositionIfDefined(ctx: StateContext<any>, route: ExtendEnum<RoutesPartialEnum> | undefined | ((parentCompositionId: string, resId: string) => string), parentCompositionId: string | undefined, resId: string | undefined, replaceUrl: boolean = false): void {
    if (!isNullOrUndefined(route)) { // TODO MANAGE CASE DELETE WITHOUT RESID
      if (isFunction(route)) {
        route = route(parentCompositionId, resId);
      }
      ctx.dispatch(new Navigate([route], {}, {replaceUrl: replaceUrl}));
    }
  }

  static getStateNameFromInstance<TStateModel extends BaseStateModel = BaseStateModel>(store: BasicState<TStateModel>): string {
    return this.getStateNameFromClass(store.constructor as Type<BasicState<BaseStateModel>>);
  }

  static getStateNameFromClass<TStateModel extends BaseStateModel = BaseStateModel>(ctor: Type<BasicState<TStateModel>>): string {
    return this.getStateFromClass(ctor)[this._ATTRIBUTE_STATE_NAME];
  }

  static getStateFromInstance<T = any, TStateModel extends BaseStateModel = BaseStateModel>(store: BasicState<TStateModel>): T {
    return this.getStateFromClass(store.constructor as Type<BasicState<BaseStateModel>>);
  }

  static getStateFromClass<T = any, TStateModel extends BaseStateModel = BaseStateModel>(ctor: Type<BasicState<TStateModel>>): T {
    return ctor[this._NGXS_META_OPTIONS_KEY];
  }

  static isNextChunkAvailable(queryParameters: QueryParameters): boolean {
    let numberOfPages = queryParameters.paging.length / queryParameters.paging.pageSize;
    if (numberOfPages < 1) {
      numberOfPages = 1;
    }
    if (queryParameters.paging.pageIndex + 1 >= numberOfPages) {
      return false;
    }
    return true;
  }
}
