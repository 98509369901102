/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - core-abstract.component.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {Directive} from "@angular/core";
import {
  FloatLabelType,
  MatFormFieldAppearance,
} from "@angular/material/form-field";
import {IconNamePartialEnum} from "../../enums/partial/icon-name-partial.enum";
import {LabelTranslatePartialEnum} from "../../enums/partial/label-translate-partial.enum";
import {CoreAbstractAngularElement} from "../../models/angular/core-abstract-angular-element.model";
import {SsrUtil} from "../../utils/ssr.util";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class CoreAbstractComponent extends CoreAbstractAngularElement {
  get appearanceInputMaterial(): MatFormFieldAppearance {
    return "outline"; /*environment.appearanceInputMaterial*/
  }

  get displayEmptyRequiredFieldInError(): boolean {
    return true; /*environment.displayEmptyRequiredFieldInError*/
  }

  get positionLabelInputMaterial(): FloatLabelType {
    return "auto"; /*environment.positionLabelInputMaterial*/
  }

  get iconNameEnum(): typeof IconNamePartialEnum {
    return IconNamePartialEnum;
  }

  get labelTranslateEnum(): typeof LabelTranslatePartialEnum {
    return LabelTranslatePartialEnum;
  }

  get ssrUtil(): typeof SsrUtil {
    return SsrUtil;
  }
}
