import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SolNoResultComponent } from "./no-result.component";

@NgModule({
  declarations: [SolNoResultComponent],
  imports: [CommonModule],
  exports: [SolNoResultComponent]
})
export class SolNoResultComponentModule {}
