/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - form-validation.helper.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from "@angular/forms";
import {
  isNullOrUndefined,
  isNullOrUndefinedOrWhiteString,
  isTrue,
} from "../tools/is/is.tool";
import {MappingObject} from "../types/mapping-type.type";
import {MappingObjectUtil} from "../utils/mapping-object.util";
import {StringUtil} from "../utils/string.util";

export class FormValidationHelper {
  static readonly METADATA_ERRORS_MESSAGES: string = "ERRORS_MESSAGES";

  static getMetadataErrors(formControl: AbstractControl): MappingObject<string, string> {
    return formControl[FormValidationHelper.METADATA_ERRORS_MESSAGES];
  }

  static initMetadataErrors(formControl: AbstractControl): MappingObject<string, string> {
    return formControl[FormValidationHelper.METADATA_ERRORS_MESSAGES] = {} as MappingObject<string, string>;
  }

  static getAbstractControl(form: FormGroup, key: string): AbstractControl | undefined {
    return form.get(key);
  }

  static getFormGroup(form: FormGroup, key: string): FormGroup | undefined {
    const abstractForm = this.getAbstractControl(form, key);
    if (!(abstractForm instanceof FormGroup)) {
      // eslint-disable-next-line no-console
      console.warn(`The AbstractControl with key '${key}' is not a FormGroup as expected`, form);
    }
    return abstractForm as FormGroup;
  }

  static getFormControl(form: FormGroup, key: string): FormControl | undefined {
    const abstractForm = this.getAbstractControl(form, key);
    if (!(abstractForm instanceof FormControl)) {
      // eslint-disable-next-line no-console
      console.warn(`The AbstractControl with key '${key}' is not a FormControl as expected`, form);
    }
    return abstractForm as FormControl;
  }

  static getFormArray(form: FormGroup, key: string): FormArray | undefined {
    const abstractForm = this.getAbstractControl(form, key);
    if (!(abstractForm instanceof FormArray)) {
      // eslint-disable-next-line no-console
      console.warn(`The AbstractControl with key '${key}' is not a FormArray as expected`, form);
    }
    return abstractForm as FormArray;
  }

  static getMetadataErrorsByFormName(form: FormGroup, formDefinition: string): string {
    const formControl = this.getFormControl(form, formDefinition);
    return this.getFormMetadataErrorFormattedString(formControl);
  }

  static getFormMetadataErrorFormattedString(formControl: AbstractControl): string {
    if (isNullOrUndefined(formControl)) {
      return StringUtil.stringEmpty;
    }
    const errors = FormValidationHelper.getMetadataErrors(formControl);
    if (isNullOrUndefined(errors) || MappingObjectUtil.size(errors) === 0) {
      return StringUtil.stringEmpty;
    }
    return Array.from(MappingObjectUtil.values(errors)).join(". ");
  }

  static hasRequiredFieldByName(form: FormGroup, abstractControlName: string): boolean {
    const formControl = this.getFormControl(form, abstractControlName);
    return this.hasRequiredField(formControl);
  }

  static hasRequiredField(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl["controls"]) {
      for (const controlName in abstractControl["controls"]) {
        if (abstractControl["controls"][controlName]) {
          if (this.hasRequiredField(abstractControl["controls"][controlName])) {
            return true;
          }
        }
      }
    }
    return false;
  }

  static displayInvalidWhenRequired(formControl: FormControl, displayEmptyRequiredFieldInError: boolean): boolean {
    if (isNullOrUndefined(formControl) || !isTrue(displayEmptyRequiredFieldInError)) {
      return false;
    }
    return FormValidationHelper.hasRequiredField(formControl)
      && (isNullOrUndefinedOrWhiteString(formControl.value) || formControl.invalid);
  }
}
