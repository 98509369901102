import { SolChangeInfo } from "common-ng";

export class AcademicalYear {
  constructor(
    public entityId?: string,
    public label?: string,
    public current?: boolean,
    public future?: boolean,
    public startDate?: Date,
    public endDate?: Date,
    public creation?: SolChangeInfo,
    public lastUpdate?: SolChangeInfo,
    public resId?: string
  ) {}
}
