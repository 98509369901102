/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - string.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {SOLIDIFY_CONSTANTS} from "../constants";
import {ChangeCase} from "../lib-wrapped/change-case.model";
import {KeyValue} from "../models/key-value.model";
import {
  isEmptyString,
  isNullOrUndefined,
} from "../tools/is/is.tool";

export class StringUtil {
  static readonly stringEmpty: string = SOLIDIFY_CONSTANTS.STRING_EMPTY;

  static format(str: string, ...val: string[]): string {
    for (let i = 0; i < val.length; i++) {
      str = StringUtil.replaceAll(str, `{${i}}`, val[i]);
    }
    return str;
  }

  static formatKeyValue(str: string, ...val: KeyValue[]): string {
    val.forEach(v => {
      str = StringUtil.replaceAll(str, `{${v.key}}`, v.value);
    });
    return str;
  }

  static capitalize(origin: string): string {
    if (isNullOrUndefined(origin) || isEmptyString(origin)) {
      return origin;
    }
    const firstCharUpper = origin.substring(0, 1).toUpperCase();
    if (origin.length === 1) {
      return firstCharUpper;
    }
    return firstCharUpper + origin.substring(1, origin.length);
  }

  static convertSpinalCaseToPascalCase(origin: string): string {
    origin = origin.replace("-", " ");
    return ChangeCase.pascalCase(origin);
  }

  static convertSpinalCaseToCamelCase(origin: string): string {
    origin = origin.replace("-", " ");
    return ChangeCase.camelCase(origin);
  }

  static convertToCamelCase(origin: string): string {
    return ChangeCase.camelCase(origin);
  }

  static convertToSpinalCase(origin: string): string {
    origin = origin.replace(" ", "-");
    return ChangeCase.paramCase(origin);
  }

  static convertToSnakeCase(origin: string): string {
    origin = origin.replace(" ", "_");
    return ChangeCase.snakeCase(origin);
  }

  static convertToPascalCase(origin: string): string {
    return ChangeCase.pascalCase(origin);
  }

  static replaceAll(str: string, oldChar: string, newChar: string): string {
    const strSplitter = str.split(oldChar);
    return strSplitter.join(newChar);
  }
}
