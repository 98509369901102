<form [formGroup]="form" data-test="search-form" novalidate>
  <div class="form-fields">
    <mat-form-field id="faculties" (click)="openFaculty()">
      <mat-label>Facultés/Centres/Instituts</mat-label>
      <mat-select [formControlName]="formDefinition.facultyId" data-test="search-select-faculty">
        <mat-option class="custom-option" [value]="selectOptionEnum.defaultOption"
          >Toutes les facultés
        </mat-option>
        <mat-accordion #faculties="matAccordion" class="faculties" multi="true">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Facultés</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let faculty of facultiesObs | async">
              <mat-option *ngIf="faculty.label.includes('Faculté')" [value]="faculty.entityId">
                {{ faculty.label }}
              </mat-option>
            </ng-container>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Centres</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let faculty of facultiesObs | async">
              <mat-option *ngIf="faculty.label.includes('Centre')" [value]="faculty.entityId">
                {{ faculty.label }}
              </mat-option>
            </ng-container>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Instituts</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let faculty of facultiesObs | async">
              <mat-option
                *ngIf="!faculty.label.includes('Faculté') && !faculty.label.includes('Centre')"
                [value]="faculty.entityId"
              >
                {{ faculty.label }}
              </mat-option>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-select>
    </mat-form-field>
    <ng-container *ngIf="isActiveSearchType(searchTypeEnum.studyPlans)">
      <mat-form-field id="departments">
        <mat-label>Départements</mat-label>
        <mat-select
          [formControlName]="formDefinition.studyPlanDepartmentId"
          data-test="search-select-study-plan-departement"
        >
          <mat-option class="department" [value]="selectOptionEnum.defaultOption"
            >Tout département / section
          </mat-option>
          <mat-option
            class="department"
            *ngFor="let studyPlanDepartment of studyPlanDepartmentsObs | async"
            [value]="studyPlanDepartment.entityId"
            >{{ studyPlanDepartment.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="isActiveSearchType(searchTypeEnum.teachings)">
      <mat-form-field id="departments">
        <mat-label>Départements</mat-label>
        <mat-select
          [formControlName]="formDefinition.teachingDepartmentId"
          data-test="search-select-teaching-departement"
        >
          <mat-option class="department" [value]="selectOptionEnum.defaultOption"
            >Tout département / section
          </mat-option>
          <mat-option
            class="department"
            *ngFor="let teachingDepartment of teachingDepartmentsObs | async"
            [value]="teachingDepartment.entityId"
            >{{ teachingDepartment.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div id="searchTermBox">
      <mat-form-field id="searchTerm">
        <input
          [formControlName]="formDefinition.searchTerm"
          data-test="search-input"
          matInput
          name="search"
          (keydown.enter)="onSubmit()"
          placeholder="{{ this._activeSearchType === searchTypeEnum.teachings ? 'Mots-clés, code cours/plan d\'études…' : 'Mots-clés, code cours/plan d\'études… ' }}"
        />
      </mat-form-field>
      <a id="searchClear" (click)="clearSearch($event)" *ngIf="(searchFormObs | async)?.searchTerm">
        <img src="assets/images/search-clear.svg" />
      </a>
    </div>
    <button
      data-test="search-btn"
      mat-icon-button
      type="submit"
      id="searchButton"
      [disableRipple]="true"
      (click)="onSubmit()"
      [disabled]="isDisabled"
    >
      <img
        src="{{ isDisabled ? 'assets/images/search-disabled.svg' : 'assets/images/search-active.svg' }}"
      />
    </button>
  </div>
</form>
