import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { SolSnackBarMessageType, SolSnackBarMessageTypeUtil } from "./snack-bar-message-type";

@Component({
  selector: "sol-snack-bar-message",
  templateUrl: "./snack-bar-message.component.html",
  styleUrls: ["./snack-bar-message.component.scss"]
})
export class SolSnackBarMessageComponent {
  get MessageType(): typeof SolSnackBarMessageType {
    return SolSnackBarMessageType;
  }

  constructor(
    private _cdr: ChangeDetectorRef,
    private _snackBarRef: MatSnackBarRef<SolSnackBarMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  onInit(): void {
    this._cdr.detectChanges();
  }

  /** Dismisses the snack bar. */
  dismiss(): void {
    this._snackBarRef.dismiss();
  }

  displayCloseButton(): boolean {
    if (this.data.dismissible) {
      return true;
    }
    if (SolSnackBarMessageTypeUtil.isAutodismissingMessageType(this.data.messageType)) {
      return false;
    }
    return true;
  }

  displayReloadPageAction(): boolean {
    if (this.data.reloadPageAction === true) {
      return true;
    }
    return false;
  }

  reloadPage(): void {
    window.location.reload();
  }
}
