import { Component, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-periodicities-selector",
  templateUrl: "./periodicities.selector.presentational.html",
  styleUrls: ["./periodicities.selector.presentational.scss"]
})
export class PeriodicitiesSelectorPresentational {
  @Input() control: UntypedFormControl;
}
