import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { Injectable } from "@angular/core";
import {
  ChangeSemesterSelected,
  DeleteStudentScheduleBlock,
  DeleteStudentScheduleBlockList,
  FetchCalendarEvents,
  FetchStudentScheduleBlocks,
  FetchTypicalWeekEvents,
  UpdateStudentScheduleBlockList,
  UpdateStudentScheduleBlockSelection
} from "./schedule.actions";
import { CalendarEvent } from "angular-calendar";
import { StudentTimetableService } from "../../services/student-timetable.service";
import { Subscription } from "rxjs";
import { addHours, addMinutes, startOfDay } from "date-fns";
import { StudentScheduleBlockService } from "../../services/student-schedule-block.service";
import { SearchState } from "../search/search.state";
import { StudentScheduleBlock } from "../../models/student-schedule-block.model";
import { SolSnackBarMessageType, SolSnackBarService } from "common-ng";
import { MatSnackBar } from "@angular/material/snack-bar";

export class ScheduleStateModel {
  semesterSelected: string;
  loadingCalendarEvents: boolean;
  calendarEvents: CalendarEvent<{ event: Event }>[];
  loadingTypicalWeekEvents: boolean;
  typicalWeekEvents: CalendarEvent<{ event: Event }>[];
  loadingStudentScheduleBlocks: boolean;
  studentScheduleBlocks: StudentScheduleBlock[];
  lastStartDate: string;
  lastEndDate: string;
}

const defaults: ScheduleStateModel = {
  semesterSelected: "A",
  loadingCalendarEvents: true,
  calendarEvents: [],
  loadingTypicalWeekEvents: true,
  typicalWeekEvents: [],
  loadingStudentScheduleBlocks: true,
  studentScheduleBlocks: [],
  lastStartDate: null,
  lastEndDate: null
};

@State<ScheduleStateModel>({
  name: "schedule",
  defaults
})
@Injectable()
export class ScheduleState {
  constructor(
    private _store: Store,
    private _snackBar: SolSnackBarService,
    private _matSnackBar: MatSnackBar,
    private _scheduleBlockService: StudentScheduleBlockService,
    private _scheduleInstanceService: StudentTimetableService
  ) {}

  @Selector()
  static getSemesterSelected(state: ScheduleStateModel): string {
    return state.semesterSelected;
  }

  @Action(ChangeSemesterSelected)
  changeSemesterSelected(
    { setState, getState, patchState, dispatch }: StateContext<ScheduleStateModel>,
    action: ChangeSemesterSelected
  ): void {
    patchState({
      semesterSelected: action.semester
    });
  }

  @Action(FetchCalendarEvents, { cancelUncompleted: true })
  fetchCalendarEvents(
    ctx: StateContext<ScheduleStateModel>,
    action: FetchCalendarEvents
  ): Subscription {
    const state = ctx.getState();
    if (!state.loadingCalendarEvents) {
      ctx.patchState({
        loadingCalendarEvents: true
      });
    }
    ctx.patchState({
      calendarEvents: [],
      lastStartDate: action.minDate,
      lastEndDate: action.maxDate
    });
    return this._scheduleInstanceService
      .getMyTimetableByDateRange(action.minDate, action.maxDate)
      .subscribe(
        data => {
          let events = [];
          for (const si of data) {
            const e: CalendarEvent = {
              start: addMinutes(addHours(startOfDay(si.date), si.startHour), si.startMinutes),
              end: addMinutes(addHours(startOfDay(si.date), si.endHour), si.endMinutes),
              title:
                si.roomLabel +
                " " +
                si.buildingLabel +
                "<br>" +
                si.title +
                " - " +
                si.studentScheduleBlock.course.type,
              id: si.entityId,
              meta: {
                si
              },
              color: {
                primary: si.color,
                secondary: si.color
              }
            };
            events = [...events, e];
            ctx.patchState({
              calendarEvents: events
            });
          }
          ctx.patchState({
            loadingCalendarEvents: false
          });
        },
        error => {
          // TODO display error or not
        }
      );
  }

  @Action(FetchTypicalWeekEvents, { cancelUncompleted: true })
  fetchTypicalWeekEvents(
    ctx: StateContext<ScheduleStateModel>,
    action: FetchTypicalWeekEvents
  ): Subscription {
    const state = ctx.getState();
    if (!state.loadingTypicalWeekEvents) {
      ctx.patchState({
        loadingTypicalWeekEvents: true
      });
    }
    ctx.patchState({
      typicalWeekEvents: []
    });
    return this._scheduleBlockService
      .getMyStudentScheduleBlock(action.academicalYear, ctx.getState().semesterSelected, true)
      .subscribe(
        data => {
          let events = [];
          for (const sb of data) {
            if (sb.course.frequency !== "Non régulier") {
              const days = sb.course.day.split(",");
              days.forEach(day => {
                const e: CalendarEvent = {
                  start: addMinutes(
                    addHours(startOfDay(this._getCalendarDay(day)), sb.course.startHour),
                    sb.course.startMinutes
                  ),
                  end: addMinutes(
                    addHours(startOfDay(this._getCalendarDay(day)), sb.course.endHour),
                    sb.course.endMinutes
                  ),
                  title: sb.course.room + " " + sb.course.building + "<br>" + sb.activity.title,
                  id: sb.course.entityId,
                  meta: {
                    sb
                  },
                  color: {
                    primary: sb.color,
                    secondary: sb.color
                  },
                  cssClass: sb.course.frequency !== "hebdo" ? "no-hebdo" : ""
                };
                events = [...events, e];
              });
              ctx.patchState({
                typicalWeekEvents: events
              });
            }
          }
          ctx.patchState({
            loadingTypicalWeekEvents: false
          });
        },
        error => {
          // TODO display error or not
        }
      );
  }

  @Action(FetchStudentScheduleBlocks, { cancelUncompleted: true })
  fetchStudentScheduleBlocks(
    ctx: StateContext<ScheduleStateModel>,
    action: FetchStudentScheduleBlocks
  ): Subscription {
    const state = ctx.getState();
    if (!state.loadingStudentScheduleBlocks) {
      ctx.patchState({
        loadingStudentScheduleBlocks: true
      });
    }
    ctx.patchState({
      studentScheduleBlocks: []
    });
    return this._scheduleBlockService
      .getAllMyStudentScheduleBlock(action.academicalYear, ctx.getState().semesterSelected)
      .subscribe(
        data => {
          ctx.patchState({
            studentScheduleBlocks: data
          });

          ctx.patchState({
            loadingStudentScheduleBlocks: false
          });
        },
        error => {
          // TODO display error or not
        }
      );
  }

  @Action(DeleteStudentScheduleBlock, { cancelUncompleted: true })
  deleteStudentScheduleBlock(
    ctx: StateContext<ScheduleStateModel>,
    action: DeleteStudentScheduleBlock
  ): Subscription {
    return this._scheduleBlockService.deleteStudentScheduleBlock(action.ssbId).subscribe(
      data => {
        this._store.dispatch(
          new FetchTypicalWeekEvents(
            this._store.selectSnapshot(SearchState.getFormModel).academicalYear
          )
        );
        this._store.dispatch(
          new FetchStudentScheduleBlocks(
            this._store.selectSnapshot(SearchState.getFormModel).academicalYear
          )
        );
        if (ctx.getState().lastStartDate && ctx.getState().lastEndDate) {
          this._store.dispatch(
            new FetchCalendarEvents(ctx.getState().lastStartDate, ctx.getState().lastEndDate)
          );
        }
      },
      error => {
        // TODO display error or not
      }
    );
  }

  @Action(DeleteStudentScheduleBlockList, { cancelUncompleted: true })
  deleteStudentScheduleBlockList(
    ctx: StateContext<ScheduleStateModel>,
    action: DeleteStudentScheduleBlockList
  ): Subscription {
    return this._scheduleBlockService.deleteStudentScheduleBlockList(action.ssbIdList).subscribe(
      data => {
        this._store.dispatch(
          new FetchTypicalWeekEvents(
            this._store.selectSnapshot(SearchState.getFormModel).academicalYear
          )
        );
        this._store.dispatch(
          new FetchStudentScheduleBlocks(
            this._store.selectSnapshot(SearchState.getFormModel).academicalYear
          )
        );
        if (ctx.getState().lastStartDate && ctx.getState().lastEndDate) {
          this._store.dispatch(
            new FetchCalendarEvents(ctx.getState().lastStartDate, ctx.getState().lastEndDate)
          );
        }
        this.success();
      },
      error => {
        // TODO display error or not
      }
    );
  }

  @Action(UpdateStudentScheduleBlockList, { cancelUncompleted: true })
  updateStudentScheduleBlockList(
    ctx: StateContext<ScheduleStateModel>,
    action: UpdateStudentScheduleBlockList
  ): Subscription {
    return this._scheduleBlockService.customize(action.ssbList).subscribe(
      data => {
        this._store.dispatch(
          new FetchTypicalWeekEvents(
            this._store.selectSnapshot(SearchState.getFormModel).academicalYear
          )
        );
        this._store.dispatch(
          new FetchStudentScheduleBlocks(
            this._store.selectSnapshot(SearchState.getFormModel).academicalYear
          )
        );
        if (ctx.getState().lastStartDate && ctx.getState().lastEndDate) {
          this._store.dispatch(
            new FetchCalendarEvents(ctx.getState().lastStartDate, ctx.getState().lastEndDate)
          );
        }
      },
      error => {
        // TODO display error or not
      }
    );
  }

  @Action(UpdateStudentScheduleBlockSelection, { cancelUncompleted: true })
  updateStudentScheduleBlockSelection(
    ctx: StateContext<ScheduleStateModel>,
    action: UpdateStudentScheduleBlockSelection
  ): Subscription {
    return this._scheduleBlockService.updateMySelection(action.ssbSelection).subscribe(
      data => {
        this._store.dispatch(
          new FetchTypicalWeekEvents(
            this._store.selectSnapshot(SearchState.getFormModel).academicalYear
          )
        );
        this._store.dispatch(
          new FetchStudentScheduleBlocks(
            this._store.selectSnapshot(SearchState.getFormModel).academicalYear
          )
        );
        if (ctx.getState().lastStartDate && ctx.getState().lastEndDate) {
          this._store.dispatch(
            new FetchCalendarEvents(ctx.getState().lastStartDate, ctx.getState().lastEndDate)
          );
        }
      },
      error => {
        // TODO display error or not
      }
    );
  }

  _getCalendarDay(day: string): Date {
    switch (day) {
      case "Lundis": {
        return new Date(2023, 6, 17);
      }
      case "Mardis": {
        return new Date(2023, 6, 18);
      }
      case "Mercredis": {
        return new Date(2023, 6, 19);
      }
      case "Jeudis": {
        return new Date(2023, 6, 20);
      }
      case "Vendredis": {
        return new Date(2023, 6, 21);
      }
      case "Samedis": {
        return new Date(2023, 6, 22);
      }
      default: {
        break;
      }
    }
  }

  success(): void {
    this._snackBar.open(this._matSnackBar, SolSnackBarMessageType.SUCCESS, {
      data: {
        message:
          "Votre sélection a été mise à jour<br> Attention, ceci n'est pas une inscription officielle !"
      }
    });
  }
}
