<div id="academical-year" [class.compact]="compact">
  <a [routerLink]="['/']">
    <span class="title" *ngIf="!compact">Programme des cours</span>
    <span class="title-compact" *ngIf="compact">Programme des cours | </span>
  </a>
  <form [formGroup]="academicalYearForm" data-test="search-form" novalidate class="academical-year">
    <mat-select
      [formControlName]="formDefinition.academicalYear"
      data-test="search-select-academical-year"
      (openedChange)="isOpen = $event"
    >
      <mat-option
        class="academical"
        *ngFor="let academicalYear of academicalYears"
        [value]="academicalYear.entityId"
      >
        {{ format(academicalYear) }} {{ isOpen && academicalYear.entityId >
        currentAcademicalYear.entityId ? ' (en cours de construction)' : '' }} {{ isOpen &&
        academicalYear.entityId < currentAcademicalYear.entityId ? ' (archives)' : '' }}
      </mat-option>
    </mat-select>
  </form>
  <div
    data-test="info-warning-construction"
    class="info-warning-construction"
    *ngIf="!isLoadingAcademicalYears && academicalYearForm.get(formDefinition.academicalYear).value > currentAcademicalYear?.entityId"
  >
    <span
      class="sol-icon-text"
      [ngClass]="{ 'info-warning-construction-title-compact': compact, 'info-warning-construction-title': !compact }"
      ><mat-icon>warning</mat-icon>En cours de construction <mat-icon>warning </mat-icon></span
    >
    <div class="info-warning-construction-message">
      Les informations sont incomplètes et peuvent changer.
    </div>
  </div>
</div>
