import { AcademicalYear } from "../../models/academical-year.model";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { AcademicalYearService } from "../../services/academical-year.service";
import { tap } from "rxjs/operators";
import {
  GetCurrentAcademicYear,
  GetCurrentAcademicYearFail,
  GetCurrentAcademicYearSuccess
} from "./academic-year.actions";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class AcademicYearStateModel {
  currentAccademicYear: AcademicalYear;
}

const defaults: AcademicYearStateModel = {
  currentAccademicYear: {}
};

@State<AcademicYearStateModel>({
  name: "AcademicalYear",
  defaults
})
@Injectable()
export class AcademicYearState {
  constructor(private _store: Store, private _academicalYearService: AcademicalYearService) {}

  @Selector()
  static getCurrentAcademicYear(state: AcademicYearStateModel): AcademicalYear {
    return state.currentAccademicYear;
  }

  @Action(GetCurrentAcademicYearSuccess)
  getCurrentAcademicYearSuccess(
    { setState, getState, patchState, dispatch }: StateContext<AcademicYearStateModel>,
    action: GetCurrentAcademicYearSuccess
  ): void {
    patchState({
      currentAccademicYear: action.payload
    });
  }

  @Action(GetCurrentAcademicYear, { cancelUncompleted: true })
  getCurrentAcademicYear(
    { getState, setState, patchState, dispatch }: StateContext<AcademicYearStateModel>,
    action: GetCurrentAcademicYear
  ): Observable<AcademicalYear[]> {
    patchState({
      currentAccademicYear: {}
    });

    return this._academicalYearService.getCurrentAcademicYear().pipe(
      tap(
        (data: AcademicalYear[]) => {
          dispatch(new GetCurrentAcademicYearSuccess(data[0]));
        },
        error => {
          dispatch(new GetCurrentAcademicYearFail());
          throw error;
        }
      )
    );
  }
}
