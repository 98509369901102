import localeFr from "@angular/common/locales/fr";
import { Component, HostListener, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationExtras, Params, Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { GetCurrentAcademicYear } from "./shared/stores/academic-year/academic-year.actions";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { RoutingHistoryService } from "./shared/services/routing-history.service";
import { SolAppConfigService } from "common-ng";
import { filter, first, skip } from "rxjs/operators";
import { SearchState } from "./shared/stores/search/search.state";
import { Observable } from "rxjs";
import {
  createHttpParamsFromSearchForm,
  SearchForm,
  SearchUrlParameter
} from "./shared/models/search-form.model";
import { SearchTypeEnum } from "./shared/enums/search-type.enum";
import { Location, registerLocaleData } from "@angular/common";
import { UpdateFormValue } from "@ngxs/form-plugin";
import { RoutesEnum } from "./shared/enums/routes.enum";
import { UpdateActiveSearchType } from "./shared/stores/search/search.action";

declare let gtag;
registerLocaleData(localeFr, "fr");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  @Select(SearchState.getFormModel) searchFormObs: Observable<SearchForm>;
  oldUrl: string = "https://wadme.unige.ch:3349/pls/opprg/w_rech_cours.debut";
  scrolled: boolean = false;
  showTutorial: boolean = false;

  constructor(
    private _router: Router,
    private _store: Store,
    private _route: ActivatedRoute,
    private _location: Location,
    matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    private _routingHistoryService: RoutingHistoryService,
    private _appConfig: SolAppConfigService
  ) {
    if (this._appConfig.config.production && this._appConfig.config.envName === "prod") {
      this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag("config", this._appConfig.config.googleAnalyticsID, {
            page_path: event.urlAfterRedirects,
            anonymize_ip: true
          });
        }
      });
    }
    matIconRegistry.addSvgIcon(
      "print",
      _sanitizer.bypassSecurityTrustResourceUrl("assets/images/print.svg")
    );
    this._routingHistoryService.recordUrls();
  }

  @HostListener("window:scroll", [])
  onWindowScroll(): void {
    this.scrolled = window.scrollY > 0;
  }

  ngOnInit(): void {
    if (this._location.path().includes("study-plans")) {
      this._store.dispatch(new UpdateActiveSearchType(SearchTypeEnum.studyPlans));
    }
    if (
      this._location.path().includes("my-schedule") ||
      this._location.path().includes("my-calendar")
    ) {
      this._store.dispatch(new UpdateActiveSearchType(null));
    }
    this._store.dispatch(new GetCurrentAcademicYear());

    this._route.queryParams.pipe(skip(1), first()).subscribe(params => {
      const searchForm: SearchForm = new SearchForm();
      for (const paramName in SearchUrlParameter) {
        if (params[SearchUrlParameter[paramName]]) {
          searchForm[paramName] = params[SearchUrlParameter[paramName]];
        }
      }

      const activeSearchType = this._store.selectSnapshot(SearchState.getActiveSearchType);
      switch (activeSearchType) {
        case SearchTypeEnum.teachings:
          searchForm.departmentId = params[SearchUrlParameter.teachingDepartmentId];
          break;
        case SearchTypeEnum.studyPlans:
          searchForm.departmentId = params[SearchUrlParameter.studyPlanDepartmentId];
          break;
      }
      searchForm.teachingDepartmentId = undefined;
      searchForm.studyPlanDepartmentId = undefined;

      this._store.dispatch(
        new UpdateFormValue({
          path: "search.form",
          value: searchForm
        })
      );
    });

    this.searchFormObs.pipe(skip(1)).subscribe(form => {
      this._updateUrl(form);
    });

    this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      if (
        this._router.url === "/" + RoutesEnum.teachings ||
        this._router.url === "/" + RoutesEnum.studyPlans
      ) {
        const form = this._store.selectSnapshot(SearchState.getFormModel);
        this._updateUrl(form);
      }
    });
  }

  public showNavigation(): boolean {
    return (
      !this._router.url.startsWith("/home") &&
      this._router.url !== "/" &&
      !this._router.url.includes("/print")
    );
  }

  public toggleHelp(): void {
    window.scrollTo(0, 0);
    this.showTutorial = !this.showTutorial;
    if (this.showTutorial) {
      gtag("event", "open_tutorial", {
        event_category: "home",
        event_label: "openTutorial"
      });
    }
  }

  private _updateUrl(searchForm: SearchForm): void {
    const activeSearchType = this._store.selectSnapshot(SearchState.getActiveSearchType);
    const queryParams: Params = createHttpParamsFromSearchForm(searchForm, activeSearchType);
    switch (activeSearchType) {
      case SearchTypeEnum.teachings:
        queryParams[SearchUrlParameter.teachingDepartmentId] = searchForm.departmentId;
        break;
      case SearchTypeEnum.studyPlans:
        queryParams[SearchUrlParameter.studyPlanDepartmentId] = searchForm.departmentId;
        break;
    }
    const navigationExtras: NavigationExtras = {
      relativeTo: this._route,
      queryParams: queryParams
    };
    const url: string = this._router.createUrlTree([], navigationExtras).toString();
    this._location.go(url);
  }

  getContentScheduleClass(): boolean {
    return (
      this._location.path().includes("my-schedule") || this._location.path().includes("my-calendar")
    );
  }
}
