/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - store.decorator.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {ActionOptions} from "@ngxs/store";
import {BaseStoreNameSpace} from "../models/stores/base-store-namespace.model";
import {StoreActionClass} from "../models/stores/state-action.model";
import {isNullOrUndefined} from "../tools/is/is.tool";
import {
  SolidifyMetaDataModel,
  SolidifyMetadataUtil,
} from "../utils/stores/solidify-metadata.util";

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function RegisterDefaultAction<T extends BaseStoreNameSpace>(defaultActionClassCallback: DefaultActionClassCallback<T>, options?: ActionOptions, parentState?: any): MethodDecorator {
  return (target: any, name: string, descriptor: TypedPropertyDescriptor<any>) => {
    let meta: SolidifyMetaDataModel<T>;
    if (isNullOrUndefined(parentState)) {
      meta = SolidifyMetadataUtil.ensureStoreSolidifyMetadata(target.constructor);
    } else {
      meta = SolidifyMetadataUtil.ensureStoreSolidifyMetadata(parentState);
    }
    if (!Array.isArray(meta.defaultActions)) {
      meta.defaultActions = [];
    }

    meta.defaultActions.push({
      fn: name,
      callback: defaultActionClassCallback,
      options,
    });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function OverrideDefaultAction(): MethodDecorator {
  return (target: any, name: string, descriptor: TypedPropertyDescriptor<any>) => {
    const meta = SolidifyMetadataUtil.ensureStoreSolidifyMetadata(target.constructor);
    if (!Array.isArray(meta.excludedRegisteredDefaultActionFns)) {
      meta.excludedRegisteredDefaultActionFns = [];
    }
    meta.excludedRegisteredDefaultActionFns.push(name);
  };
}

export interface RegisteredDefaultAction<T extends BaseStoreNameSpace> {
  fn?: string;
  callback?: DefaultActionClassCallback<T>;
  options?: ActionOptions;
}

export type DefaultActionClassCallback<T extends BaseStoreNameSpace> = (baseNameSpace?: T) => StoreActionClass;
