import {
  ApiService,
  BaseStateModel,
  BasicState,
  defaultResourceStateInitValue,
  isNonEmptyArray,
  NotificationService
} from "solidify-frontend";
import { Action, Actions, Selector, State, StateContext, Store } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { BreadcrumbAction } from "./breadcrumb.action";
import { StateEnum } from "../../../models/enum/state-enum";
import { BreadcrumbItem } from "../../../models/breadcrumb/breadcrumb-item.model";
import { BreadcrumbStatusEnum } from "../../../models/breadcrumb/breadcrumb-status.enum";
import { Navigate } from "@ngxs/router-plugin";

export interface BreadCrumbStateModel extends BaseStateModel {
  isLoading: boolean;
  isLoadingCounter: number;
  breadcrumbList: BreadcrumbItem[];
  currentBreadcrumb: BreadcrumbItem;
}

@State<BreadCrumbStateModel>({
  name: StateEnum.breadcrumb,
  defaults: {
    currentBreadcrumb: null,
    breadcrumbList: [],
    isLoading: false,
    ...defaultResourceStateInitValue()
  }
})
@Injectable()
export class BreadcrumbState extends BasicState<BreadCrumbStateModel> {
  constructor(
    protected apiService: ApiService,
    protected store: Store,
    protected notificationService: NotificationService,
    protected actions$: Actions
  ) {
    super();
  }

  @Selector()
  static getDeclarationBreadCrumbList(state: BreadCrumbStateModel): BreadcrumbItem[] {
    return state.breadcrumbList;
  }

  @Selector()
  static getNextDeclarationBreadCrumbItem(state: BreadCrumbStateModel): BreadcrumbItem {
    return this.getNextAvailableStep(state.breadcrumbList);
  }

  @Selector()
  static getLoading(state: BreadCrumbStateModel): boolean {
    return state.isLoading;
  }

  @Action(BreadcrumbAction.StoreBreadcrumb)
  storeDeclarationBreadCrumb(
    ctx: StateContext<BreadCrumbStateModel>,
    action: BreadcrumbAction.StoreBreadcrumb
  ): void {
    ctx.patchState({
      currentBreadcrumb: BreadcrumbState._getCurrentBreadcrumb(action.breadcrumb),
      breadcrumbList: action.breadcrumb
    });
    ctx.dispatch(new BreadcrumbAction.StoreBreadcrumbSuccess());
  }

  @Action(BreadcrumbAction.NavigateNextAvailableStep)
  navigateNextDeclarationStep(
    ctx: StateContext<BreadCrumbStateModel>,
    action: BreadcrumbAction.NavigateNextAvailableStep
  ): void {
    const nextList = BreadcrumbState._navigateNextAvailableStep(ctx.getState().breadcrumbList);
    const nextCurrent = BreadcrumbState._getCurrentBreadcrumb(nextList);
    ctx.patchState({
      breadcrumbList: nextList,
      currentBreadcrumb: nextCurrent
    });
    if (action.navigateToUrl) {
      this.store.dispatch(new Navigate([nextCurrent.url]));
    }
  }

  @Action(BreadcrumbAction.NavigatePreviousStep)
  navigatePreviousDeclarationStep(
    ctx: StateContext<BreadCrumbStateModel>,
    action: BreadcrumbAction.NavigatePreviousStep
  ): void {
    const previousList = BreadcrumbState._navigatePreviousStep(ctx.getState().breadcrumbList);
    const previousCurrent = BreadcrumbState._getCurrentBreadcrumb(previousList);
    ctx.patchState({
      breadcrumbList: previousList,
      currentBreadcrumb: previousCurrent
    });
    if (action.navigateToUrl) {
      this.store.dispatch(new Navigate([previousCurrent.url]));
    }
  }

  @Action(BreadcrumbAction.NavigateToIndexStep)
  navigateToIndexDeclarationStep(
    ctx: StateContext<BreadCrumbStateModel>,
    action: BreadcrumbAction.NavigateToIndexStep
  ): void {
    const nextList = BreadcrumbState._navigateToIndexStep(
      ctx.getState().breadcrumbList,
      action.index
    );
    const nextCurrent = BreadcrumbState._getCurrentBreadcrumb(nextList);
    ctx.patchState({
      breadcrumbList: nextList,
      currentBreadcrumb: nextCurrent
    });
    if (action.navigateToUrl) {
      this.store.dispatch(new Navigate([nextCurrent.url]));
    }
  }

  private static _getCurrentBreadcrumb(breadCrumb: BreadcrumbItem[]): BreadcrumbItem | undefined {
    return isNonEmptyArray(breadCrumb)
      ? breadCrumb
          .slice()
          .reverse()
          .find(value => value.status === BreadcrumbStatusEnum.CURRENT)
      : undefined;
  }

  private static _getCurrentBreadcrumbIndex(breadCrumb: BreadcrumbItem[]): number {
    const currentBreadCrumbIndex = isNonEmptyArray(breadCrumb)
      ? breadCrumb
          .slice()
          .reverse()
          .findIndex(value => value.status === BreadcrumbStatusEnum.CURRENT)
      : -1;
    return currentBreadCrumbIndex !== -1 ? breadCrumb.length - 1 - currentBreadCrumbIndex : -1;
  }

  private static _navigateToIndexStep(
    breadCrumb: BreadcrumbItem[],
    indexToNavigateOn: number
  ): BreadcrumbItem[] {
    const arrayToChange = Object.assign([], breadCrumb);
    const currentIndex = BreadcrumbState._getCurrentBreadcrumbIndex(arrayToChange);
    if (currentIndex < indexToNavigateOn) {
      for (let i = indexToNavigateOn; i < currentIndex; i++) {
        const itemToVisit = {
          url: arrayToChange[i].url,
          value: arrayToChange[i].value,
          status: BreadcrumbStatusEnum.VISITED
        };
        arrayToChange.splice(i, 1, itemToVisit);
      }
    } else {
      for (let i = currentIndex; i > indexToNavigateOn; i--) {
        const itemToVisit = {
          url: arrayToChange[i].url,
          value: arrayToChange[i].value,
          status: BreadcrumbStatusEnum.ENABLED
        };
        arrayToChange.splice(i, 1, itemToVisit);
      }
    }
    const itemCHanged = {
      url: arrayToChange[indexToNavigateOn]?.url,
      value: arrayToChange[indexToNavigateOn]?.value,
      status: BreadcrumbStatusEnum.CURRENT
    };
    arrayToChange.splice(indexToNavigateOn, 1, itemCHanged);
    const currentObjectChanged = {
      url: arrayToChange[currentIndex]?.url,
      value: arrayToChange[currentIndex]?.value,
      status:
        indexToNavigateOn > currentIndex
          ? BreadcrumbStatusEnum.VISITED
          : BreadcrumbStatusEnum.ENABLED
    };
    arrayToChange.splice(currentIndex, 1, currentObjectChanged);
    return arrayToChange;
  }

  public static getNextAvailableStep(breadCrumb: BreadcrumbItem[]): BreadcrumbItem | undefined {
    let itemToReturn;
    const currentIndex = this._getCurrentBreadcrumbIndex(breadCrumb);
    for (let i = currentIndex; i < breadCrumb.length; i++) {
      if (breadCrumb[i]?.status === BreadcrumbStatusEnum.ENABLED) {
        itemToReturn = breadCrumb[i];
        break;
      }
    }
    return itemToReturn;
  }

  private static _navigateNextAvailableStep(breadCrumb: BreadcrumbItem[]): BreadcrumbItem[] {
    const arrayToChange = Object.assign([], breadCrumb);
    const currentIndex = this._getCurrentBreadcrumbIndex(arrayToChange);
    for (let i = currentIndex; i < arrayToChange.length; i++) {
      if (arrayToChange[i]?.status === BreadcrumbStatusEnum.ENABLED) {
        const itemChanged = {
          url: arrayToChange[i].url,
          value: arrayToChange[i].value,
          status: BreadcrumbStatusEnum.CURRENT
        };
        arrayToChange.splice(i, 1, itemChanged);
        const currentObjectChanged = {
          url: arrayToChange[currentIndex].url,
          value: arrayToChange[currentIndex].value,
          status: BreadcrumbStatusEnum.VISITED
        };
        arrayToChange.splice(currentIndex, 1, currentObjectChanged);
        break;
      }
    }
    return arrayToChange;
  }

  private static _navigatePreviousStep(breadCrumb: BreadcrumbItem[]): BreadcrumbItem[] {
    const arrayToChange = Object.assign([], breadCrumb);
    const currentIndex = this._getCurrentBreadcrumbIndex(arrayToChange);
    for (let i = currentIndex; i >= 0; i--) {
      if (arrayToChange[i]?.status === BreadcrumbStatusEnum.VISITED) {
        const itemCHanged = {
          url: arrayToChange[i].url,
          value: arrayToChange[i].value,
          status: BreadcrumbStatusEnum.CURRENT
        };
        arrayToChange.splice(i, 1, itemCHanged);
        const currentObjectChanged = {
          url: arrayToChange[currentIndex].url,
          value: arrayToChange[currentIndex].value,
          status: BreadcrumbStatusEnum.ENABLED
        };
        arrayToChange.splice(currentIndex, 1, currentObjectChanged);
        break;
      }
    }
    return arrayToChange;
  }
}
