import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { publishReplay, refCount } from "rxjs/operators";
import { SolSSOUserService } from "./sso-user.service";
import { SolSSOUser } from "../sso-user";

/**
 * Login service should be registered at the application level so it is a singleton
 */
@Injectable()
export class SolSSOLoginService {
  private _userService: SolSSOUserService;

  constructor(userService: SolSSOUserService) {
    this._userService = userService;
    this._user = null;
    this.login();
  }

  private _user: Observable<SolSSOUser>;

  public get user(): Observable<SolSSOUser> {
    this.login();
    return this._user;
  }

  public get isLoggedOff(): boolean {
    return this._user === null;
  }

  public login(): void {
    if (this._user || !this._userService) {
      return;
    }
    const source: Observable<SolSSOUser> = this._userService.get();
    this._user = source.pipe(publishReplay(1), refCount());
  }

  public forceLogin(): Observable<SolSSOUser> {
    const source: Observable<SolSSOUser> = this._userService.get();
    this._user = source.pipe(publishReplay(1), refCount());
    return this._user;
  }

  public logoff(): void {
    this._user = null;
  }

  /**
   * In case of 401 (Unauthorized) or response.status === 0 or === -1 (no more shibboleth session)
   * forces reload of the current page to trigger Shibboleth login. Checks if login was already
   * attempted.
   *
   * If the number of trials is greater than the maximum number authorized attempts redirect to the
   * login page.
   */
  public onUnauthorized(response: Response): void {
    if (response.status !== 0 && response.status !== 401 && response.status !== -1) {
      return;
    }

    // 401 we must try to relog
    if (response.status === 401) {
      this.logoff();
      return;
    }

    //todo: check how timeout works
    // This condition is true whenever a request is sent with a timeout promise (we use the
    //  timeout to cancel the
    // request when a new request is sent)
    // var hasTimeout = response.config.timeout && typeof(response.config.timeout) === 'object';
    //
    //
    //if (!hasTimeout) {
    //  this.displayLogin();
    //  return;
    //}
    //
    // Another condition must be tested to be sur we only reload the page or ask the user to relog
    // only when the
    // shibboleth session is no more available (when the shibboleth cookie expired/is deleted)
    // This condition check if the promise passed in the request timeout is in "Pending" status,
    // the status is pending
    // when the shibboleth session is down, because the promise cannot be resolved. When the
    // shibboleth session is
    // open, the promise status is 1 (or Resolved).
    // if (response.config.timeout.$$state.status === 0) {
    //  this.displayLogin();
    // }
  }
}
