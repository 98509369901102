import { BaseStoreNameSpace } from "solidify-frontend";
import { BreadcrumbItem } from "../../../models/breadcrumb/breadcrumb-item.model";
import { StateEnum } from "../../../models/enum/state-enum";

const state = StateEnum.breadcrumb;

export namespace BreadcrumbAction {
  export class NavigateNextAvailableStep {
    static readonly type: string = "[Breadcrumb] Navigate next available step";

    constructor(public navigateToUrl: boolean = false) {}
  }

  export class NavigatePreviousStep {
    static readonly type: string = "[Breadcrumb] Navigate previous step";

    constructor(public navigateToUrl: boolean = false) {}
  }

  export class NavigateToIndexStep {
    static readonly type: string = "[Breadcrumb] Navigate to index";

    constructor(public index: number, public navigateToUrl: boolean = false) {}
  }

  export class StoreBreadcrumb {
    static readonly type: string = "[Breadcrumb] Store breadcrumb";

    constructor(public breadcrumb: BreadcrumbItem[]) {}
  }

  export class StoreBreadcrumbSuccess {
    static readonly type: string = "[Breadcrumb] Store breadcrumb success";

    constructor() {}
  }

  export class StoreDeclarationBreadcrumbFail {
    static readonly type: string = "[DeclarationBreadcrumb] Store declaration breadcrumb failed";
  }
}

export const breadcrumbNameSpace: BaseStoreNameSpace = BreadcrumbAction;
