import { NgModule } from "@angular/core";
import {
  SolApplicationDirectivesModule,
  SolCommonSharedModule,
  SolLayoutModule,
  SolMaterialModule,
  SolMessageModule,
  SolNavBackModule,
  SolNoResultComponentModule,
  SolPipesModule
} from "common-ng";
import { UnderConstructionPresentationalModule } from "../features/alert/components/presentationals/under-construction/under-construction.presentational.module";
import { ArchivePresentationalModule } from "../features/alert/components/presentationals/archive/archive.presentational.module";

const modules = [
  SolCommonSharedModule,
  SolApplicationDirectivesModule,
  SolPipesModule,
  SolMessageModule,
  SolLayoutModule,
  SolMaterialModule,
  SolNavBackModule,
  SolNoResultComponentModule,
  UnderConstructionPresentationalModule,
  ArchivePresentationalModule
];

@NgModule({
  declarations: [],
  imports: [...modules],
  exports: [...modules],
  providers: []
})
export class SharedModule {}
