import { NgModule } from "@angular/core";
import { SolCommonSharedModule } from "../../shared/shared.module";
import { SolFocusDirective, SolSearchComponent } from "./search.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [SolCommonSharedModule, FormsModule],
  declarations: [SolSearchComponent, SolFocusDirective],
  exports: [SolSearchComponent]
})
export class SolSearchModule {}
