import { ModelUtil } from "../utils/model";

export class SolSSOUser {
  constructor(
    public systemId?: number,
    public uniqueId?: number,
    public email?: string,
    public employeeType?: string[],
    public firstName?: string,
    public lastName?: string,
    public ismemberOf?: string[],
    public roles?: string[],
    public unigeChStudentCategory?: string[],
    public accessToken?: string,
    public accessTokenExpiresTime?: number
  ) {}

  public static create(data: any): SolSSOUser | SolSSOUser[] {
    return ModelUtil.create(SolSSOUser, data);
  }

  public hasRoles(roles: string[] | string): boolean {
    if (roles) {
      if (Array.isArray(roles)) {
        for (const r of this.roles) {
          for (const role of roles) {
            if (role === r) {
              return true;
            }
          }
        }
      } else {
        for (const r of this.roles) {
          if (roles === r) {
            return true;
          }
        }
      }
    }
    return false;
  }
}
