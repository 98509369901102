import { Directive, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CoreAbstractComponent } from "solidify-frontend";

/**
 * Base class used to give generic functionalities to the components extending it
 */
// @ts-ignore
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SolBaseComponent extends CoreAbstractComponent implements OnDestroy {
  private _unsubscribe: Subject<undefined> = new Subject<undefined>();
  unsubscribe$: Observable<undefined> = this._unsubscribe.asObservable();

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unsubscribe.next(undefined);
    this._unsubscribe.complete();
  }
}
