import { NgModule } from "@angular/core";
import { AcademicalYearContainer } from "./academical-year.container";
import { SharedModule } from "../../../../shared/shared.module";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { SliderModule } from "primeng/slider";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [AcademicalYearContainer],
  imports: [SharedModule, NgxsFormPluginModule, SliderModule, FormsModule],
  exports: [AcademicalYearContainer]
})
export class AcademicalYearContainerModule {}
