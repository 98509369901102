/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - base-action.dialog.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import {AbstractInternalDialog} from "../abstract-internal/abstract-internal.dialog";

// @dynamic
@Component({
  selector: "solidify-base-action-dialog",
  templateUrl: "./base-action.dialog.html",
  styleUrls: ["./base-action.dialog.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseActionDialog<TData, UResult> extends AbstractInternalDialog<TData, UResult> {
  constructor(protected readonly _injector: Injector,
              protected readonly _dialogRef: MatDialogRef<BaseActionDialog<TData, UResult>, UResult>,
              @Inject(MAT_DIALOG_DATA) public readonly data: TData) {
    super(_injector, _dialogRef, data);
  }
}
