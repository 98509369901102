/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - counter-subject.model.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {BehaviorSubject} from "rxjs";
import {isNumberReal} from "../../tools/is/is.tool";

export class CounterSubject extends BehaviorSubject<number> {

  private static _toSafeValue(value: any): number {
    return isNumberReal(value) && value >= 0 ? value : 0;
  }

  constructor(initialCount: number = 0) {
    super(CounterSubject._toSafeValue(initialCount));
  }

  next(count: any): void {
    super.next(CounterSubject._toSafeValue(parseFloat(count)));
  }

  hasCount(): boolean {
    return this.getValue() > 0;
  }

  increment(increment: number = 1): number {
    const result = this.getValue() + parseFloat(increment as any);
    this.next(result);
    return result;
  }

  decrement(decrement: number = 1): number {
    const result = this.getValue() - parseFloat(decrement as any);
    this.next(result);
    return result;
  }

}
