/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - relation-3-tiers.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {BaseResourceType} from "../../../models/dto/base-resource.model";
import {CollectionTyped} from "../../../models/dto/collection-typed.model";
import {QueryParameters} from "../../../models/query-parameters/query-parameters.model";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../models/stores/base.action";
import {Relation3TiersForm} from "./relation-3-tiers-form.model";
import {Relation3TiersNameSpace} from "./relation-3-tiers-namespace.model";

export namespace Relation3TiersAction {
  export class GetAll extends BaseAction {
    static readonly type: string = "[{0}] Relation 3 Tiers : Get All";

    constructor(public parentId: string, public queryParameters?: QueryParameters, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetAllSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetAll> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Get All Success";

    constructor(public parentAction: GetAll, public list: CollectionTyped<TResource>) {
      super(parentAction);
    }
  }

  export class GetAllFail extends BaseSubActionFail<GetAll> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Get All Fail";
  }

  export class GetById extends BaseAction {
    static readonly type: string = "[{0}] Relation 3 Tiers : Get By Id";

    constructor(public parentId: string, public id: string, public queryParameters?: QueryParameters, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetByIdSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetById> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Get By Id Success";

    constructor(public parentAction: GetById, public current: TResource) {
      super(parentAction);
    }
  }

  export class GetByIdFail extends BaseSubActionFail<GetById> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Get By Id Fail";
  }

  export class Create extends BaseAction {
    static readonly type: string = "[{0}] Relation 3 Tiers : Create";

    constructor(public parentId: string, public id: string, public listResId: string[]) {
      super();
    }
  }

  export class CreateSuccess extends BaseSubActionSuccess<Create> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Create Success";
  }

  export class CreateFail extends BaseSubActionFail<Create> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Create Fail";
  }

  export class CreateResource extends BaseAction {
    static readonly type: string = "[{0}] Relation 3 Tiers : Create Resource";

    constructor(public parentId: string, public listResId: string[]) {
      super();
    }
  }

  export class CreateResourceSuccess extends BaseSubActionSuccess<CreateResource> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Create Resource Success";
  }

  export class CreateResourceFail extends BaseSubActionFail<CreateResource> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Create Resource Fail";
  }

  export class Delete extends BaseAction {
    static readonly type: string = "[{0}] Relation 3 Tiers : Delete";

    constructor(public parentId: string, public id: string, public listResId: string[]) {
      super();
    }
  }

  export class DeleteSuccess extends BaseSubActionSuccess<Delete> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Delete Success";
  }

  export class DeleteFail extends BaseSubActionFail<Delete> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Delete Fail";
  }

  export class Update extends BaseAction {
    static readonly type: string = "[{0}] Relation 3 Tiers : Update";

    constructor(public parentId: string, public newForm: Relation3TiersForm[], public addCreateDefaultAction: boolean = false) {
      super();
    }
  }

  export class UpdateSuccess extends BaseSubActionSuccess<Update> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Update Success";

    constructor(public parentAction: Update) {
      super(parentAction);
    }
  }

  export class UpdateFail extends BaseSubActionFail<Update> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Update Fail";

    constructor(public parentAction: Update) {
      super(parentAction);
    }
  }

  export class UpdateItem extends BaseAction {
    static readonly type: string = "[{0}] Relation 3 Tiers : Update Item";

    constructor(public parentId: string, public id: string, public object: any) {
      super();
    }
  }

  export class UpdateItemSuccess extends BaseSubActionSuccess<UpdateItem> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Update Item Success";

    constructor(public parentAction: UpdateItem) {
      super(parentAction);
    }
  }

  export class UpdateItemFail extends BaseSubActionFail<UpdateItem> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Update Item Fail";

    constructor(public parentAction: UpdateItem) {
      super(parentAction);
    }
  }

  export class SetGrandChildList extends BaseAction {
    static readonly type: string = "[{0}] Relation 3 Tiers : Set Grand Child List";

    constructor(public parentId: string, public id: string, public listResId: string[]) {
      super();
    }
  }

  export class SetGrandChildListSuccess extends BaseSubActionSuccess<SetGrandChildList> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Set Grand Child List Success";

    constructor(public parentAction: SetGrandChildList) {
      super(parentAction);
    }
  }

  export class SetGrandChildListFail extends BaseSubActionFail<SetGrandChildList> {
    static readonly type: string = "[{0}] Relation 3 Tiers : Set Grand Child List Fail";

    constructor(public parentAction: SetGrandChildList) {
      super(parentAction);
    }
  }
}

export const relation3TiersActionNameSpace: Relation3TiersNameSpace = Relation3TiersAction;
