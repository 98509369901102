import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { SolCommonSharedModule } from "../../shared/shared.module";
import { SolSessionTimeoutService } from "./session-timeout.service";
import { SOL_SESSION_TIMEOUT_OPTIONS, SolSessionTimeoutOptions } from "./session-timeout.constant";
import { SolSessionTimeoutDialogComponent } from "./session-timeout-dialog/session-timeout-dialog.component";

export const USER_OPTIONS = new InjectionToken("SOL_SESSION_TIMEOUT_USER_OPTIONS");

export function sessionTimeoutOptionsFactory(options: SolSessionTimeoutOptions): any {
  const defaultLoggerOptions: SolSessionTimeoutOptions = {
    idleTime: 3540 // 59 minutes
  };

  return {
    ...defaultLoggerOptions,
    ...options
  };
}

@NgModule({
  imports: [SolCommonSharedModule],
  declarations: [SolSessionTimeoutDialogComponent],
  exports: [SolSessionTimeoutDialogComponent]
})
export class SolSessionTimeoutModule {
  static forRoot(options?: SolSessionTimeoutOptions): ModuleWithProviders<SolSessionTimeoutModule> {
    return {
      ngModule: SolSessionTimeoutModule,
      providers: [
        {
          provide: SolSessionTimeoutService,
          useClass: SolSessionTimeoutService,
          multi: false
        },
        {
          provide: USER_OPTIONS,
          useValue: options
        },
        {
          provide: SOL_SESSION_TIMEOUT_OPTIONS,
          useFactory: sessionTimeoutOptionsFactory,
          deps: [USER_OPTIONS]
        }
      ]
    };
  }
}
