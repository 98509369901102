<div class="content">
  <ng-container *ngIf="currentPage === 1">
    <app-academical-year-container [compact]="true"></app-academical-year-container>
    <img class="step1" src="assets/images/tutorial/step1.svg" />
    <div class="page">
      <div class="text">
        Sélectionnez l’année académique désirée pour voir les cours ou plans d’études actuels,
        passés ou futurs.
      </div>
      <div>
        <img src="assets/images/tutorial/tutorial1.svg" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="currentPage === 2">
    <img class="step2" src="assets/images/tutorial/step2.svg" />
    <div class="page">
      <div class="text">Trouvez les cours adaptés à votre cursus en cliquant sur l’onglet.</div>
      <div>
        <img src="assets/images/tutorial/tutorial2.svg" />
      </div>
    </div>
    <div class="teachings">Cours</div>
  </ng-container>
  <ng-container *ngIf="currentPage === 3">
    <img class="step3" src="assets/images/tutorial/step3.svg" />
    <div class="page">
      <div class="text">
        Ou cherchez un plan d’étude pour trouver votre formation idéale et atteindre vos objectifs.
      </div>
      <div>
        <img src="assets/images/tutorial/tutorial1.svg" />
      </div>
    </div>
    <div class="study-plans">Plans d'études</div>
  </ng-container>
  <ng-container *ngIf="currentPage === 4">
    <img class="step4" src="assets/images/tutorial/step4.svg" />
    <div class="page4">
      <div>
        <img src="assets/images/tutorial/detail.png" />
      </div>
      <div class="text">
        Accédez au détail d’un cours sur la page des résultats de votre recherche.
      </div>
      <div>
        <img src="assets/images/tutorial/tutorial4.svg" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="currentPage === 5">
    <app-filter-bar-container [forTutorial]="true"></app-filter-bar-container>
    <img class="step5" src="assets/images/tutorial/step5.svg" />
    <div class="page5">
      <div class="text">Affinez votre recherche avec les filtres dédiés.</div>
      <div>
        <img src="assets/images/tutorial/tutorial5.svg" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="currentPage === 6">
    <div class="login sol-icon-text">
      SE CONNECTER
      <mat-icon>power_settings_new</mat-icon>
    </div>
    <img class="step6" src="assets/images/tutorial/step6.svg" />
    <div class="page">
      <div class="text">
        Connectez-vous pour sélectionner des horaires et constituer votre agenda
      </div>
      <div>
        <img src="assets/images/tutorial/tutorial3.svg" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="currentPage === 7">
    <img class="step7" src="assets/images/tutorial/step7.svg" />
    <div class="page4">
      <div>
        <img src="assets/images/tutorial/teaching_add.png" />
      </div>
      <div class="text">
        Ajoutez un horaire depuis le détail d’un cours ou depuis un groupe dans un plan d’études
      </div>
      <div>
        <img src="assets/images/tutorial/tutorial2.svg" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="currentPage === 8">
    <img class="step8" src="assets/images/tutorial/step8.svg" />
    <div class="page8">
      <div class="text">Manipulez vos horaires depuis "Mes cours"</div>
      <div>
        <img src="assets/images/tutorial/tutorial3.svg" />
      </div>
    </div>
    <div class="my-schedule">Mes horaires</div>
    <mat-tab-group id="schedule-block-list">
      <mat-tab [disabled]="true">
        <ng-template mat-tab-label>Mes cours</ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container *ngIf="currentPage === 9">
    <img class="step9" src="assets/images/tutorial/step9.svg" />
    <div class="page9">
      <div class="text">Visualisez et manipulez vos horaires dans votre semaine type</div>
      <div>
        <img src="assets/images/tutorial/tutorial4.svg" />
      </div>
    </div>
    <div class="my-schedule">Mes horaires</div>
    <mat-tab-group id="typical-week">
      <mat-tab [disabled]="true">
        <ng-template mat-tab-label>Ma semaine type</ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container *ngIf="currentPage === 10">
    <img class="step10" src="assets/images/tutorial/step10.svg" />
    <div class="page10">
      <div>
        <img src="assets/images/tutorial/ical_download.png" />
      </div>
      <div class="text">
        Visualisez vos horaires dans votre calendrier. Vous pouvez exporter vos horaires vers votre
        calendrier de messagerie
      </div>
      <div>
        <img src="assets/images/tutorial/tutorial1.svg" />
      </div>
    </div>
    <div class="my-schedule">Mes horaires</div>
    <mat-tab-group id="my-calendar">
      <mat-tab [disabled]="true">
        <ng-template mat-tab-label>Mon calendrier</ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container *ngIf="currentPage === 11">
    <div class="page11">
      <div class="text">Ça y est, vous êtes prêt à naviguer sur le site.…</div>
      <div>
        <img src="assets/images/tutorial/tutorial7.svg" />
      </div>
    </div>
  </ng-container>
  <a (click)="onDismissClick()" class="close"><img src="assets/images/tutorial/close.svg" /></a>
  <div class="navigation">
    <a (click)="onDismissClick()" class="skip">Passer</a>
    <div class="pages">
      <a
        *ngFor="let number of [1,2,3,4,5,6,7,8,9,10,11]"
        (click)="onPageClick(number)"
        [class.active]="number === currentPage"
        [class.passed]="number < currentPage"
        >{{ number }}</a
      >
    </div>
    <a [class.hide]="currentPage === 1" (click)="onPreviousClick()" class="previous">Précédent</a>
    <a (click)="onNextClick()" class="next"
      >{{ (currentPage < finalPage) ? "Suivant" : "Ok, j’ai compris" }}
      <img src="assets/images/tutorial/chevron.svg"
    /></a>
  </div>
</div>
