import { NgModule } from "@angular/core";
import { SolCommonSharedModule } from "../../shared/shared.module";
import { SolBackToTopComponent } from "./back-to-top.component";

@NgModule({
  imports: [SolCommonSharedModule],
  exports: [SolBackToTopComponent],
  declarations: [SolBackToTopComponent],
  providers: []
})
export class SolBackToTopModule {}
