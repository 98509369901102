import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SolContainerComponent } from "./container.component";
import { SolFooterModule } from "../footer/footer.module";

@NgModule({
  imports: [CommonModule, SolFooterModule],
  declarations: [SolContainerComponent],
  exports: [SolContainerComponent]
})
export class SolContainerModule {}
