import { StudyPlan } from "../models/study-plan.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SolApiResponse, SolApiRespPageInfo, SolAppConfigService, SolSort } from "common-ng";
import { Observable } from "rxjs";
import { SearchForm } from "../models/search-form.model";
import { SearchServiceBase } from "./search-service-base";

@Injectable({
  providedIn: "root"
})
export class StudyPlanService extends SearchServiceBase {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {
    super();
  }

  public searchStudyPlans(
    filters: SearchForm,
    pagination?: SolApiRespPageInfo,
    sorting?: SolSort
  ): Observable<SolApiResponse<StudyPlan>> {
    const httpParams = this.createHttpParams(filters, pagination);

    return this._http.get<SolApiResponse<StudyPlan>>(
      this._appConfig.config.apiRootUrl + "study-plans",
      {
        params: httpParams
      }
    );
  }

  public getStudyPlanById(id: string): Observable<StudyPlan> {
    return this._http.get<StudyPlan>(this._appConfig.config.apiRootUrl + "study-plans/" + id);
  }
}
