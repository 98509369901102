import { Inject, Injectable, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";

/**
 * Util tools for iframe
 */
@Injectable()
export class SolIframeService implements OnInit {
  /**
   * Execute the main functions used to seamlessly integrate a child page in a parent page
   * throughout an iframe located in the "unige.ch" domain.
   */
  constructor(@Inject(DOCUMENT) private _document: any) {
    this.authorizeUnigeParentDomainCommunication();
    this.addIframeClassToHTMLTag();
  }

  public ngOnInit(): void {
    // Seem to be necessary in order for the constructor to be called when added to a component
    // providers list.
  }

  /**
   * Indicate if a page is embedded in an iframe.
   */
  public isEmbedded(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  /**
   * Set _document.domain in order to allow a child page to communicate with its parent _document
   * hosted in the "unige.ch" domain.
   */
  public authorizeUnigeParentDomainCommunication(): void {
    if (window.location.hostname.includes("unige.ch")) {
      try {
        document.domain = "unige.ch";
      } catch (ex) {
        // ignore
      }
    }
  }

  /**
   * Add the CSS class "iframed" to the <html> tag if the loaded page is embedded in an iframe.
   */
  public addIframeClassToHTMLTag(): void {
    if (this.isEmbedded()) {
      this._document.getElementsByTagName("html")[0].classList.add("iframed");
    }
  }
}
