import { Injectable, Injector, Type } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import _merge from "lodash.merge";
import { Observable, of, switchMap, take } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { versionInfo } from "../utils/version-info";
import {
  DateUtil,
  DefaultSolidifyEnvironment,
  SolidifyAppAction,
  SolidifyAppState,
  SolidifyAppStateModel
} from "solidify-frontend";
import { Store } from "@ngxs/store";

@Injectable({
  providedIn: "root"
})
export class SolAppConfigService {
  public config: any;
  public readonly CONSOLE_WARN_NO_ENVIRONMENT_RUNTIME_AVAILABLE: string =
    "common-ng : No environment " +
    "runtime config available, using the compiled environment config";
  public readonly CONSOLE_ERROR_LOADING_RUNTIME_CONFIG: string =
    "common-ng : Error loading environment " +
    'runtime config. An empty file named "environment.runtime.json" must be available in the ' +
    "application assets/ folder";

  constructor(private _injector: Injector) {}

  initApplication(
    environment: DefaultSolidifyEnvironment,
    appState: Type<SolidifyAppState<SolidifyAppStateModel>>
  ): Observable<boolean> {
    const store = this._injector.get(Store);
    new DateUtil(); // Allow to initiate moment in DateUtil
    environment.appState = appState;
    store.dispatch(new SolidifyAppAction.InitApplication());
    return of(true);
  }

  mergeConfigAndInitApplication(
    environment: DefaultSolidifyEnvironment,
    appState: Type<SolidifyAppState<SolidifyAppStateModel>>
  ): Observable<boolean> {
    return this.mergeConfig(environment).pipe(
      take(1),
      switchMap(success => this.initApplication(environment, appState))
    );
  }

  mergeConfig(environment: any): Observable<any> {
    if (environment.envName !== "dev") {
      const http = this._injector.get(HttpClient);

      return http.get("assets/environment.runtime.json?hash=" + versionInfo.cacheBustingHash).pipe(
        tap(runtimeConfig => {
          this.config = _merge(environment, runtimeConfig);
        }),
        catchError(err => {
          if (err.status === 404) {
            console.warn(this.CONSOLE_WARN_NO_ENVIRONMENT_RUNTIME_AVAILABLE);
            this.config = environment;
            return of(this.config); // resolve even if no runtime env config
          } else {
            console.error(this.CONSOLE_ERROR_LOADING_RUNTIME_CONFIG);
            // re-throw the error to reject the Promise
            throw err;
          }
        })
      );
    } else {
      this.config = environment;
      return of(this.config);
    }
  }
}
