<div class="snack-bar-container" data-test="sol-snack-bar">
  <div class="content">
    <div
      [ngClass]="'icon-container-' + data.messageType"
      [ngSwitch]="data.messageType"
      class="icon-container"
    >
      <div *ngSwitchCase="MessageType.WARNING" class="icon">
        <mat-icon>warning</mat-icon>
      </div>
      <div *ngSwitchCase="MessageType.INFO" class="icon">
        <mat-icon>info</mat-icon>
      </div>
      <div *ngSwitchCase="MessageType.SUCCESS" class="icon">
        <mat-icon>done</mat-icon>
      </div>
      <div *ngSwitchCase="MessageType.ERROR" class="icon">
        <mat-icon>error</mat-icon>
      </div>
      <div *ngSwitchDefault class="icon">
        <mat-icon>info</mat-icon>
      </div>
    </div>

    <div [ngClass]="{ 'can-be-dismissed': displayCloseButton() }" class="message">
      <div [innerHTML]="data.message"></div>
      <div *ngIf="displayReloadPageAction()" class="reload-page">
        <button (click)="reloadPage()" class="reload-page-button" mat-button>
          <mat-icon>refresh</mat-icon>
          Recharger la page
        </button>
      </div>
    </div>

    <div *ngIf="displayCloseButton()" class="close-button">
      <button (click)="dismiss()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>
