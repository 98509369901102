import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { StudyPlanDetailsService } from "../../services/study-plan-details.service";
import { StudyPlanNode } from "../../models/study-plan-node.model";
import {
  GetStudyPlanDetails,
  GetStudyPlanDetailsFail,
  GetStudyPlanDetailsSuccess
} from "./study-plan-nodes.action";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class StudyPlanNodesStateModel {
  studyPlanNodes: StudyPlanNode[];
  isLoading: boolean;
}

const defaults: StudyPlanNodesStateModel = {
  studyPlanNodes: [],
  isLoading: false
};

@State<StudyPlanNodesStateModel>({
  name: "StudyPlanDetails",
  defaults
})
@Injectable()
export class StudyPlanNodesState {
  constructor(private _store: Store, private _studyPlanDetailsService: StudyPlanDetailsService) {}

  @Selector()
  static getLoading(state: StudyPlanNodesStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static getStudyPlanDetails(state: StudyPlanNodesStateModel): StudyPlanNode[] {
    return state.studyPlanNodes;
  }

  @Action(GetStudyPlanDetailsSuccess)
  getStudyPlanDetailsSuccess(
    { setState, getState, patchState, dispatch }: StateContext<StudyPlanNodesStateModel>,
    action: GetStudyPlanDetailsSuccess
  ): void {
    patchState({
      studyPlanNodes: action.payload,
      isLoading: false
    });
  }

  @Action(GetStudyPlanDetails, { cancelUncompleted: true })
  getStudyPlanDetails(
    { getState, setState, patchState, dispatch }: StateContext<StudyPlanNodesStateModel>,
    action: GetStudyPlanDetails
  ): Observable<StudyPlanNode> {
    patchState({
      studyPlanNodes: [],
      isLoading: true
    });

    const planId: string = action.payload;
    return this._studyPlanDetailsService.getStudyPlanDetailsById(planId).pipe(
      tap(
        (data: StudyPlanNode) => {
          dispatch(new GetStudyPlanDetailsSuccess([data]));
        },
        error => {
          dispatch(new GetStudyPlanDetailsFail());
          throw error;
        }
      )
    );
  }
}
