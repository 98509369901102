/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - mapping-object.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  isEquality,
  isFunction,
  isMap,
  isTruthyObject,
  isUndefined,
} from "../tools/is/is.tool";
import {MappingObject} from "../types/mapping-type.type";
import {SolidifyObject} from "../types/solidify-object.type";
import {ObjectUtil} from "./object.util";

// @dynamic
export class MappingObjectUtil {
  static keys<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>): TKey[] {
    if (isMap(mappingObject)) {
      const keys = [];
      mappingObject.forEach((value: UValue, key: TKey) => {
        keys.push(key);
      });
      return keys;
    } else {
      const obj = mappingObject as SolidifyObject<TKey, UValue>;
      const keys = ObjectUtil.keys(obj);
      return keys as TKey[];
    }
  }

  static values<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>): UValue[] {
    if (isMap(mappingObject)) {
      const values = [];
      mappingObject.forEach((value: UValue, key: TKey) => {
        values.push(value);
      });
      return values;
    } else {
      const obj = mappingObject as SolidifyObject<TKey, UValue>;
      const keys = ObjectUtil.values(obj);
      return keys as UValue[];
    }
  }

  static toMap<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>): Map<TKey, UValue> {
    if (isMap(mappingObject)) {
      return mappingObject;
    } else {
      const map = new Map<TKey, UValue>();
      const list = Object.entries(mappingObject).filter(([key, value]) => !isUndefined(value));
      list.forEach(([key, value]) => {
        map.set(key as TKey, value as UValue);
      });
      return map;
    }
  }

  static toObject<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>): SolidifyObject<TKey, UValue> {
    if (isMap(mappingObject)) {
      const result = {} as SolidifyObject<TKey, UValue>;
      mappingObject.forEach((value, key) => result[key] = value);
      return result;
    } else {
      return mappingObject;
    }
  }

  static copy<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>): SolidifyObject<TKey, UValue> {
    if (isMap(mappingObject)) {
      return this.toObject(mappingObject);
    } else {
      return Object.assign({}, mappingObject);
    }
  }

  static clear<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>): void {
    if (isTruthyObject(mappingObject)) {
      if (isMap(mappingObject)) {
        mappingObject.clear();
      } else {
        ObjectUtil.clearKeys(mappingObject);
      }
    }
  }

  static delete<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>, key: TKey): boolean {
    if (isTruthyObject(mappingObject)) {
      if (isMap(mappingObject)) {
        return mappingObject.delete(key);
      } else {
        return ObjectUtil.clearKey(mappingObject, this._convertKeyToString(key));
      }
    }
    return false;
  }

  static size<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>): number {
    return this.toMap(mappingObject).size;
  }

  static forEach<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>, callback: (value: UValue, key: TKey, mappingObject: MappingObject<TKey, UValue>) => void, thisArg?: any): void {
    if (!isFunction(callback)) {
      return;
    }
    if (isTruthyObject(mappingObject)) {
      if (isMap(mappingObject)) {
        mappingObject.forEach(callback, thisArg);
      } else {
        ObjectUtil.keys(mappingObject).forEach(key => {
          callback.call(thisArg, mappingObject[key], key, mappingObject);
        });
      }
    }
  }

  static get<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>, key: TKey): UValue | undefined {
    if (isTruthyObject(mappingObject)) {
      if (isMap(mappingObject)) {
        return mappingObject.get(key);
      } else {
        return mappingObject[this._convertKeyToString(key)];
      }
    }
    return undefined;
  }

  static has<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>, key: TKey, checkValueIsDefined?: boolean): boolean {
    if (checkValueIsDefined) {
      return !isUndefined(this.get(mappingObject, key));
    }
    if (isTruthyObject(mappingObject)) {
      if (isMap(mappingObject)) {
        return mappingObject.has(key);
      } else {
        return ObjectUtil.hasKey(mappingObject, this._convertKeyToString(key));
      }
    }
    return false;
  }

  static set<TKey extends string = string, UValue = any>(mappingObject: MappingObject<TKey, UValue>, key: TKey, value: UValue): boolean {
    if (isTruthyObject(mappingObject)) {
      const currenUValue = this.get(mappingObject, key);
      if (isMap(mappingObject)) {
        mappingObject.set(key, value);
      } else {
        mappingObject[this._convertKeyToString(key)] = value;
      }
      return !isEquality(currenUValue, value);
    }
    return false;
  }

  private static _convertKeyToString<TKey extends string = string>(key: TKey): string {
    return String(key);
  }
}
