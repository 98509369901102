/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - resource-read-only.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {BaseResourceType} from "../../../models/dto/base-resource.model";
import {CollectionTyped} from "../../../models/dto/collection-typed.model";
import {QueryParameters} from "../../../models/query-parameters/query-parameters.model";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../models/stores/base.action";
import {ResourceReadOnlyNameSpace} from "./resource-read-only-namespace.model";

export namespace ResourceReadOnlyAction {
  export class ChangeQueryParameters extends BaseAction {
    static readonly type: string = "[{0}] Resource ReadOnly : Change Query Parameters";

    constructor(public queryParameters: QueryParameters, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetAll extends BaseAction {
    static readonly type: string = "[{0}] Resource ReadOnly : Get All";

    constructor(public queryParameters?: QueryParameters, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetAllSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetAll> {
    static readonly type: string = "[{0}] Resource ReadOnly : Get All Success";

    constructor(public parentAction: GetAll, public list: CollectionTyped<TResource>) {
      super(parentAction);
    }
  }

  export class GetAllFail extends BaseSubActionFail<GetAll> {
    static readonly type: string = "[{0}] Resource ReadOnly : Get All Fail";
  }

  export class GetById extends BaseAction {
    static readonly type: string = "[{0}] Resource ReadOnly : Get By Id";

    constructor(public id: string, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetByIdSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetById> {
    static readonly type: string = "[{0}] Resource ReadOnly : Get By Id Success";

    constructor(public parentAction: GetById, public model: TResource) {
      super(parentAction);
    }
  }

  export class GetByIdFail extends BaseSubActionFail<GetById> {
    static readonly type: string = "[{0}] Resource ReadOnly : Get By Id Fail";
  }
}

export const resourceReadOnlyActionNameSpace: ResourceReadOnlyNameSpace = ResourceReadOnlyAction;
