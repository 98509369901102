/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - is.tool.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  FormArray,
  FormGroup,
} from "@angular/forms";
import {Observable} from "rxjs";

/* eslint-disable prefer-arrow/prefer-arrow-functions */
export function isBoolean(value: any): value is boolean {
  return typeof value === "boolean";
}

export function isTrue(value: any): value is true {
  return value === true;
}

export function isFalse(value: any): value is false {
  return value === false;
}

export function isTruthy(value: any): boolean {
  return !isFalsy(value);
}

export function isFalsy(value: any): boolean {
  return !value;
}

export function isFormGroup(value: any): value is FormGroup {
  return isInstanceOf(value, FormGroup) && !!(value as FormGroup).controls;
}

export function isFormArray(value: any): value is FormArray {
  return isInstanceOf(value, FormArray) && !!(value as FormArray).controls;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: any): value is Function {
  return typeof value === "function";
}

export function isNumber(value: any): value is number {
  return typeof value === "number";
}

export function isNumberValid(value: any): boolean {
  return isNumber(value) && !isNaN(value);
}

export function isNumberInvalid(value: any): boolean {
  return isNumber(value) && isNaN(value);
}

export function isNumberFinite(value: any): boolean {
  return isNumber(value) && isFinite(value);
}

export function isNumberInfinite(value: any): boolean {
  return isNumber(value) && !isFinite(value);
}

export function isNumberReal(value: any): boolean {
  return isNumber(value) && !isNaN(value) && isFinite(value);
}

export function isNumberUnreal(value: any): boolean {
  return isNumber(value) && (isNaN(value) || !isFinite(value));
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isObject(value: any): value is object {
  return typeof value === "object" && !isArray(value);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isTruthyObject(value: any): value is {} {
  return isObject(value) && isTruthy(value);
}

export function isArray(value: any): value is any[] {
  return Array.isArray(value);
}

export function isEmptyArray(value: any): boolean {
  return isArray(value) && value.length === 0;
}

export function isNonEmptyArray(value: any): boolean {
  return isArray(value) && value.length > 0;
}

export function isIterable(value: any): value is Iterable<any> {
  return isNotNullNorUndefined(value) && isFunction((value as Iterable<any>)[Symbol.iterator]);
}

export function isMap(value: any): value is Map<any, any> {
  return isInstanceOf(value, Map);
}

export function isSet(value: any): value is Set<any> {
  return isInstanceOf(value, Set);
}

export function isObservable(value: any): value is Observable<any> {
  return isInstanceOf(value, Observable);
}

export function isPromise(value: any): value is Promise<any> {
  return isInstanceOf(value, Promise);
}

export function isElement(value: any): value is Element {
  return isInstanceOf(value, Element);
}

export function isHtmlElement(value: any): value is HTMLElement {
  return isInstanceOf(value, HTMLElement);
}

export function isNull(value: any): value is null {
  return value === null;
}

export function isNotNull(value: any): boolean {
  return !isNull(value);
}

export function isString(value: any): value is string {
  return typeof value === "string";
}

export function isEmptyString(value: any): value is "" {
  return value === "";
}

export function isNonEmptyString(value: any): boolean {
  return isString(value) && value.length > 0;
}

export function isWhiteString(value: any): boolean {
  return isString(value) && value.trim() === "";
}

export function isNonWhiteString(value: any): boolean {
  return isString(value) && value.trim().length > 0;
}

export function isSymbol(value: any): value is symbol {
  return typeof value === "symbol";
}

export function isUndefined(value: any): value is undefined {
  return typeof value === "undefined";
}

export function isDefined(value: any): boolean {
  return !isUndefined(value);
}

export function isNullOrUndefined(value: any): value is null | undefined {
  return isNull(value) || isUndefined(value);
}

export function isNullOrUndefinedOrEmpty(value: any): boolean {
  return isNull(value) || isUndefined(value) || isEmptyString(value);
}

export function isNullOrUndefinedOrEmptyArray(value: any): boolean {
  return isNull(value) || isUndefined(value) || isEmptyArray(value);
}

export function isNullOrUndefinedOrWhiteString(value: any): boolean {
  return isNull(value) || isUndefined(value) || isWhiteString(value);
}

export function isNotNullNorUndefined(value: any): boolean {
  return !isNullOrUndefined(value);
}

export function isNotNullNorUndefinedNorEmpty(value: any): boolean {
  return isNotNullNorUndefined(value) && isNonEmptyString(value);
}

export function isNotNullNorUndefinedNorWhiteString(value: any): boolean {
  return isNotNullNorUndefined(value) && isNonWhiteString(value);
}

export function isReference(value: any): value is Reference {
  return isTruthyObject(value) || isFunction(value);
}

export function isPrimitive(value: any): value is Primitive {
  return !isReference(value);
}

export function isInstanceOf<T>(value: any, prototype: Type<T>): value is T {
  return value instanceof prototype;
}

export function isError(value: any): value is Error {
  return isInstanceOf(value, Error);
}

export function isEquality(valueA: any, valueB: any): boolean {
  return isNaN(valueA) ? isNaN(valueB) : valueA === valueB;
}

export function assertIsNever(value?: any): never {
  throw new Error(`Never expected ${value}`);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type Reference = {} | Function;

export type Primitive = null | boolean | number | string | symbol | undefined;

export type Type<T> = new(...args: any[]) => T;
/* eslint-enable prefer-arrow/prefer-arrow-functions */
