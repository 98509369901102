/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - enum.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {KeyValue} from "../models/key-value.model";
import {isNullOrUndefined} from "../tools/is/is.tool";

export class EnumUtil {
  static convertToArray(enums: any, valueToExclude: any[] = []): any[] {
    const array = [...Object.values(enums)];
    valueToExclude.forEach(v => {
      const index = array.indexOf(v);
      if (index !== -1) {
        array.splice(array.indexOf(v), 1);
      }
    });
    return array;
  }

  static getLabel(listEnum: KeyValue[], key: string): string {
    const keyValue = this.getKeyValue(listEnum, key);
    return keyValue.value;
  }

  static getKeyValue(listEnum: KeyValue[], key: string): KeyValue {
    const item = listEnum.find(i => i.key === key);
    if (isNullOrUndefined(item)) {
      return {
        key: key,
        value: key,
      } as KeyValue;
    }
    return item;
  }
}
