import { NgModule } from "@angular/core";
import { SolBreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageSelectComponent } from "./language-select/language-select.component";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { ChipListInputComponent } from "./chip-list-input/chip-list-input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatInputModule } from "@angular/material/input";
import { SolLoginAsModule } from "./login-as/loginas.module";

const components = [SolBreadcrumbComponent, LanguageSelectComponent, ChipListInputComponent];
const containers = [];
const routables = [];

@NgModule({
  declarations: [...components, ...containers, ...routables],
  imports: [
    SolLoginAsModule,
    CommonModule,
    TranslateModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule
  ],
  exports: [...components, ...containers, ...routables, SolLoginAsModule]
})
export class WidgetModule {}
