import { UntypedFormGroup } from "@angular/forms";
import { Params } from "@angular/router";
import { propertyName } from "common-ng";
import { SearchTypeEnum } from "../enums/search-type.enum";

export enum SearchUrlParameter {
  searchTerm = "searchTerm",
  academicalYear = "year",
  facultyId = "fac",
  teachingDepartmentId = "teachingDep",
  studyPlanDepartmentId = "studyPlanDep",
  studyLevelId = "level",
  personId = "teacher",
  creditMin = "creditMin",
  creditMax = "creditMax"
}

export interface SearchForm {
  searchTerm?: string;
  academicalYear?: string;
  facultyId?: string;
  teachingDepartmentId?: string;
  studyPlanDepartmentId?: string;
  studyLevelId?: string;
  personId?: string;
  creditMin?: number;
  creditMax?: number;
  activityLanguages?: string;
  remoteOnly?: boolean;
  recorded?: boolean;
}

// eslint-disable-next-line no-redeclare
export class SearchForm implements SearchForm {
  constructor(
    public searchTerm?: string,
    public academicalYear?: string,
    public facultyId?: string,
    public teachingDepartmentId?: string,
    public studyPlanDepartmentId?: string,
    public departmentId?: string,
    public studyLevelId?: string,
    public personId?: string,
    public creditMin?: number,
    public creditMax?: number,
    public activityLanguages?: string,
    public shortPeriodicities?: string,
    public days?: string,
    public startHour?: string,
    public endHour?: string,
    public remoteOnly?: boolean,
    public recorded?: boolean
  ) {}

  static fromParams(params: Params): SearchForm {
    const searchForm: SearchForm = new SearchForm();

    Object.keys(SearchUrlParameter).forEach(key => {
      const urlParam: string = SearchUrlParameter[key];
      const value = params[urlParam];
      if (value) {
        searchForm[key] = value;
      }
    });

    return searchForm;
  }
}

export class SearchFormDefinition {
  @propertyName() searchTerm: string;
  @propertyName() academicalYear: string;
  @propertyName() facultyId: string;
  @propertyName() teachingDepartmentId: string;
  @propertyName() studyPlanDepartmentId: string;
  @propertyName() studyLevelId: string;
  @propertyName() personId: string;
  @propertyName() creditMin: string;
  @propertyName() creditMax: string;
  @propertyName() shortPeriodicities: string;
  @propertyName() days: string;
  @propertyName() activityLanguages: string;
  @propertyName() startHour: string;
  @propertyName() endHour: string;
  @propertyName() remoteOnly: string;
  @propertyName() recorded: string;
}

const teachingsUrlParameters = [
  SearchUrlParameter.searchTerm,
  SearchUrlParameter.academicalYear,
  SearchUrlParameter.facultyId,
  SearchUrlParameter.teachingDepartmentId,
  SearchUrlParameter.personId,
  SearchUrlParameter.creditMin,
  SearchUrlParameter.creditMax
];

export function createHttpParamsFromTeachingsForm(form: UntypedFormGroup): Params {
  const params: Params = {};

  teachingsUrlParameters.forEach(urlParam => {
    const formParam = urlToFormParam(urlParam);
    const value = form.get(formParam).value;
    if (value) {
      params[urlParam] = value;
    }
  });

  return params;
}

const studyPlansUrlParameters: string[] = [
  SearchUrlParameter.searchTerm,
  SearchUrlParameter.academicalYear,
  SearchUrlParameter.facultyId,
  SearchUrlParameter.studyPlanDepartmentId,
  SearchUrlParameter.studyLevelId
];

export function createHttpParamsFromStudyPlansForm(form: UntypedFormGroup): Params {
  const params: Params = {};

  studyPlansUrlParameters.forEach(urlParam => {
    const formParam = urlToFormParam(urlParam);
    const value = form.get(formParam).value;
    if (value) {
      params[urlParam] = value;
    }
  });

  return params;
}

function urlToFormParam(urlParam: string): string {
  return Object.keys(SearchUrlParameter).find(key => SearchUrlParameter[key] === urlParam);
}

export function createHttpParamsFromSearchForm(
  searchForm: SearchForm,
  searchType: SearchTypeEnum
): Params {
  const params: Params = {};

  const parameters =
    searchType === SearchTypeEnum.teachings ? teachingsUrlParameters : studyPlansUrlParameters;
  parameters.forEach(urlParam => {
    const formParam = urlToFormParam(urlParam);
    const value = searchForm[formParam];
    if (value) {
      params[urlParam] = value;
    }
  });

  return params;
}
