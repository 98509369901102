import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SolApiResponse, SolAppConfigService } from "common-ng";
import { AcademicalYear } from "../models/academical-year.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AcademicalYearService {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {}

  public getAll(): Observable<AcademicalYear[]> {
    // Size set to 1000 in order to ensure pagination is not limiting the number of element returned
    return this._http
      .get<SolApiResponse<AcademicalYear>>(
        this._appConfig.config.apiRootUrl + "academical-years?size=1000"
      )
      .pipe(map(data => data._data));
  }

  public getCurrentAcademicYear(): Observable<AcademicalYear[]> {
    return this._http
      .get<SolApiResponse<AcademicalYear>>(
        this._appConfig.config.apiRootUrl + "academical-years/search?search=isCurrent:true"
      )
      .pipe(map(data => data._data));
  }

  public getAcademicYearById(id: string): Observable<AcademicalYear> {
    return this._http.get<AcademicalYear>(
      this._appConfig.config.apiRootUrl + "academical-years/" + id
    );
  }
}
