import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StudyPlanNode } from "../models/study-plan-node.model";
import { SolAppConfigService } from "common-ng";

@Injectable({
  providedIn: "root"
})
export class StudyPlanDetailsService {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {}

  public getStudyPlanDetailsById(id: string): Observable<StudyPlanNode> {
    return this._http.get<StudyPlanNode>(
      this._appConfig.config.apiRootUrl + "study-plan-nodes/" + id
    );
  }
}
