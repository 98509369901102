import { Moment } from "moment";

export class SolPerson {
  entityId: string;
  firstname: string;
  lastname: string;
  birth: Moment;
  lastnameEdited: string;
  usualLastname: string;
  previousLastname: string;
  birthName: string;
  firstnameEdited: string;
  usualFirstname: string;
  firstname2: string;
  namesForSearch: string;
  shortNameForSearch: string;
  studentShortName: string;
  employeeShortName: string;
  alternativeLastnames: string;
  gender: string;

  constructor(person?: any) {
    if (person) {
      Object.assign(this, person);
    }
  }

  public isMale(): boolean {
    return this.gender === "M" ? true : false;
  }

  public isFemale(): boolean {
    return this.gender === "F" ? true : false;
  }
}
