<div class="navigation-container">
  <div class="top-navigation">
    <div class="header">
      <div id="logo" [class.visible]="!isSearchVisible">
        <a href="https://www.unige.ch/" class="logo"><img src="assets/images/logo.svg" /></a>
        <a href="https://www.unige.ch/" class="logo-mobile"
          ><img src="assets/images/logo-mobile.svg"
        /></a>
      </div>
      <div class="academical-year">
        <app-academical-year-container
          [compact]="true"
          [class.visible]="isSearchVisible"
          (academicalYearChanged)="changeSearchVisibility()"
        ></app-academical-year-container>
      </div>
      <div id="links">
        <a [routerLink]="['/']" class="link home"><img src="assets/images/home-btn.svg" /></a>
        <a href="https://portail.unige.ch/" class="link">Portail</a>
        <div data-test="login-button-navigation">
          <a
            class="login"
            *ngIf="hasUser()"
            (click)="logout()"
            i18n-title
            mat-button
            title="Déconnexion"
          >
            <span>{{ user.name }}</span>
            <mat-icon>power_settings_new</mat-icon> </a
          ><a
            class="login"
            *ngIf="!hasUser()"
            (click)="login()"
            i18n-title
            mat-button
            title="Connexion"
          >
            <span>Se connecter</span>
            <mat-icon>power_settings_new</mat-icon>
          </a>
        </div>
      </div>
      <div id="links-mobile" [class.visible]="!isSearchVisible">
        <a (click)="toggleSearch($event)" data-test="search-mobile">
          <img id="search-mobile" src="assets/images/search-mobile.svg"
        /></a>
        <a [routerLink]="['/']" class="link home"><img src="assets/images/home-btn.svg" /></a>
        <a class="link login" *ngIf="hasUser()" [matMenuTriggerFor]="appMenu">
          <div class="initial-icon">{{ user?.name?.charAt(0).toUpperCase() }}</div>
        </a>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="logout()">Se déconnecter</button>
        </mat-menu>
        <a class="link login" *ngIf="!hasUser()" (click)="login()">
          <mat-icon>power_settings_new</mat-icon>
        </a>
      </div>
    </div>
    <app-search-bar-container
      *ngIf="!isMySchedule()"
      id="search-bar"
      [class.visible]="isSearchVisible"
      (onSubmit)="onSubmit()"
      (click)="toggleSearch($event)"
    ></app-search-bar-container>
    <div *ngIf="isMySchedule()" id="search-bar-no-filter"></div>
    <ul>
      <li [class.active]="isTeachings()">
        <a (click)="onNavLinkClick('Cours')" data-test="search-teachings">Cours</a>
      </li>
      <li [class.active]="isStudyPlans()">
        <a (click)="onNavLinkClick('Plans d\'études')" data-test="search-study-plans"
          >Plans d'études</a
        >
      </li>
      <li [class.active]="!isStudyPlans() && !isTeachings() && isMySchedule()">
        <a (click)="onNavLinkClick('Mes horaires')" data-test="my-schedule">Mes horaires</a>
      </li>
      <li class="reset" *ngIf="!isMySchedule()">
        <a (click)="resetForm()" data-test="reinit-btn"
          >Réinitialiser les filtres <img src="assets/images/filter-clear.svg"
        /></a>
      </li>
    </ul>
  </div>
</div>
<div class="content">
  <app-filter-bar-container></app-filter-bar-container>
</div>
