import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SolSSOUser } from "../sso-user";

@Injectable({
  providedIn: "root"
})
export class SolSSOUserService {
  private _url: string;

  constructor(private _http: HttpClient) {}

  public get(): Observable<SolSSOUser> {
    this._url = "api/sso-user";
    return this._http
      .get<SolSSOUser>(this._url)
      .pipe(map(data => <SolSSOUser>SolSSOUser.create(data)));
  }
}
