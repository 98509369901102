import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { MemoizedUtil } from "solidify-frontend";
import { Store } from "@ngxs/store";
import { SolBaseComponent } from "../../utils/base-component";
import { SolLoginAsProfileState } from "../stores/login-as/login-as-profile.state";
import { SolLoginAsProfileModel } from "./model/login-as-profile/login-as-profile.model";
import { LoginAsProfileAction } from "../stores/login-as/login-as-profile.action";
import { MatSelectChange } from "@angular/material/select";
import { CookieService } from "ngx-cookie-service";
import { filter } from "rxjs/operators";
import { SolSSOUserService } from "../../user/services/sso-user.service";
import { SolAppConfigService } from "../../config/app-config.service";

const LOGINAS_COOKIE_NAME = "loginas_profile";

@Component({
  selector: "sol-login-as",
  templateUrl: "./login-as.component.html",
  styleUrls: ["./login-as.component.scss"]
})
export class SolLoginAsComponent extends SolBaseComponent implements OnInit {
  readonly defaultValue: string = "Mon profil";
  public runtimeEnvironment: string;

  @Input()
  loginAsProfileSelected: string = this.defaultValue;

  @Input()
  reloadPageOnProfileChange: boolean = true;

  @Output("onLoginAsProfileChanged")
  loginAsProfileChanged: EventEmitter<string> = new EventEmitter<string>();

  onLoginAsProfileChange(loginAsProfile: MatSelectChange): void {
    this.loginAsProfileChanged.emit(loginAsProfile.value);
    if (loginAsProfile.value === this.defaultValue) {
      this._cookieService.delete(LOGINAS_COOKIE_NAME, "/");
    } else {
      this._cookieService.set(LOGINAS_COOKIE_NAME, loginAsProfile.value, {
        path: "/"
      });
    }
    if (this.reloadPageOnProfileChange) {
      window.location.reload();
    }
  }

  isLoadingObs: Observable<boolean> = MemoizedUtil.select(
    this._store,
    SolLoginAsProfileState,
    state => state.isLoading
  );

  loginAsProfilesObs: Observable<SolLoginAsProfileModel[]> = MemoizedUtil.select(
    this._store,
    SolLoginAsProfileState,
    state => state.list
  );

  constructor(
    private _store: Store,
    private _cookieService: CookieService,
    private _ssoUser: SolSSOUserService,
    private _appConfig: SolAppConfigService
  ) {
    super();
    this.runtimeEnvironment = _appConfig.config.envName;

    // eslint-disable-next-line no-console
  }

  ngOnInit(): void {
    super.ngOnInit();

    // filter operator is used to ignore undefined values returned by the Observable
    this.subscribe(this.loginAsProfilesObs.pipe(filter(value => !!value)), value => {
      this.selectProfileBasedOnCookieValue(value);
    });

    this._store.dispatch(new LoginAsProfileAction.GetLoginAsProfileList());
  }

  selectProfileBasedOnCookieValue(profiles: SolLoginAsProfileModel[]): void {
    const profileValueInCookie = this._cookieService.get(LOGINAS_COOKIE_NAME);
    this.loginAsProfileSelected = this.defaultValue;
    for (const profile of profiles) {
      if (profile.name === profileValueInCookie) {
        this.loginAsProfileSelected = profile.name;
        break;
      }
    }
  }

  displayLoginAsOption(): boolean {
    return this.runtimeEnvironment === "test" || this.runtimeEnvironment === "dev";
  }
}
