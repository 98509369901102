/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - app-user.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {StateApplicationEnum} from "../../../../application/enums/state-application.enum";
import {TypeDefaultAction} from "../../../../core/decorators/type-default-action.decorator";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../../core/models/stores/base.action";
import {ResourceAction} from "../../../../core/stores/abstract/resource/resource.action";
import {User} from "../../../models/dto/user.model";
import {SolidifyAppUserNameSpace} from "./app-user-namespace.model";

const state = StateApplicationEnum.application_user;

export namespace SolidifyAppUserAction {
  @TypeDefaultAction(state)
  export class LoadResource extends ResourceAction.LoadResource {
  }

  @TypeDefaultAction(state)
  export class LoadResourceSuccess extends ResourceAction.LoadResourceSuccess {
  }

  @TypeDefaultAction(state)
  export class LoadResourceFail extends ResourceAction.LoadResourceFail {
  }

  @TypeDefaultAction(state)
  export class ChangeQueryParameters extends ResourceAction.ChangeQueryParameters {
  }

  @TypeDefaultAction(state)
  export class GetAll extends ResourceAction.GetAll {
  }

  @TypeDefaultAction(state)
  export class GetAllSuccess<TUser extends User = User> extends ResourceAction.GetAllSuccess<TUser> {
  }

  @TypeDefaultAction(state)
  export class GetAllFail<TUser extends User = User> extends ResourceAction.GetAllFail<TUser> {
  }

  @TypeDefaultAction(state)
  export class GetByListId extends ResourceAction.GetByListId {
  }

  @TypeDefaultAction(state)
  export class GetByListIdSuccess extends ResourceAction.GetByListIdSuccess {
  }

  @TypeDefaultAction(state)
  export class GetByListIdFail extends ResourceAction.GetByListIdFail {
  }

  @TypeDefaultAction(state)
  export class GetById extends ResourceAction.GetById {
  }

  @TypeDefaultAction(state)
  export class GetByIdSuccess<TUser extends User = User> extends ResourceAction.GetByIdSuccess<TUser> {
  }

  @TypeDefaultAction(state)
  export class GetByIdFail<TUser extends User = User> extends ResourceAction.GetByIdFail<TUser> {
  }

  @TypeDefaultAction(state)
  export class Create<TUser extends User = User> extends ResourceAction.Create<TUser> {
  }

  @TypeDefaultAction(state)
  export class CreateSuccess<TUser extends User = User> extends ResourceAction.CreateSuccess<TUser> {
  }

  @TypeDefaultAction(state)
  export class CreateFail<TUser extends User = User> extends ResourceAction.CreateFail<TUser> {
  }

  @TypeDefaultAction(state)
  export class Update<TUser extends User = User> extends ResourceAction.Update<TUser> {
  }

  @TypeDefaultAction(state)
  export class UpdateSuccess<TUser extends User = User> extends ResourceAction.UpdateSuccess<TUser> {
  }

  @TypeDefaultAction(state)
  export class UpdateFail<TUser extends User = User> extends ResourceAction.UpdateFail<TUser> {
  }

  @TypeDefaultAction(state)
  export class Delete extends ResourceAction.Delete {
  }

  @TypeDefaultAction(state)
  export class DeleteSuccess extends ResourceAction.DeleteSuccess {
  }

  @TypeDefaultAction(state)
  export class DeleteFail extends ResourceAction.DeleteFail {
  }

  @TypeDefaultAction(state)
  export class DeleteList extends ResourceAction.DeleteList {
  }

  @TypeDefaultAction(state)
  export class DeleteListSuccess extends ResourceAction.DeleteListSuccess {
  }

  @TypeDefaultAction(state)
  export class DeleteListFail extends ResourceAction.DeleteListFail {
  }

  @TypeDefaultAction(state)
  export class AddInList<TUser extends User = User> extends ResourceAction.AddInList<TUser> {
  }

  @TypeDefaultAction(state)
  export class AddInListById extends ResourceAction.AddInListById {
  }

  @TypeDefaultAction(state)
  export class AddInListByIdSuccess<TUser extends User = User> extends ResourceAction.AddInListByIdSuccess<TUser> {
  }

  @TypeDefaultAction(state)
  export class AddInListByIdFail<TUser extends User = User> extends ResourceAction.AddInListByIdFail<TUser> {
  }

  @TypeDefaultAction(state)
  export class RemoveInListById extends ResourceAction.RemoveInListById {
  }

  @TypeDefaultAction(state)
  export class RemoveInListByListId extends ResourceAction.RemoveInListByListId {
  }

  @TypeDefaultAction(state)
  export class LoadNextChunkList extends ResourceAction.LoadNextChunkList {
  }

  @TypeDefaultAction(state)
  export class LoadNextChunkListSuccess<TUser extends User = User> extends ResourceAction.LoadNextChunkListSuccess<TUser> {
  }

  @TypeDefaultAction(state)
  export class LoadNextChunkListFail extends ResourceAction.LoadNextChunkListFail {
  }

  @TypeDefaultAction(state)
  export class Clean extends ResourceAction.Clean {
  }

  export class GetCurrentUser extends BaseAction {
    static readonly type: string = `[${state}] Get Current User`;
  }

  export class GetCurrentUserSuccess<TUser extends User = User> extends BaseSubActionSuccess<GetCurrentUser> {
    static readonly type: string = `[${state}] Get Current User Success`;

    constructor(public parentAction: GetCurrentUser, public user: TUser) {
      super(parentAction);
    }
  }

  export class GetCurrentUserFail extends BaseSubActionFail<GetCurrentUser> {
    static readonly type: string = `[${state}] Get Current User Fail`;
  }
}

export const solidifyAppUserActionNameSpace: SolidifyAppUserNameSpace = SolidifyAppUserAction;
