import { NgModule } from "@angular/core";
import { NavigationContainer } from "./navigation.container";
import { SharedModule } from "../../../../shared/shared.module";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { SliderModule } from "primeng/slider";
import { FormsModule } from "@angular/forms";
import { AcademicalYearContainerModule } from "../../../academical-year/components/containers/academical-year.container.module";
import { SearchBarContainerModule } from "../../../search-bar/components/containers/search-bar.container.module";
import { FilterBarContainerModule } from "../../../filter-bar/components/containers/filter-bar.container.module";

@NgModule({
  declarations: [NavigationContainer],
  imports: [
    SharedModule,
    NgxsFormPluginModule,
    SliderModule,
    FormsModule,
    SearchBarContainerModule,
    AcademicalYearContainerModule,
    FilterBarContainerModule
  ],
  exports: [NavigationContainer]
})
export class NavigationContainerModule {}
