import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {
  SearchTeachings,
  SearchTeachingsFail,
  SearchTeachingsSuccess,
  UpdatePagination,
  UpdateSorting
} from "./teachings.action";
import { tap } from "rxjs/operators";
import { Teaching } from "../../models/teaching.model";
import { SolApiResponse, SolApiRespPageInfo, SolSort, SolSortOrder } from "common-ng";
import { TeachingService } from "../../services/teaching.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

export class TeachingsStateModel {
  teachings: Teaching[];
  isLoading: boolean;
  sort: SolSort;
  pagination: SolApiRespPageInfo;
  academicYearOfTeaching: string;
}

const defaults: TeachingsStateModel = {
  teachings: [],

  isLoading: false,

  sort: {
    field: "",
    order: SolSortOrder.ASC
  } as SolSort,

  pagination: {
    currentPage: 0,
    sizePage: 10,
    totalItems: 0,
    totalPages: 0
  } as SolApiRespPageInfo,
  academicYearOfTeaching: ""
};

@State<TeachingsStateModel>({
  name: "teachings",
  defaults
})
@Injectable()
export class TeachingsState {
  constructor(private _store: Store, private _teachingService: TeachingService) {}

  @Selector()
  static getTeachings(state: TeachingsStateModel): Teaching[] {
    return state.teachings;
  }

  @Selector()
  static getTeachingAcademicYearValue(state: TeachingsStateModel): string {
    return state.academicYearOfTeaching;
  }

  @Selector()
  static getLoading(state: TeachingsStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static getPagination(state: TeachingsStateModel): SolApiRespPageInfo {
    return state.pagination;
  }

  @Selector()
  static getSort(state: TeachingsStateModel): SolSort {
    return state.sort;
  }

  @Action(SearchTeachings, { cancelUncompleted: true })
  searchTeachings(
    { getState, setState, patchState, dispatch }: StateContext<TeachingsStateModel>,
    { payload }: SearchTeachings
  ): Observable<SolApiResponse<Teaching>> {
    const model = payload.model;
    let selectedYear = "";
    if (model) {
      selectedYear = model.academicalYear;
    }
    patchState({ isLoading: true, academicYearOfTeaching: selectedYear });

    let pagination: SolApiRespPageInfo = { ...defaults.pagination };
    pagination.sizePage = getState().pagination.sizePage;
    let sort = defaults.sort;

    if (payload) {
      if (payload.pagination) {
        pagination = payload.pagination;
      }

      if (!payload.sort) {
        sort = this._store.selectSnapshot(TeachingsState.getSort);
      } else {
        sort = payload.sort;
      }
    }

    return this._teachingService.searchTeachings(model, pagination, sort).pipe(
      tap(
        data => dispatch(new SearchTeachingsSuccess(data)),
        error => {
          dispatch(new SearchTeachingsFail());
          throw error;
        }
      )
    );
  }

  @Action(SearchTeachingsSuccess)
  searchTeachingsSuccess(
    { setState, getState, patchState, dispatch }: StateContext<TeachingsStateModel>,
    { payload }: SearchTeachingsSuccess
  ): void {
    patchState({
      teachings: payload._data,
      isLoading: false
    });

    dispatch(
      new UpdatePagination({
        ...payload._page
      })
    );

    dispatch(new UpdateSorting(null));
  }

  @Action(UpdatePagination)
  updatePagination(
    { getState, patchState }: StateContext<TeachingsStateModel>,
    { payload }: UpdatePagination
  ): void {
    patchState({
      pagination: {
        ...defaults.pagination,
        ...payload
      }
    });
  }
}
