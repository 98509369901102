/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - memoized.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {Type} from "@angular/core";
import {Store} from "@ngxs/store";
import {Observable} from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  take,
} from "rxjs/operators";
import {BaseRelationResourceType} from "../../models/dto/base-relation-resource.model";
import {BaseResourceType} from "../../models/dto/base-resource.model";
import {QueryParameters} from "../../models/query-parameters/query-parameters.model";
import {BaseStateModel} from "../../models/stores/base-state.model";
import {AssociationNoSqlReadOnlyStateModel} from "../../stores/abstract/association-no-sql-read-only/association-no-sql-read-only-state.model";
import {AssociationNoSqlReadOnlyState} from "../../stores/abstract/association-no-sql-read-only/association-no-sql-read-only.state";
import {AssociationRemoteStateModel} from "../../stores/abstract/association-remote/association-remote-state.model";
import {AssociationRemoteState} from "../../stores/abstract/association-remote/association-remote.state";
import {AssociationStateModel} from "../../stores/abstract/association/association-state.model";
import {AssociationState} from "../../stores/abstract/association/association.state";
import {BasicState} from "../../stores/abstract/base/basic.state";
import {CompositionStateModel} from "../../stores/abstract/composition/composition-state.model";
import {CompositionState} from "../../stores/abstract/composition/composition.state";
import {Relation2TiersStateModel} from "../../stores/abstract/relation-2-tiers/relation-2-tiers-state.model";
import {Relation2TiersState} from "../../stores/abstract/relation-2-tiers/relation-2-tiers.state";
import {Relation3TiersStateModel} from "../../stores/abstract/relation-3-tiers/relation-3-tiers-state.model";
import {Relation3TiersState} from "../../stores/abstract/relation-3-tiers/relation-3-tiers.state";
import {ResourceNoSqlReadOnlyStateModel} from "../../stores/abstract/resource-no-sql-read-only/resource-no-sql-read-only-state.model";
import {ResourceNoSqlReadOnlyState} from "../../stores/abstract/resource-no-sql-read-only/resource-no-sql-read-only.state";
import {ResourceReadOnlyStateModel} from "../../stores/abstract/resource-read-only/resource-read-only-state.model";
import {ResourceReadOnlyState} from "../../stores/abstract/resource-read-only/resource-read-only.state";
import {ResourceStateModel} from "../../stores/abstract/resource/resource-state.model";
import {ResourceState} from "../../stores/abstract/resource/resource.state";
import {
  isFunction,
  isNullOrUndefined,
} from "../../tools/is/is.tool";

// @dynamic
export class MemoizedUtil {
  static select<TStateModel extends BaseStateModel, T>(store: Store,
                                                       ctor: Type<BasicState<TStateModel>>,
                                                       selector: (state: TStateModel) => T,
                                                       distinct?: boolean | ((x: T, y: T) => boolean)): Observable<T> {
    const memoizedSelector = store.select(ctor).pipe(
      filter(state => !isNullOrUndefined(state)),
      map(value => selector(value)),
    );
    return distinct ? memoizedSelector.pipe(isFunction(distinct) ? distinctUntilChanged(distinct) : distinctUntilChanged()) : memoizedSelector;
  }

  static selectSnapshot<TStateModel extends BaseStateModel, T>(store: Store,
                                                               ctor: Type<BasicState<TStateModel>>,
                                                               selector: (state: TStateModel) => T): T {
    return selector(store.selectSnapshot(ctor));
  }

  static selectOnce<TStateModel extends BaseStateModel, T>(store: Store,
                                                           ctor: Type<BasicState<TStateModel>>,
                                                           selector: (state: TStateModel) => T): Observable<T> {
    return store.select(ctor).pipe(
      filter(state => !isNullOrUndefined(state)),
      take(1),
      map(value => selector(value)),
    );
  }

  static isLoading<TStateModel extends BaseStateModel>(store: Store, ctor: Type<BasicState<TStateModel>>): Observable<boolean> {
    return this.select(store, ctor, state => state.isLoadingCounter > 0, true);
  }

  static isLoadingSnapshot<TStateModel extends BaseStateModel>(store: Store, ctor: Type<BasicState<TStateModel>>): boolean {
    return this.selectSnapshot(store, ctor, state => state.isLoadingCounter > 0);
  }

  static selected<TStateModel extends AssociationStateModel<TResource>
    | AssociationNoSqlReadOnlyStateModel<TResource>
    | AssociationRemoteStateModel<TResource>
    | Relation2TiersStateModel<TResource>
    | Relation3TiersStateModel<TResource>
    , TResource extends BaseResourceType, TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: Type<AssociationState<TStateModel, TResource>>
      | Type<AssociationNoSqlReadOnlyState<TStateModel, TResource>>
      | Type<AssociationRemoteState<TStateModel, TResource>>
      | Type<Relation2TiersState<TStateModel, TResource, TRelation>>
      | Type<Relation3TiersState<TStateModel, TResource, TRelation>>,
  ): Observable<TResource[]> {
    return MemoizedUtil.select(store, ctor, state => state.selected, true);
  }

  static selectedSnapshot<TStateModel extends AssociationStateModel<TResource>
    | AssociationNoSqlReadOnlyStateModel<TResource>
    | AssociationRemoteStateModel<TResource>
    | Relation2TiersStateModel<TResource>
    | Relation3TiersStateModel<TResource>
    , TResource extends BaseResourceType, TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: Type<AssociationState<TStateModel, TResource>>
      | Type<AssociationNoSqlReadOnlyState<TStateModel, TResource>>
      | Type<AssociationRemoteState<TStateModel, TResource>>
      | Type<Relation2TiersState<TStateModel, TResource, TRelation>>
      | Type<Relation3TiersState<TStateModel, TResource, TRelation>>,
  ): TResource[] {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.selected);
  }

  static list<TStateModel extends CompositionStateModel<TResource>
    | ResourceStateModel<TResource>
    | ResourceNoSqlReadOnlyStateModel<TResource>
    | ResourceReadOnlyStateModel<TResource>
    , TResource extends BaseResourceType, TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: Type<CompositionState<TStateModel, TResource>>
      | Type<ResourceState<TStateModel, TResource>>
      | Type<ResourceNoSqlReadOnlyState<TStateModel, TResource>>
      | Type<ResourceReadOnlyState<TStateModel, TResource>>,
  ): Observable<TResource[]> {
    return MemoizedUtil.select(store, ctor, state => state.list, true);
  }

  static listSnapshot<TStateModel extends CompositionStateModel<TResource>
    | ResourceStateModel<TResource>
    | ResourceNoSqlReadOnlyStateModel<TResource>
    | ResourceReadOnlyStateModel<TResource>
    , TResource extends BaseResourceType, TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: Type<CompositionState<TStateModel, TResource>>
      | Type<ResourceState<TStateModel, TResource>>
      | Type<ResourceNoSqlReadOnlyState<TStateModel, TResource>>
      | Type<ResourceReadOnlyState<TStateModel, TResource>>,
  ): TResource[] {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.list);
  }

  static total<TStateModel extends MemoizedUtilState<TResource>,
    TResource extends BaseResourceType,
    TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: MemoizedUtilStateType<TStateModel, TResource, TRelation>,
  ): Observable<number> {
    return MemoizedUtil.select(store, ctor, state => state.total, true);
  }

  static totalSnapshot<TStateModel extends MemoizedUtilState<TResource>,
    TResource extends BaseResourceType,
    TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: MemoizedUtilStateType<TStateModel, TResource, TRelation>,
  ): number {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.total);
  }

  static current<TStateModel extends MemoizedUtilState<TResource>,
    TResource extends BaseResourceType,
    TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: MemoizedUtilStateType<TStateModel, TResource, TRelation>,
  ): Observable<TResource> {
    return MemoizedUtil.select(store, ctor, state => state.current, true);
  }

  static currentSnapshot<TStateModel extends MemoizedUtilState<TResource>,
    TResource extends BaseResourceType,
    TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: MemoizedUtilStateType<TStateModel, TResource, TRelation>,
  ): TResource | undefined {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.current);
  }

  static queryParameters<TStateModel extends MemoizedUtilState<TResource>,
    TResource extends BaseResourceType,
    TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: MemoizedUtilStateType<TStateModel, TResource, TRelation>,
  ): Observable<QueryParameters> {
    return MemoizedUtil.select(store, ctor, state => state.queryParameters, true);
  }

  static queryParametersSnapshot<TStateModel extends MemoizedUtilState<TResource>,
    TResource extends BaseResourceType,
    TRelation extends BaseRelationResourceType>(
    store: Store,
    ctor: MemoizedUtilStateType<TStateModel, TResource, TRelation>,
  ): QueryParameters {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.queryParameters);
  }
}

export type MemoizedUtilState<TResource extends BaseResourceType> = AssociationStateModel<TResource>
  | AssociationNoSqlReadOnlyStateModel<TResource>
  | AssociationRemoteStateModel<TResource>
  | CompositionStateModel<TResource>
  | Relation2TiersStateModel<TResource>
  | Relation3TiersStateModel<TResource>
  | ResourceStateModel<TResource>
  | ResourceNoSqlReadOnlyStateModel<TResource>
  | ResourceReadOnlyStateModel<TResource>;

export type MemoizedUtilStateType<TStateModel extends MemoizedUtilState<TResource>,
  TResource extends BaseResourceType,
  TRelation extends BaseRelationResourceType> = Type<AssociationState<TStateModel, TResource>>
  | Type<AssociationNoSqlReadOnlyState<TStateModel, TResource>>
  | Type<AssociationRemoteState<TStateModel, TResource>>
  | Type<CompositionState<TStateModel, TResource>>
  | Type<Relation2TiersState<TStateModel, TResource, TRelation>>
  | Type<Relation3TiersState<TStateModel, TResource, TRelation>>
  | Type<ResourceState<TStateModel, TResource>>
  | Type<ResourceNoSqlReadOnlyState<TStateModel, TResource>>
  | Type<ResourceReadOnlyState<TStateModel, TResource>>;
