import { StudentScheduleBlock } from "../../models/student-schedule-block.model";

export class ChangeSemesterSelected {
  static readonly type: string = "[Schedule] Change semester selected";

  constructor(public semester?: string) {}
}

export class FetchCalendarEvents {
  static readonly type: string = "[Schedule] Fetch current calendar events";

  constructor(public minDate?: string, public maxDate?: string) {}
}

export class FetchTypicalWeekEvents {
  static readonly type: string = "[Schedule] Fetch current typical week events";

  constructor(public academicalYear?: string) {}
}

export class FetchStudentScheduleBlocks {
  static readonly type: string = "[Schedule] Fetch student schedule block";

  constructor(public academicalYear?: string) {}
}

export class DeleteStudentScheduleBlock {
  static readonly type: string = "[Schedule] delete student schedule block";

  constructor(public ssbId?: string) {}
}

export class DeleteStudentScheduleBlockList {
  static readonly type: string = "[Schedule] delete student schedule block list";

  constructor(public ssbIdList?: string[]) {}
}

export class UpdateStudentScheduleBlockList {
  static readonly type: string = "[Schedule] update student schedule block list";

  constructor(public ssbList?: StudentScheduleBlock[]) {}
}

export class UpdateStudentScheduleBlockSelection {
  static readonly type: string = "[Schedule] update student schedule block selection";

  constructor(public ssbSelection?: Map<string, boolean>) {}
}
