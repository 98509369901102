import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SolMaterialModule } from "../../../shared/material.module";
import {
  SolSidenavComponent,
  SolSidenavContainerComponent,
  SolSidenavContentComponent
} from "./sidenav.component";
import { SolFooterModule } from "../footer/footer.module";

@NgModule({
  imports: [CommonModule, SolMaterialModule, SolFooterModule],
  declarations: [SolSidenavComponent, SolSidenavContainerComponent, SolSidenavContentComponent],
  exports: [SolSidenavComponent, SolSidenavContainerComponent, SolSidenavContentComponent]
})
export class SolSidenavModule {}
