import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Sol404ErrorComponent } from "./404-error.component";
import { SolMessageModule } from "../../../../directives/message/message.module";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  imports: [CommonModule, SolMessageModule, MatButtonModule],
  exports: [Sol404ErrorComponent],
  declarations: [Sol404ErrorComponent]
})
export class Sol404ErrorModule {}
