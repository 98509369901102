/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - confirm.dialog.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import {SOLIDIFY_CONSTANTS} from "../../../constants";
import {PropertyName} from "../../../decorators/property-name.decorator";
import {ButtonColorEnum} from "../../../enums/button-color.enum";
import {FormValidationHelper} from "../../../helpers/form-validation.helper";
import {BaseFormDefinition} from "../../../models/forms/base-form-definition.model";
import {
  isNonEmptyString,
  isNotNullNorUndefinedNorWhiteString,
  isNullOrUndefined,
  isNumberValid,
  isTrue,
} from "../../../tools/is/is.tool";
import {SolidifyObject} from "../../../types/solidify-object.type";
import {SolidifyValidator} from "../../../utils/validations/validation.util";
import {AbstractInternalDialog} from "../abstract-internal/abstract-internal.dialog";

@Component({
  selector: "solidify-confirm-dialog",
  templateUrl: "./confirm.dialog.html",
  styleUrls: ["./confirm.dialog.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialog extends AbstractInternalDialog<ConfirmDialogData, boolean | string> implements OnInit {
  form: FormGroup;
  formDefinition: FormComponentFormDefinition = new FormComponentFormDefinition();

  constructor(protected readonly _dialogRef: MatDialogRef<ConfirmDialog>,
              @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmDialogData,
              protected readonly _injector: Injector,
              private readonly _fb: FormBuilder) {
    super(_injector, _dialogRef, data);
  }

  ngOnInit(): void {
    super.ngOnInit();

    if (isNotNullNorUndefinedNorWhiteString(this.data.withInput)) {
      const validators = [SolidifyValidator];
      if (isTrue(this.data.inputRequired)) {
        validators.push(Validators.required);
      }
      if (isNumberValid(this.data.inputMaxLimit)) {
        validators.push(Validators.maxLength(this.data.inputMaxLimit));
      }
      this.form = this._fb.group({
        [this.formDefinition.textOrComment]: [isNotNullNorUndefinedNorWhiteString(this.data.inputInitialValue) ? this.data.inputInitialValue : SOLIDIFY_CONSTANTS.STRING_EMPTY, validators],
      });
    }
  }

  get confirmDialogInputEnum(): typeof ConfirmDialogInputEnum {
    return ConfirmDialogInputEnum;
  }

  get colorConfirm(): ButtonColorEnum {
    return isNullOrUndefined(this.data?.colorConfirm) ? ButtonColorEnum.primary : this.data.colorConfirm;
  }

  get colorCancel(): ButtonColorEnum {
    return isNullOrUndefined(this.data?.colorCancel) ? ButtonColorEnum.primary : this.data.colorCancel;
  }

  confirm(): void {
    if (isNotNullNorUndefinedNorWhiteString(this.data.withInput)) {
      const message = this.form.get(this.formDefinition.textOrComment).value;
      this.submit(isNonEmptyString(message) ? message : true);
    } else {
      this.submit(true);
    }
  }

  get formValidationHelper(): typeof FormValidationHelper {
    return FormValidationHelper;
  }

  getFormControl(key: string): FormControl {
    return FormValidationHelper.getFormControl(this.form, key);
  }
}

export interface ConfirmDialogData {
  titleToTranslate: string;
  innerHtmlOnTitle: boolean;
  paramTitle?: SolidifyObject | undefined;
  messageToTranslate?: string | undefined;
  innerHtmlOnMessage: boolean;
  paramMessage?: SolidifyObject | undefined;
  confirmButtonToTranslate?: string | undefined;
  cancelButtonToTranslate?: string | undefined;
  colorConfirm?: ButtonColorEnum;
  colorCancel?: ButtonColorEnum;
  withInput?: ConfirmDialogInputEnum | undefined;
  inputRequired?: boolean;
  inputInitialValue?: string;
  inputMaxLimit?: number | undefined;
  inputLabelToTranslate?: string | undefined;
}

class FormComponentFormDefinition extends BaseFormDefinition {
  @PropertyName() textOrComment: string;
}

export type ConfirmDialogInputEnum =
  "TEXTAREA"
  | "INPUT_TEXT";

export const ConfirmDialogInputEnum = {
  TEXTAREA: "TEXTAREA" as ConfirmDialogInputEnum,
  INPUT_TEXT: "INPUT_TEXT" as ConfirmDialogInputEnum,
};
