/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - focus-first-element.directive.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Directive,
  ElementRef,
  Input,
} from "@angular/core";
import {SsrUtil} from "../../utils/ssr.util";

// @dynamic
@Directive({
  selector: "[solidifyFocusFirstElement]",
})
export class FocusFirstElementDirective {

  private _focusFirstElement: boolean = false;

  @Input("solidifyFocusFirstElement")
  set focusFirstElement(focusFirstElement: boolean) {
    if (this._focusFirstElement !== focusFirstElement) {
      this._focusFirstElement = focusFirstElement;
      this.onFocusFirstElementChange();
    }

  }

  get focusFirstElement(): boolean {
    return this._focusFirstElement;
  }

  get nativeElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  get document(): Document {
    return SsrUtil.window?.document;
  }

  public constructor(private readonly _elementRef: ElementRef) {
  }

  onFocusFirstElementChange(): void {
    if (this._focusFirstElement) {
      this.doFocusFirstElement();
    }
  }

  doFocusFirstElement(needToFocusFirstElement: boolean = this.focusFirstElement): void {
    if (SsrUtil.isServer) {
      return;
    }
    setTimeout(() => {
      let hasFocused = false;
      this.nativeElement.querySelectorAll("*").forEach((elementToFocus: HTMLElement) => {
        if (!hasFocused) {
          elementToFocus.focus();
          if (this.document.activeElement === elementToFocus) {
            hasFocused = true;
          }
        }
      });
    });
  }

}
