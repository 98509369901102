/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - mat-button-theme.directive.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from "@angular/core";
import {ButtonThemeEnum} from "../../enums/button-theme.enum";
import {isTrue} from "../../tools/is/is.tool";
import {EnumUtil} from "../../utils/enum.util";

// @dynamic
@Directive({
  selector: "[solidifyMatButtonTheme]",
})
export class MatButtonThemeDirective {

  static readonly themeClassNameMatPrefix: string = "mat-";

  static readonly defaultThemeClassName: ButtonThemeEnum = ButtonThemeEnum.button;

  private static _themeClassNames: ButtonThemeEnum[] = EnumUtil.convertToArray(ButtonThemeEnum);

  static getThemeClassNames(addPrefix?: boolean): string[] {
    return isTrue(addPrefix) ? this._themeClassNames.map(themeClassName => this.themeClassNameMatPrefix + themeClassName) : [...this._themeClassNames];
  }

  private _class: ButtonThemeEnum = MatButtonThemeDirective.defaultThemeClassName;

  @Input("solidifyMatButtonTheme")
  set class(value: ButtonThemeEnum) {
    this._class = value || MatButtonThemeDirective.defaultThemeClassName;
    this._onThemeClassNameChange();
  }

  get class(): ButtonThemeEnum {
    return this._class;
  }

  constructor(protected readonly _elementRef: ElementRef,
              protected readonly _renderer: Renderer2) {
  }

  private _onThemeClassNameChange(): void {
    MatButtonThemeDirective.getThemeClassNames(true)
      .forEach(themeClassNameToRemove => this._renderer.removeClass(this._elementRef.nativeElement, themeClassNameToRemove));
    this._renderer.addClass(this._elementRef.nativeElement, MatButtonThemeDirective.themeClassNameMatPrefix + this._class);
  }

}
