/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - validation.directive.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  OnInit,
  Renderer2,
  Self,
} from "@angular/core";
import {
  FormControlStatus,
  NgControl,
  ValidationErrors,
} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {
  filter,
  tap,
} from "rxjs/operators";
import {LabelTranslateInterface} from "../../../label-translate-interface.model";
import {SOLIDIFY_CONSTANTS} from "../../constants";
import {DefaultSolidifyEnvironment} from "../../environments/environment.solidify-defaults";
import {FormValidationHelper} from "../../helpers/form-validation.helper";
import {ENVIRONMENT} from "../../injection-tokens/environment.injection-token";
import {LABEL_TRANSLATE} from "../../injection-tokens/label-to-translate.injection-token";
import {
  isArray,
  isNonEmptyArray,
  isNotNullNorUndefined,
  isNullOrUndefined,
  isTrue,
  isUndefined,
} from "../../tools/is/is.tool";
import {MappingObject} from "../../types/mapping-type.type";
import {MappingObjectUtil} from "../../utils/mapping-object.util";
import {ObjectUtil} from "../../utils/object.util";
import {CoreAbstractDirective} from "../core-abstract/core-abstract.directive";

@Directive({
  selector: "[solidifyValidation]",
})
export class ValidationDirective extends CoreAbstractDirective implements OnInit {
  private _previousErrors: ValidationErrors;

  static readonly ERROR_REQUIRED: string = "required";
  private readonly _ERROR_BACKEND: string = "errorsFromBackend";

  private readonly _REGISTRY_INVALID_KEY: string = SOLIDIFY_CONSTANTS.FORM_STATUS_INVALID;

  @Input("solidifyValidation")
  matError: any | undefined;

  private _environment: DefaultSolidifyEnvironment | undefined;

  get environment(): DefaultSolidifyEnvironment {
    if (isUndefined(this._environment)) {
      this._environment = this._injector.get(ENVIRONMENT);
    }
    return this._environment;
  }

  private _labelTranslate: LabelTranslateInterface | undefined;

  get labelTranslate(): LabelTranslateInterface {
    if (isUndefined(this._labelTranslate)) {
      this._labelTranslate = this._injector.get(LABEL_TRANSLATE);
    }
    return this._labelTranslate;
  }

  constructor(private readonly _injector: Injector,
              @Self() private readonly _ngControl: NgControl,
              private readonly _elementRef: ElementRef,
              private readonly _renderer: Renderer2,
              private readonly _translate: TranslateService,
              private readonly _changeDetector: ChangeDetectorRef) {
    super();
  }

  private addMetadataError(errorKey: string, errorMessage: string): void {
    MappingObjectUtil.set(this.getMetadataErrors(), errorKey, errorMessage);
  }

  private getMetadataErrors(): MappingObject<string, string> {
    return FormValidationHelper.getMetadataErrors(this._ngControl.control);
  }

  ngOnInit(): void {
    FormValidationHelper.initMetadataErrors(this._ngControl.control);
    this._updateErrorsMetadata(this._ngControl.control.status);
    this.subscribe(this._ngControl.control.valueChanges.pipe(
      tap(validity => {
        const fc = this._ngControl.control;
        if (!fc.invalid) {
          return;
        }
        if (MappingObjectUtil.has(fc.errors, this._ERROR_BACKEND) && MappingObjectUtil.size(fc.errors) === 1) {
          fc.updateValueAndValidity();
        }
      })),
    );

    this.subscribe(this._ngControl.control.statusChanges.pipe(
      filter(s => this._isDifferent()),
      tap(validity => {
        this._updateErrorsMetadata(validity);
      })),
    );
  }

  private _updateErrorsMetadata(validity: FormControlStatus): void {
    const errors = this._ngControl.control.errors;
    switch (validity) {
      case SOLIDIFY_CONSTANTS.FORM_STATUS_VALID:
        this._cleanExistingErrors();
        break;
      case SOLIDIFY_CONSTANTS.FORM_STATUS_INVALID:
        this._computeNewErrors(errors);
        break;
    }
    this._previousErrors = ObjectUtil.clone(errors);
  }

  private _cleanExistingErrors(): void {
    MappingObjectUtil.clear(this.getMetadataErrors());

    if (this.matError) {
      this.matError.textContent = SOLIDIFY_CONSTANTS.STRING_EMPTY;
    }

    this._changeDetector.detectChanges();
  }

  private _isDifferent(): boolean {
    let newErrorsObject = this._ngControl.control.errors;
    let oldErrorsObject = this._previousErrors;

    if (isNullOrUndefined(newErrorsObject)) {
      newErrorsObject = {};
    }
    if (isNullOrUndefined(oldErrorsObject)) {
      oldErrorsObject = {};
    }

    if (Object.getOwnPropertyNames(newErrorsObject).length !== Object.getOwnPropertyNames(oldErrorsObject).length) {
      return true;
    }

    for (const property of Object.getOwnPropertyNames(newErrorsObject)) {
      if (oldErrorsObject.hasOwnProperty(property) && typeof oldErrorsObject[property] === typeof newErrorsObject[property]) {

        if (isArray(oldErrorsObject[property])) {
          if (oldErrorsObject[property].length !== newErrorsObject[property].length) {
            return true;
          }
          for (let i = 0; i < oldErrorsObject[property].length; i++) {
            if (oldErrorsObject[property][i] !== newErrorsObject[property][i]) {
              return true;
            }
          }
        } else {
          if (oldErrorsObject[property] !== newErrorsObject[property]) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private _computeNewErrors(nativeErrors: ValidationErrors): void {
    let errorFound = MappingObjectUtil.size(this.getMetadataErrors()) > 0;
    if (isNullOrUndefined(nativeErrors)) {
      throw Error("Should never be here");
    }

    if (isTrue(nativeErrors[ValidationDirective.ERROR_REQUIRED]) && !MappingObjectUtil.has(this.getMetadataErrors(), ValidationDirective.ERROR_REQUIRED)) {
      errorFound = true;
      this.addMetadataError(ValidationDirective.ERROR_REQUIRED, this._translate.instant(this.labelTranslate.coreValidationErrorRequiredToTranslate));
    }

    if (isNotNullNorUndefined(nativeErrors[this._ERROR_BACKEND]) && isNonEmptyArray(nativeErrors[this._ERROR_BACKEND])) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < nativeErrors[this._ERROR_BACKEND].length; i++) {
        const key = this._ERROR_BACKEND + nativeErrors[this._ERROR_BACKEND][i];
        if (!MappingObjectUtil.has(this.getMetadataErrors(), key)) {
          errorFound = true;
          this.addMetadataError(key, this._translate.instant(nativeErrors[this._ERROR_BACKEND][i]));
        }
      }
    }

    if (!errorFound) {
      this.addMetadataError(this._REGISTRY_INVALID_KEY, this._translate.instant(this.labelTranslate.coreValidationErrorInvalidToTranslate));
    }

    if (this.matError) {
      this.matError.textContent = FormValidationHelper.getFormMetadataErrorFormattedString(this._ngControl.control);
    }

    this._changeDetector.detectChanges();
  }
}
