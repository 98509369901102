/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - validation.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import {SOLIDIFY_CONSTANTS} from "../../constants";
import {
  isFormArray,
  isFormGroup,
  isNotNullNorUndefined,
} from "../../tools/is/is.tool";

export const SOLIDIFY_ERRORS: string = "SOLIDIFY_META_ERRORS";

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
export const SolidifyValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const result = control[SOLIDIFY_ERRORS];
  delete control[SOLIDIFY_ERRORS];
  return result;
};

export class ValidationUtil {
  static collectErrorsInNestedObject(control: AbstractControl): any | null {
    if (isFormGroup(control)) {
      return Object.entries(control.controls)
        .reduce(
          (acc, [key, childControl]) => {
            const childErrors = this.collectErrorsInNestedObject(childControl);
            if (childErrors) {
              acc = {...acc, [key]: childErrors};
            }
            return acc;
          },
          null,
        );
    } else {
      return control.errors;
    }
  }

  static collectErrorsInArray(control: AbstractControl, stopOnInvalidArray: boolean, listErrors: any[] = []): any[] | null {
    if (isFormGroup(control)) {
      Object.entries(control.controls).forEach((c) => {
        const abstractControl: AbstractControl = c[1];
        this.collectErrorsInArray(abstractControl, stopOnInvalidArray, listErrors);
      });
      return listErrors;
    } else if (isFormArray(control)) {
      Object.entries(control.controls).forEach((c) => {
        const abstractControl: AbstractControl = c[1];
        if (stopOnInvalidArray && control.status === SOLIDIFY_CONSTANTS.FORM_STATUS_INVALID) {
          if (abstractControl.status === SOLIDIFY_CONSTANTS.FORM_STATUS_INVALID) {
            listErrors.push({arrayItemInError: true});
          }
        } else {
          this.collectErrorsInArray(abstractControl, stopOnInvalidArray, listErrors);
        }
      });
    } else {
      if (isNotNullNorUndefined(control.errors) && (control.dirty || control.touched)) {
        listErrors.push(control.errors);
      }
      return listErrors;
    }
  }
}
