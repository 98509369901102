/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - composition.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {BaseResourceType} from "../../../models/dto/base-resource.model";
import {CollectionTyped} from "../../../models/dto/collection-typed.model";
import {QueryParameters} from "../../../models/query-parameters/query-parameters.model";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../models/stores/base.action";
import {CompositionNameSpace} from "./composition-namespace.model";

export namespace CompositionAction {
  export class ChangeQueryParameters extends BaseAction {
    static readonly type: string = "[{0}] Change Query Parameters";

    constructor(public parentId: string, public queryParameters: QueryParameters, public keepCurrentContext: boolean = false, public getAllAfterChange: boolean = true) {
      super();
    }
  }

  export class GetAll extends BaseAction {
    static readonly type: string = "[{0}] Composition : Get All SubResource";

    constructor(public parentId: string, public queryParameters?: QueryParameters, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetAllSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetAll> {
    static readonly type: string = "[{0}] Composition : Get All SubResource Success";

    constructor(public parentAction: GetAll, public list: CollectionTyped<TResource>) {
      super(parentAction);
    }
  }

  export class GetAllFail extends BaseSubActionFail<GetAll> {
    static readonly type: string = "[{0}] Composition : Get All SubResource Fail";
  }

  export class GetById extends BaseAction {
    static readonly type: string = "[{0}] Composition : Get By Id";

    constructor(public parentId: string, public resId: string, public keepCurrentContext: boolean = false) {
      super();
    }
  }

  export class GetByIdSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<GetById> {
    static readonly type: string = "[{0}] Composition : Get By Id Success";

    constructor(public parentAction: GetById, public model: TResource) {
      super(parentAction);
    }
  }

  export class GetByIdFail extends BaseSubActionFail<GetById> {
    static readonly type: string = "[{0}] Composition : Get By Id Fail";
  }

  export class Create<TResource extends BaseResourceType> extends BaseAction {
    static readonly type: string = "[{0}] Composition : Create SubResource";

    constructor(public parentId: string, public model: TResource) {
      super();
    }
  }

  export class CreateSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<Create<TResource>> {
    static readonly type: string = "[{0}] Composition : Create SubResource Success";

    constructor(public parentAction: Create<TResource>, public parentId: string, public model: TResource) {
      super(parentAction);
    }
  }

  export class CreateFail<TResource extends BaseResourceType> extends BaseSubActionFail<Create<TResource>> {
    static readonly type: string = "[{0}] Composition : Create SubResource Fail";
  }

  export class Delete extends BaseAction {
    static readonly type: string = "[{0}] Composition : Delete SubResource";

    constructor(public parentId: string, public resId: string) {
      super();
    }
  }

  export class DeleteSuccess extends BaseSubActionSuccess<Delete> {
    static readonly type: string = "[{0}] Composition : Delete SubResource Success";

    constructor(public parentAction: Delete, public parentId: string) {
      super(parentAction);
    }
  }

  export class DeleteFail extends BaseSubActionFail<Delete> {
    static readonly type: string = "[{0}] Composition : Delete SubResource Fail";
  }

  export class Update<TResource extends BaseResourceType> extends BaseAction {
    static readonly type: string = "[{0}] Composition : Update SubResource";

    constructor(public parentId: string, public model: TResource) {
      super();
    }
  }

  export class UpdateSuccess<TResource extends BaseResourceType> extends BaseSubActionSuccess<Update<TResource>> {
    static readonly type: string = "[{0}] Composition : Update SubResource Success";

    constructor(public parentAction: Update<TResource>, public parentId: string, public model: TResource) {
      super(parentAction);
    }
  }

  export class UpdateFail<TResource extends BaseResourceType> extends BaseSubActionFail<Update<TResource>> {
    static readonly type: string = "[{0}] Composition : Update SubResource Fail";

    constructor(public parentAction: Update<TResource>, public parentId: string) {
      super(parentAction);
    }
  }

  export class Clean extends BaseAction {
    static readonly type: string = "[{0}] Clean";

    constructor(public preserveList?: boolean, public queryParameter?: QueryParameters | undefined) {
      super();
    }
  }
}

export const compositionActionNameSpace: CompositionNameSpace = CompositionAction;
