import { NgModule } from "@angular/core";
import { SolCommonSharedModule } from "../../shared/shared.module";
import { SolNavBackComponent } from "./nav-back.component";

@NgModule({
  imports: [SolCommonSharedModule],
  exports: [SolNavBackComponent],
  declarations: [SolNavBackComponent],
  providers: []
})
export class SolNavBackModule {}
