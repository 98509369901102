import { AcademicalYear } from "../../models/academical-year.model";

export class GetCurrentAcademicYear {
  static readonly type: string = "[App] Get current academic year";
  constructor() {}
}

export class GetCurrentAcademicYearSuccess {
  static readonly type: string = "[App] Get current academic year success";
  constructor(public payload: AcademicalYear) {}
}

export class GetCurrentAcademicYearFail {
  static readonly type: string = "[App] Get current academic year fail";
}
