/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - upload.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {ErrorDto} from "../../../models/errors/error-dto.model";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../models/stores/base.action";
import {FileUploadWrapper} from "../../../models/upload/file-upload-wrapper.model";
import {SolidifyFile} from "../../../models/upload/solidify-file.model";
import {UploadFileStatus} from "../../../models/upload/upload-file-status.model";
import {UploadNameSpace} from "./upload-namespace.model";

export namespace UploadAction {
  export class UploadFile extends BaseAction {
    static readonly type: string = "[{0}] Upload File";

    constructor(public parentId: string, public fileUploadWrapper: FileUploadWrapper, public isArchive: boolean = false) {
      super();
    }
  }

  export class UploadFileSuccess<TResource extends SolidifyFile> extends BaseSubActionSuccess<UploadFile> {
    static readonly type: string = "[{0}] Upload File Success";

    constructor(public parentAction: UploadFile, public parentId: string, public uploadFileStatus: UploadFileStatus<TResource>, public solidifyFile: SolidifyFile) {
      super(parentAction);
    }
  }

  export class UploadFileFail<TResource extends SolidifyFile> extends BaseSubActionFail<UploadFile> {
    static readonly type: string = "[{0}] Upload File Fail";

    constructor(public parentAction: UploadFile, public uploadFileStatus: UploadFileStatus<TResource>, public errorDto: ErrorDto | undefined) {
      super(parentAction);
    }
  }

  export class MarkAsCancelFileSending<TResource extends SolidifyFile> extends BaseAction {
    static readonly type: string = "[{0}] Mark As Cancel File Sending";

    constructor(public parentId: string, public uploadFileStatus: UploadFileStatus<TResource>) {
      super();
    }
  }

  export class CancelFileSending<TResource extends SolidifyFile> extends BaseAction {
    static readonly type: string = "[{0}] Cancel File Sending";

    constructor(public parentId: string, public uploadFileStatus: UploadFileStatus<TResource>) {
      super();
    }
  }

  export class RetrySendFile<TResource extends SolidifyFile> extends BaseAction {
    static readonly type: string = "[{0}] Retry Send File";

    constructor(public parentId: string, public uploadFileStatus: UploadFileStatus<TResource>) {
      super();
    }
  }
}

export const uploadActionNameSpace: UploadNameSpace = UploadAction;
