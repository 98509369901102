import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  InjectionToken,
  Input,
  Output,
  Type,
  ViewChild
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export class SolUploadedFile {
  constructor(
    public name: string,
    public size: number,
    public content: string,
    public contentType: string
  ) {}
}

// Just here to force import in order to avoid the following error when building the project with
// ng-packagr : error TS4023: Exported variable 'SOL_UPLOAD_CONTROL_VALUE_ACCESSOR' has or is using
// name 'InjectionToken' from external module
// "/[..]/common-ng/node_modules/@angular/core/src/di/injection_token" but cannot be named. TODO
// check is there is a better way to handle the SOL_UPLOAD_CONTROL_VALUE_ACCESSOR
// eslint-disable-next-line prefer-const
let inject: InjectionToken<any>;
// eslint-disable-next-line prefer-const
let type: Type<any>;

export const SOL_UPLOAD_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SolUploadComponent),
  multi: true
};

/**
 * This component use a file input in order to let user select a file. Unfortunately this input
 * doesn't have a value attribute and therefore is not compatible with directive like formGroup or
 * ngModel which are mandatory to correctly handle form validation. To overcome this limitation, a
 * hidden input text is used in the template in order to hold the file input model and thus be able
 * to implement the ControlValueAccessor to make this custom component form friendly.
 *
 * To make the component a little bit more sexy than a default file input, a custom button is used
 * and the input file is hidden. Interaction is still possible thanks to a click event on the
 * custom button which triggers the file input click action.
 */
@Component({
  selector: "sol-upload",
  templateUrl: "./upload.component.html",
  styleUrls: ["./upload.component.scss"],
  providers: [SOL_UPLOAD_CONTROL_VALUE_ACCESSOR]
})
export class SolUploadComponent implements ControlValueAccessor {
  uploadedFile: SolUploadedFile;
  @ViewChild("fileInput", { static: true })
  fileInput: ElementRef;
  // Used to fill the <input> accept attribute
  @Input()
  acceptedFormat: string;
  @Output()
  onFileLoaded: EventEmitter<SolUploadedFile> = new EventEmitter<SolUploadedFile>();
  public _fileReader: any;

  @Input()
  isSentInFormData: boolean = false;

  constructor() {
    this._fileReader = new FileReader();
  }

  private _value: any;

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      this._onChange();
    }
  }

  // Callback registered via registerOnTouched (ControlValueAccessor)
  public _onTouched: Function = () => {};

  // Callback registered via registerOnChange (ControlValueAccessor)
  public _onChange: Function = () => {};

  onFileChange(fileInput: any): void {
    const file: File = <File>fileInput.target.files[0];
    if (this.isSentInFormData) {
      this._fileReader.readAsArrayBuffer(file);
      this._fileReader.onloadend = () => {
        const fileContent = this._fileReader.result;
        this.uploadedFile = new SolUploadedFile(file.name, file.size, fileContent, file.type);
        this.value = this.uploadedFile;
        this._onChange();
        this.onFileLoaded.emit(this.uploadedFile);
      };
    } else {
      this._fileReader.readAsDataURL(file);
      this._fileReader.onloadend = () => {
        const base64Content = this._fileReader.result.match(/,(.*)$/)[1];
        this.uploadedFile = new SolUploadedFile(file.name, file.size, base64Content, file.type);
        this.value = this.uploadedFile;
        this._onChange();
        this.onFileLoaded.emit(this.uploadedFile);
      };
    }
  }

  onClick(): void {
    this.fileInput.nativeElement.value = null;
    this._onTouched();
  }

  // Implemented as part of ControlValueAccessor.
  writeValue(value: any): void {
    this._value = value;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
