import { SolSortOrder } from "../../../models/sort-order";
import { Action, NgxsAfterBootstrap, Selector, State, StateContext } from "@ngxs/store";
import { SolPerson } from "../models/person";
import {
  ResetPagination,
  ResetSorting,
  ResetState,
  SearchPersons,
  SearchPersonsFail,
  SearchPersonsSuccess,
  SearchPersonsWhenPaginating,
  SearchPersonsWhenSorting,
  SelectPerson,
  UpdateFormSubmitState,
  UpdatePagination,
  UpdateSorting
} from "./persons.action";
import { SolPersonService } from "../services/person.service";
import { tap } from "rxjs/operators";
import { Injectable, Injector } from "@angular/core";
import { SolApiResponse } from "../../../models/api-response";
import { SolApiRespPageInfo } from "../../../models/api-resp-page-info";
import { SolSort } from "../../../models/sort";
import { StateReset } from "ngxs-reset-plugin";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { PersonSearchForm } from "../models/person-search-form";

export class SolPersonsStateModel {
  persons: SolPerson[];
  selectedPerson: SolPerson;

  sort: SolSort;
  pagination: SolApiRespPageInfo;

  isLoading: boolean;

  form: {
    model: PersonSearchForm;
    dirty: boolean;
    status: string;
    errors: {};
    submitted: boolean;
  };
}

const defaults = {
  persons: [],
  selectedPerson: null,

  sort: {
    field: "",
    order: SolSortOrder.ASC
  },

  pagination: {
    currentPage: 0,
    sizePage: 10,
    totalItems: 0,
    totalPages: 0
  },
  isLoading: false,

  form: {
    model: new PersonSearchForm(),
    dirty: false,
    status: "",
    errors: {},
    submitted: false
  }
};

@State<SolPersonsStateModel>({
  name: "solPersons",
  defaults
})
@Injectable()
export class SolPersonsState implements NgxsAfterBootstrap {
  private _personService: SolPersonService = null;

  constructor(private _injector: Injector) {}

  @Selector()
  static getForm(state: SolPersonsStateModel): any {
    return state.form;
  }

  @Selector()
  static getPersons(state: SolPersonsStateModel): SolPerson[] {
    return state.persons;
  }

  @Selector()
  static getSelectedPerson(state: SolPersonsStateModel): SolPerson {
    return state.selectedPerson;
  }

  @Selector()
  static isLoading(state: SolPersonsStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static isFormSubmitted(state: SolPersonsStateModel): boolean {
    return state.form.submitted;
  }

  @Selector()
  static getPagination(state: SolPersonsStateModel): SolApiRespPageInfo {
    return state.pagination;
  }

  ngxsAfterBootstrap(ctx: StateContext<SolPersonsStateModel>): void {
    this._personService = this._injector.get(SolPersonService);
  }

  @Action(SearchPersons, { cancelUncompleted: true })
  @Action(SearchPersonsWhenPaginating, { cancelUncompleted: true })
  @Action(SearchPersonsWhenSorting, { cancelUncompleted: true })
  searchPersons(
    { getState, setState, patchState, dispatch }: StateContext<SolPersonsStateModel>,
    { payload }: SearchPersons | SearchPersonsWhenPaginating | SearchPersonsWhenSorting
  ): Observable<SolApiResponse<SolPerson>> {
    patchState({ isLoading: true, selectedPerson: null });

    const defaultPagination = { ...defaults.pagination };
    defaultPagination.sizePage = getState().pagination.sizePage;

    if (!payload) {
      payload = {
        pagination: defaultPagination,
        sort: defaults.sort
      };
    }

    return this._personService
      .searchPersons(getState().form.model, payload.pagination, payload.sort)
      .pipe(
        tap(
          (data: SolApiResponse<SolPerson>) => {
            dispatch(new SearchPersonsSuccess(data));
          },
          error => {
            dispatch(new SearchPersonsFail());
            throw error;
          }
        )
      );
  }

  @Action(SearchPersonsSuccess)
  searchPersonsSuccess(
    { setState, getState, patchState, dispatch }: StateContext<SolPersonsStateModel>,
    { payload }: SearchPersonsSuccess
  ): void {
    patchState({
      persons: payload._data,
      selectedPerson: payload._data[0],
      isLoading: false
    });

    dispatch(
      new UpdatePagination({
        ...payload._page,
        sizePage: payload._page.sizePage,
        totalPages: payload._page.totalPages,
        totalItems: payload._page.totalItems
      })
    );

    dispatch(new UpdateSorting(null));
  }

  @Action(SelectPerson)
  selectPerson(
    { getState, setState }: StateContext<SolPersonsStateModel>,
    { payload }: SelectPerson
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedPerson: { ...payload } as any // TODO : SolPerson vs SolPersonForSearch
    });
  }

  @Action(UpdatePagination)
  updatePagination(
    { getState, patchState }: StateContext<SolPersonsStateModel>,
    { payload }: UpdatePagination
  ): void {
    const { pagination } = getState();

    patchState({
      pagination: {
        ...pagination,
        ...defaults.pagination,
        ...payload
      }
    });
  }

  @Action(ResetPagination)
  resetPagination({ getState, patchState }: StateContext<SolPersonsStateModel>): void {
    const { pagination } = getState();

    const defaultPagination = { ...defaults.pagination };
    defaultPagination.sizePage = pagination.sizePage;

    patchState({
      pagination: {
        ...pagination,
        ...defaultPagination
      }
    });
  }

  @Action(ResetSorting)
  resetSorting({ getState, patchState }: StateContext<SolPersonsStateModel>): void {
    const { sort } = getState();
    patchState({
      sort: {
        ...sort,
        ...defaults.pagination
      }
    });
  }

  @Action(UpdateSorting)
  updateSorting(
    { getState, setState }: StateContext<SolPersonsStateModel>,
    { payload }: UpdateSorting
  ): void {
    const state = getState();
    setState({
      ...state,
      sort: { ...payload }
    });
  }

  @Action(UpdateFormSubmitState)
  updateFormSubmitState(
    { patchState, getState, setState }: StateContext<SolPersonsStateModel>,
    { isSubmitted, searchForm }: UpdateFormSubmitState
  ): void {
    const state = _.cloneDeep(getState());
    state.form.submitted = isSubmitted;

    if (searchForm) {
      state.form.model = searchForm;
    }
    setState(state);
  }

  @Action(ResetState)
  resetState({ dispatch }: StateContext<SolPersonsStateModel>): void {
    dispatch(new StateReset(SolPersonsState));
  }
}
