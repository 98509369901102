/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - status-history.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {CollectionTyped} from "../../../models/dto/collection-typed.model";
import {QueryParameters} from "../../../models/query-parameters/query-parameters.model";
import {StatusHistory} from "../../../models/status-history.model";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../models/stores/base.action";
import {StatusHistoryNamespace} from "./status-history-namespace.model";

export namespace StatusHistoryAction {
  export class History extends BaseAction {
    static readonly type: string = `[{0}] History`;

    constructor(public id: string, public parentId?: string, public queryParameters?: QueryParameters) {
      super();
    }
  }

  export class HistorySuccess extends BaseSubActionSuccess<History> {
    static readonly type: string = `[{0}] History Success`;

    constructor(public parentAction: History, public list: CollectionTyped<StatusHistory>) {
      super(parentAction);
    }
  }

  export class HistoryFail extends BaseSubActionFail<History> {
    static readonly type: string = `[{0}] History Fail`;
  }

  export class ChangeQueryParameters extends BaseAction {
    static readonly type: string = "[{0}] Change Query Parameters";

    constructor(public queryParameters: QueryParameters, public id: string, public parentId?: string) {
      super();
    }
  }
}

export const statusHistoryNamespace: StatusHistoryNamespace | any = StatusHistoryAction;
