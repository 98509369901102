import { Inject, Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subscription, timer } from "rxjs";
import { SOL_SESSION_TIMEOUT_OPTIONS, SolSessionTimeoutOptions } from "./session-timeout.constant";
import { isNumberValid } from "solidify-frontend";
import { SolSessionTimeoutDialogComponent } from "./session-timeout-dialog/session-timeout-dialog.component";

@Injectable()
export class SolSessionTimeoutService {
  private _timerSubscription: Subscription;
  private _timer: Observable<number>;
  private _idleTimeSeconds: number;
  public isTimeoutExpiredSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private _dialog: MatDialog,
    @Inject(SOL_SESSION_TIMEOUT_OPTIONS) private _options: SolSessionTimeoutOptions
  ) {
    this._idleTimeSeconds = _options.idleTime;
    this.startTimer();
  }

  private _dialogRef: MatDialogRef<SolSessionTimeoutDialogComponent>;

  public openDialog(): void {
    this._dialogRef = this._dialog.open(SolSessionTimeoutDialogComponent, {
      disableClose: true,
      closeOnNavigation: false
    });
  }

  public startTimer(idleTime?: number): void {
    if (this._timerSubscription) {
      this._timerSubscription.unsubscribe();
    }
    if (isNumberValid(idleTime)) {
      this._idleTimeSeconds = idleTime;
    }

    this._timer = timer(this._idleTimeSeconds * 1000);
    this._timerSubscription = this._timer.subscribe(n => {
      this.openDialog();
      this._timerComplete(n);
    });
  }

  public stopTimer(): void {
    this._timerSubscription.unsubscribe();
  }

  public resetTimer(idleTime?: number): void {
    this.startTimer(idleTime);
  }

  public isTimeoutExpired(): boolean {
    return this.isTimeoutExpiredSubject.getValue();
  }

  private _timerComplete(n: number): void {
    this.isTimeoutExpiredSubject.next(true);
  }
}
