import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UnderConstructionPresentational } from "./under-construction.presentational";

@NgModule({
  imports: [CommonModule],
  declarations: [UnderConstructionPresentational],
  exports: [UnderConstructionPresentational]
})
export class UnderConstructionPresentationalModule {}
