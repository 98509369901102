import * as _gitVersion from "../assets/git-version.json";

/**
 * Tool to retrieve version information about the application
 */
export const versionInfo = (() => {
  const gitVersion = { ..._gitVersion };
  // Add a cacheBustingHash value which can come handy to handle
  // cache busting for static files
  if (gitVersion.lastCommitTime && gitVersion.shortSHA) {
    const epochTime = new Date(gitVersion.lastCommitTime).getTime();
    gitVersion.cacheBustingHash = gitVersion.shortSHA + epochTime;
  }
  return gitVersion;
})();
