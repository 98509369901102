/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - notification.service.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Inject,
  Injectable,
  NgZone,
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {NotificationTypeEnum} from "../enums/notification-type.enum";
import {DefaultSolidifyEnvironment} from "../environments/environment.solidify-defaults";
import {ENVIRONMENT} from "../injection-tokens/environment.injection-token";
import {NotifierService} from "../models/services/notifier-service.model";
import {
  SNACK_BAR,
  SnackbarAction,
  SnackbarData,
  SnackBarModel,
  SnackbarOption,
} from "../models/snackbar/snack-bar.model";
import {isNullOrUndefined} from "../tools/is/is.tool";
import {SolidifyObject} from "../types/solidify-object.type";
import {AbstractBaseService} from "./abstract-base.service";

@Injectable({
  providedIn: "root",
})
export class NotificationService extends AbstractBaseService implements NotifierService {
  private readonly _ONE_SECOND_IN_MILLISECONDS: number = 1000;

  constructor(@Inject(SNACK_BAR) public readonly snackBar: SnackBarModel,
              private readonly _translate: TranslateService,
              private readonly _ngZone: NgZone,
              @Inject(ENVIRONMENT) private readonly _environment: DefaultSolidifyEnvironment) {
    super();
  }

  showInformation(message: string, messageParam: SolidifyObject | undefined = undefined, action: SnackbarAction | undefined = undefined, subMessage: string = undefined, subMessageParam: SolidifyObject | undefined = undefined): void {
    this.show({
      durationInSecond: this._environment.defaultNotificationInformationDurationInSeconds,
      data: this.getDataOption(message, messageParam, NotificationTypeEnum.information, action, subMessage, subMessageParam),
      component: this._environment.defaultNotificationComponent,
    });
  }

  showWarning(message: string, messageParam: SolidifyObject | undefined = undefined, action: SnackbarAction | undefined = undefined, subMessage: string = undefined, subMessageParam: SolidifyObject | undefined = undefined): void {
    this.show({
      durationInSecond: this._environment.defaultNotificationWarningDurationInSeconds,
      data: this.getDataOption(message, messageParam, NotificationTypeEnum.warning, action, subMessage, subMessageParam),
      component: this._environment.defaultNotificationComponent,
    });
  }

  showSuccess(message: string, messageParam: SolidifyObject | undefined = undefined, action: SnackbarAction | undefined = undefined, subMessage: string = undefined, subMessageParam: SolidifyObject | undefined = undefined): void {
    this.show({
      durationInSecond: this._environment.defaultNotificationSuccessDurationInSeconds,
      data: this.getDataOption(message, messageParam, NotificationTypeEnum.success, action, subMessage, subMessageParam),
      component: this._environment.defaultNotificationComponent,
    });
  }

  showError(message: string, messageParam: SolidifyObject | undefined = undefined, action: SnackbarAction | undefined = undefined, subMessage: string = undefined, subMessageParam: SolidifyObject | undefined = undefined, error: any = undefined): void {
    this.show({
      durationInSecond: this._environment.defaultNotificationErrorDurationInSeconds,
      data: this.getDataOption(message, messageParam, NotificationTypeEnum.error, action, subMessage, subMessageParam, error),
      component: this._environment.defaultNotificationComponent,
    });
  }

  private getDataOption(message: string, messageParam: SolidifyObject | undefined, category: NotificationTypeEnum, action: SnackbarAction | undefined, subMessage: string = undefined, subMessageParam: SolidifyObject | undefined = undefined, error: any = undefined): SnackbarData {
    return {
      message: message,
      messageParam: messageParam,
      subMessage: subMessage,
      subMessageParam: subMessageParam,
      category: category,
      action: action,
      error: error,
    };
  }

  show(option: SnackbarOption): void {
    if (isNullOrUndefined(option.component)) {
      let message = option.data.message;
      const messageParam = option.data.messageParam;
      if (isNullOrUndefined(messageParam)) {
        message = this._translate.instant(message);
      } else {
        message = this._translate.instant(message, messageParam);
      }
      this._ngZone.run(() => {
        this.snackBar.open(message, "X", {
          panelClass: [option.data.category, "native"],
          duration: option.durationInSecond * this._ONE_SECOND_IN_MILLISECONDS,
        });
      });
    } else {
      this._ngZone.run(() => {
        this.snackBar.openFromComponent(option.component, {
          data: option.data,
          panelClass: [option.data.category, "component"],
          duration: option.durationInSecond * this._ONE_SECOND_IN_MILLISECONDS,
        });
      });
    }
  }

  dismiss(): void {
    this.snackBar.dismiss();
  }
}
