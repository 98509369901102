import { TutorialPresentationalModule } from "./features/tutorial/components/presentationals/tutorial.presentational.module";
import { APP_INITIALIZER, NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import {
  SolAppConfigService,
  SolBackToTopModule,
  SolHttpInterceptor,
  SolIframeService,
  SolMatPaginatorIntl,
  SolSnackBarModule
} from "common-ng";
import { SharedModule } from "./shared/shared.module";
import { environment } from "../environments/environment";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { AppRoutingModule } from "./app.routing.module";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsRouterPluginModule } from "@ngxs/router-plugin";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsResetPluginModule } from "ngxs-reset-plugin";
import { TeachingsState } from "./shared/stores/teachings/teachings.state";
import { StudyPlansState } from "./shared/stores/study-plans/study-plans.state";
import { SearchState } from "./shared/stores/search/search.state";
import { StudyPlanService } from "./shared/services/study-plan.service";
import { TeachingService } from "./shared/services/teaching.service";
import { StudyPlanNodesState } from "./shared/stores/study-plan-nodes/study-plan-nodes.state";
import { StudyPlanDetailsService } from "./shared/services/study-plan-details.service";
import { AcademicYearState } from "./shared/stores/academic-year/academic-year.state";
import { NavigationContainerModule } from "./features/navigation/components/containers/navigation.container.module";
import { ENVIRONMENT, LABEL_TRANSLATE } from "solidify-frontend";
import { labelTranslateSolidify } from "../../../common-demo/label-translate-solidify";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { from, Observable } from "rxjs";
import { ScheduleState } from "./shared/stores/my-schedule/schedule.state";

const appInitializerFn = (appConfig: SolAppConfigService) => () =>
  appConfig.mergeConfig(environment).toPromise();

class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    SolBackToTopModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    NgxsFormPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
    NgxsModule.forRoot(
      [
        SearchState,
        StudyPlansState,
        TeachingsState,
        StudyPlanNodesState,
        AcademicYearState,
        ScheduleState
      ],
      {
        developmentMode: !environment.production
      }
    ),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    NgxsResetPluginModule.forRoot(),
    SharedModule,
    SolSnackBarModule,
    NavigationContainerModule,
    TutorialPresentationalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    })
  ],
  providers: [
    SolIframeService,
    { provide: HTTP_INTERCEPTORS, useClass: SolHttpInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: environment.dateLocale },
    { provide: MatPaginatorIntl, useClass: SolMatPaginatorIntl },
    SolAppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [SolAppConfigService]
    },
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: LABEL_TRANSLATE,
      useValue: labelTranslateSolidify
    },
    StudyPlanService,
    TeachingService,
    StudyPlanDetailsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
