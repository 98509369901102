/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - global-banner.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {StatePartialEnum} from "../core/enums/partial/state-partial.enum";
import {GlobalBanner} from "../core/models/global-banner.model";
import {
  BaseAction,
  BaseSubAction,
} from "../core/models/stores/base.action";

const state = StatePartialEnum.application_global_banner;

export namespace SolidifyGlobalBannerAction {

  export class GetGlobalBanner extends BaseAction {
    static readonly type: string = `[${state}] Get Global Banner`;

    constructor() {
      super();
    }
  }

  export class GetGlobalBannerSuccess extends BaseSubAction<GetGlobalBanner> {
    static readonly type: string = `[${state}] Get Global Banner Success`;

    constructor(public parent: GetGlobalBanner, public globalBanner: GlobalBanner) {
      super(parent);
    }
  }

  export class GetGlobalBannerFail extends BaseSubAction<GetGlobalBanner> {
    static readonly type: string = `[${state}] Get Global Banner Fail`;

    constructor(public parent: GetGlobalBanner) {
      super(parent);
    }
  }

}

export const appGlobalBannerActionNameSpace = SolidifyGlobalBannerAction;
