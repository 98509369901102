import { MatPaginatorIntl } from "@angular/material/paginator";
import { Injectable } from "@angular/core";

@Injectable()
export class SolMatPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();
    this.nextPageLabel = "Page suivante";
    this.previousPageLabel = "Page précédente";
    this.itemsPerPageLabel = "Résultats par page";
    this.itemsPerPageLabel = "Résultats par page";
    /** A label for the range of items within the current page and the length of the whole list.
     * rewrite the fonction (parent fonction) and change only the label of*/
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 sur ${length}`;
      }

      length = Math.max(length, 0);

      const startIndex = page * pageSize;

      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
  }
}
