/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - solidify-application-app.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {StatePartialEnum} from "../../../../core/enums/partial/state-partial.enum";
import {BackendModuleVersion} from "../../../../core/models/backend-modules/backend-module-version.model";
import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../../core/models/stores/base.action";
import {SolidifyAppAction} from "../../../../core/stores/abstract/app/app.action";
import {MappingObject} from "../../../../core/types/mapping-type.type";

const state = StatePartialEnum.application;

export namespace SolidifyApplicationAppAction {
  export class LoadModuleUrl extends BaseAction {
    static readonly type: string = `[${state}] Load Modules URL From Backend`;
  }

  export class LoadModuleUrlSuccess extends BaseSubActionSuccess<LoadModuleUrl> {
    static readonly type: string = `[${state}] Load Modules URL From Backend Success`;

    constructor(public parentAction: LoadModuleUrl, public modulesUrl: MappingObject<string, string | string[]>) {
      super(parentAction);
    }
  }

  export class LoadModuleUrlFail extends BaseSubActionFail<LoadModuleUrl> {
    static readonly type: string = `[${state}] Load Modules URL From Backend Fail`;
  }

  export class LoadBackendModulesVersion extends BaseAction {
    static readonly type: string = `[${state}] Load Backend Modules Version`;

    constructor(public modulesUrl: MappingObject<string, string | string[]>) {
      super();
    }
  }

  export class LoadBackendModulesVersionSuccess extends BaseSubActionSuccess<LoadBackendModulesVersion> {
    static readonly type: string = `[${state}] Load Backend Modules Version Success`;

    constructor(public parentAction: LoadBackendModulesVersion, public modulesVersion: MappingObject<string, BackendModuleVersion>) {
      super(parentAction);
    }
  }

  export class LoadBackendModulesVersionFail extends BaseSubActionFail<LoadBackendModulesVersion> {
    static readonly type: string = `[${state}] Load Backend Modules Version Fail`;
  }

  export class LoadBackendModuleVersion extends BaseAction {
    static readonly type: string = `[${state}] Load Backend Module Version`;

    constructor(public moduleName: string, public moduleUrl: string) {
      super();
    }
  }

  export class LoadBackendModuleVersionSuccess extends BaseSubActionSuccess<LoadBackendModuleVersion> {
    static readonly type: string = `[${state}] Load Backend Module Version Success`;

    constructor(public parentAction: LoadBackendModuleVersion, public backendModuleVersion: BackendModuleVersion) {
      super(parentAction);
    }
  }

  export class LoadBackendModuleVersionFail extends BaseSubActionFail<LoadBackendModuleVersion> {
    static readonly type: string = `[${state}] Load Backend Module Version Fail`;
  }
}

export const solidifyApplicationAppActionNameSpace: typeof SolidifyAppAction & typeof SolidifyApplicationAppAction = {...SolidifyAppAction, ...SolidifyApplicationAppAction};
// export const solidifyApplicationAppActionNameSpace: SolidifyApplicationAppNameSpace = {...SolidifyAppAction, ...SolidifyApplicationAppAction};
