/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - snack-bar.model.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  InjectionToken,
  Type,
} from "@angular/core";
import {AbstractSnackbarPresentational} from "../../components/presentationals/abstract-snackbar/abstract-snackbar.presentational";
import {NotificationTypeEnum} from "../../enums/notification-type.enum";
import {SolidifyObject} from "../../types/solidify-object.type";

export abstract class SnackBarModel {
  abstract open(message: string, action?: string, config?: any): any;

  abstract openFromComponent(component: any, config?: any): any;

  abstract dismiss(): void;
}

export interface SnackbarOption {
  data: SnackbarData | any;
  component?: Type<AbstractSnackbarPresentational>;
  durationInSecond: number;
}

export interface SnackbarData {
  message: string;
  messageParam: SolidifyObject | undefined;
  subMessage?: string;
  subMessageParam?: SolidifyObject | undefined;
  error?: any;
  category: NotificationTypeEnum;
  action?: SnackbarAction;
}

export interface SnackbarAction {
  text: string;
  callback: () => void;
}

export const SNACK_BAR: InjectionToken<any> = new InjectionToken("snackbar");
