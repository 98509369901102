/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - cache-busting.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {HttpParams} from "@angular/common/http";
import {RandomUtil} from "./random.util";

export class CacheBustingUtil {
  private static readonly _CACHE_BUSTING_LENGTH: number = 16;
  private static readonly _CACHE_BUSTING_QUERY_PARAM_KEY: string = "solidify-cache-bust";
  private static readonly _CACHE_BUSTING_QUERY_PARAM: string = `?${this._CACHE_BUSTING_QUERY_PARAM_KEY}=`;

  static generateCacheBustingQueryParam(): string {
    return this._CACHE_BUSTING_QUERY_PARAM + this.generateCacheBustId();
  }

  static generateCacheBustingQueryParamObjet(): HttpParams | {
    [param: string]: string | number | boolean | (string | number | boolean)[];
  } {
    return {
      [this._CACHE_BUSTING_QUERY_PARAM_KEY]: this.generateCacheBustId(),
    };
  }

  static generateCacheBustId(): string {
    const min = Math.pow(10, this._CACHE_BUSTING_LENGTH - 1);
    const max = Math.pow(10, this._CACHE_BUSTING_LENGTH) - 1;
    return RandomUtil.generateNumber(0, 9) + "." + RandomUtil.generateNumber(min, max);
  }
}
