import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SolApiResponse, SolAppConfigService } from "common-ng";
import { StudyPlanDepartment } from "../models/study-plan-department.model";
import { Observable } from "rxjs";
import { SearchServiceBase } from "./search-service-base";
import { SearchForm } from "../models/search-form.model";

@Injectable({
  providedIn: "root"
})
export class StudyPlanDepartmentService extends SearchServiceBase {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {
    super();
  }

  public getByStructure(
    structureId: string,
    academicalYear: string
  ): Observable<SolApiResponse<StudyPlanDepartment>> {
    const filters = new SearchForm();
    filters.facultyId = structureId;
    filters.academicalYear = academicalYear;

    const httpParams = this.createHttpParams(filters);

    return this._http.get<SolApiResponse<StudyPlanDepartment>>(
      this._appConfig.config.apiRootUrl + "study-plan-departments?size=10000",
      { params: httpParams }
    );
  }
}
