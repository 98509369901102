import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { SolPersonInfo } from "../models/person-info";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SolApiResponse } from "../../../models/api-response";
import { SolAppConfigService } from "../../../config/app-config.service";
import { SolPerson } from "../models/person";
import { SolApiRespPageInfo } from "../../../models/api-resp-page-info";
import { SolSort } from "../../../models/sort";
import { PersonSearchForm } from "../models/person-search-form";
import moment from "moment";

@Injectable({
  providedIn: "root"
})
export class SolPersonService {
  private _apiRootUrl: string = this._appConfig.config.apiRootUrl;

  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {}

  public getPersonDetailsByCnIndividu(id: string): Observable<SolPersonInfo> {
    return this._http.get<SolPersonInfo>(this._apiRootUrl + "persons/" + id);
  }

  public searchPersons(
    filters: any,
    pagination?: SolApiRespPageInfo,
    sorting?: SolSort
  ): Observable<SolApiResponse<SolPerson> | any> {
    let httpParams = this.setSearchParam(filters);

    if (
      pagination &&
      Number.isInteger(pagination.currentPage) &&
      Number.isInteger(pagination.sizePage)
    ) {
      httpParams = httpParams
        .set("page", pagination.currentPage.toString())
        .set("size", pagination.sizePage.toString());
    }

    if (sorting && sorting.field && sorting.order) {
      httpParams = httpParams.set("sort", sorting.field + "," + sorting.order);
    }

    return this._http
      .get<SolApiResponse<SolPerson[]>>(this._apiRootUrl + "persons-search", { params: httpParams })
      .pipe(
        map((data: any) => {
          if (data !== null) {
            const dataCopy = { ...data };
            SolPersonService._addAlternativeLastnamesProperty(dataCopy._data);
            dataCopy._data = SolPersonService._castToPersonsInstance(dataCopy._data);
            return dataCopy;
          }
          return new SolApiResponse([], new SolApiRespPageInfo());
        })
      );
  }

  setSearchParam(personForSearch: PersonSearchForm): HttpParams {
    let httpParams = new HttpParams();
    // eslint-disable-next-line prefer-const
    for (let [key, value] of Object.entries(personForSearch)) {
      if (!value) {
        continue;
      }
      if (key === "birth") {
        const date = value;
        value =
          value instanceof Date ? date.format("YYYY-MM-DD") : moment(value).format("YYYY-MM-DD");
      }
      httpParams = httpParams.set(key, value);
    }
    return httpParams;
  }

  private static _addAlternativeLastnamesProperty(persons: SolPerson[]): SolPerson[] {
    let alternativeLastnames = null;

    for (const person of persons) {
      alternativeLastnames = [
        person.birthName,
        person.previousLastname,
        person.lastnameEdited,
        person.usualLastname
      ];
      person.alternativeLastnames = SolPersonService._concatDistinctNames(
        person.lastname,
        alternativeLastnames
      );
    }
    return persons;
  }

  private static _concatDistinctNames(name: string, alternativeNames: string[]): string {
    const concatenatedNames = [];
    for (const alternativeName of alternativeNames) {
      if (
        !concatenatedNames.includes(alternativeName) &&
        alternativeName !== name &&
        alternativeName !== null
      ) {
        concatenatedNames.push(alternativeName);
      }
    }
    return concatenatedNames.join(" / ");
  }

  private static _castToPersonsInstance(personsArray: SolPerson[]): SolPerson[] {
    const persons: SolPerson[] = [];
    for (const person of personsArray) {
      persons.push(new SolPerson(person));
    }
    return persons;
  }
}
