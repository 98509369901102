/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - auto-focus.directive.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Directive,
  ElementRef,
  Input,
  NgZone,
} from "@angular/core";
import {
  isFunction,
  isNullOrUndefined,
  isTruthy,
} from "../../tools/is/is.tool";

@Directive({
  selector: "[solidifyAutoFocus]",
})
export class AutoFocusDirective {

  // Focus timeout

  private _focusTimeout: any | undefined;

  // Auto focus

  private _autoFocus: boolean | any = false;

  @Input("solidifyAutoFocus")
  set autoFocus(value: boolean | any) {
    this._autoFocus = isTruthy(value);
    this._onAutoFocusChange();
  }

  get autoFocus(): boolean | any {
    return this._autoFocus;
  }

  // Native element

  get nativeElement(): HTMLElement | null {
    return this._elementRef.nativeElement;
  }

  // Constructor

  constructor(private readonly _elementRef: ElementRef,
              private readonly _ngZone: NgZone) {
  }

  // Is auto focusing

  isAutoFocusing(): boolean {
    return !isNullOrUndefined(this._focusTimeout);
  }

  // Focus

  private _focus(): boolean {
    const nativeElement = this.nativeElement;
    if (nativeElement && isFunction(nativeElement.focus)) {
      nativeElement.focus();
    } else {
      return false;
    }
  }

  // Set focus timeout

  private _setFocusTimeout(): number {
    this.clearFocusTimeout();
    this._focusTimeout = this._ngZone.runOutsideAngular(() => setTimeout(() => {
      this._focus();
      this._focusTimeout = undefined;
    }));
    return this._focusTimeout;
  }

  // Clear focus timeout

  clearFocusTimeout(): boolean {
    if (this._focusTimeout) {
      this._ngZone.runOutsideAngular(() => clearTimeout(this._focusTimeout));
      this._focusTimeout = undefined;
      return true;
    }
    return false;
  }

  // Update from auto focus

  private _onAutoFocusChange(autoFocus: boolean = this._autoFocus): void {
    if (autoFocus) {
      if (!this.isAutoFocusing()) {
        this._setFocusTimeout();
      }
    } else {
      this.clearFocusTimeout();
    }
  }

}
