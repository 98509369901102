import { HttpErrorResponse } from "@angular/common/http";

/**
 * Util used to instantiate a material snack bar component to display error message
 **/
export class ErrorMessageUtil {
  constructor() {}

  public static getErrorMessage(error: HttpErrorResponse): string {
    const helpInfo = `
      <br><br>
      Pour de l'aide,
      <a class="sol-text-link" href="http://cad.unige.ch" target="_blank" rel="noopener">
      contactez le CAD</a>`;
    const errorMessage = this._extractErrorMessage(error);

    switch (error.error?.errorCode) {
      case "GROOT_0001":
        return "Trop de résultats, veuillez affiner votre recherche.";
      case "GROOT_0002":
        return this._defaultErrorTemplate(error, errorMessage, helpInfo);
    }

    if (error.status > 499 && error.status < 600) {
      return this._defaultErrorTemplate(error, "", helpInfo);
    }

    switch (error.status) {
      case 0:
      case 401:
        return "Votre session a expiré. Veuillez recharger votre page.";
      case 412:
        return this._optimisticLockErrorTemplate(error);
      case 403:
        return `
          Votre accès n'est pas autorisé
          ${helpInfo}`;
      default:
        return this._defaultErrorTemplate(error, errorMessage, helpInfo);
    }
  }

  private static _extractErrorMessage(error: HttpErrorResponse): string {
    return error.error?.message ? `<br><br>${error.error.message}` : "";
  }

  private static _defaultErrorTemplate(
    error: HttpErrorResponse,
    errorMessage: string,
    helpInfo: string
  ): string {
    return `
      Une erreur imprévue est survenue.</br></br>
      ${error.status} ${error.statusText}${errorMessage ? `${errorMessage}` : ""}
      ${helpInfo}`;
  }

  private static _optimisticLockErrorTemplate(error: HttpErrorResponse): string {
    if (error.error?.optimisticLock) {
      return `
        La donnée que vous essayez de modifier a été modifiée ou supprimée par une autre personne.
        Veuillez réessayer`;
    } else {
      return `
        La donnée que vous essayez de modifier est utilisée par une application tierce.
        Veuillez réessayer plus tard.`;
    }
  }
}
