import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocationStrategy } from "@angular/common";

@Component({
  selector: "sol-session-timeout-dialog-component",
  templateUrl: "./session-timeout-dialog.component.html",
  styleUrls: ["./session-timeout-dialog.component.scss"]
})
export class SolSessionTimeoutDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _locationStrategy: LocationStrategy
  ) {}

  ngOnInit(): void {
    this._preventBackButton();
  }

  reloadPage(): void {
    window.location.reload();
  }

  private _preventBackButton(): void {
    history.pushState(null, null, location.href);
    this._locationStrategy.onPopState(() => {
      history.pushState(null, null, location.href);
    });
  }
}
