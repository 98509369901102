import { ResourceAction, ResourceNameSpace, TypeDefaultAction } from "solidify-frontend";
import { StateEnum } from "../../../models/enum/state-enum";
import { SolLoginAsProfileModel } from "../../login-as/model/login-as-profile/login-as-profile.model";

const state = StateEnum.loginAsProfile;

export namespace LoginAsProfileAction {
  export class GetLoginAsProfileList {
    static readonly type: string = "[LoginAsProfile] Get LoginAsProfile List";
  }

  export class GetLoginAsProfileListSuccess {
    static readonly type: string = "[LoginAsProfile] Get LoginAsProfile list Success";

    constructor(public payload: SolLoginAsProfileModel[]) {}
  }

  export class GetLoginAsProfileListFail {
    static readonly type: string = "[LoginAsProfile] Get LoginAsProfile list Fail";
  }

  @TypeDefaultAction(state)
  export class LoadResource extends ResourceAction.LoadResource {}

  @TypeDefaultAction(state)
  export class LoadResourceSuccess extends ResourceAction.LoadResourceSuccess {}

  @TypeDefaultAction(state)
  export class LoadResourceFail extends ResourceAction.LoadResourceFail {}

  @TypeDefaultAction(state)
  export class ChangeQueryParameters extends ResourceAction.ChangeQueryParameters {}

  @TypeDefaultAction(state)
  export class GetAll extends ResourceAction.GetAll {}

  @TypeDefaultAction(state)
  export class GetAllSuccess extends ResourceAction.GetAllSuccess<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class GetAllFail extends ResourceAction.GetAllFail<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class GetByListId extends ResourceAction.GetByListId {}

  @TypeDefaultAction(state)
  export class GetByListIdSuccess extends ResourceAction.GetByListIdSuccess {}

  @TypeDefaultAction(state)
  export class GetByListIdFail extends ResourceAction.GetByListIdFail {}

  @TypeDefaultAction(state)
  export class GetById extends ResourceAction.GetById {}

  @TypeDefaultAction(state)
  export class GetByIdSuccess extends ResourceAction.GetByIdSuccess<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class GetByIdFail extends ResourceAction.GetByIdFail<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class Create extends ResourceAction.Create<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class CreateSuccess extends ResourceAction.CreateSuccess<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class CreateFail extends ResourceAction.CreateFail<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class Update extends ResourceAction.Update<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class UpdateSuccess extends ResourceAction.UpdateSuccess<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class UpdateFail extends ResourceAction.UpdateFail<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class Delete extends ResourceAction.Delete {}

  @TypeDefaultAction(state)
  export class DeleteSuccess extends ResourceAction.DeleteSuccess {}

  @TypeDefaultAction(state)
  export class DeleteFail extends ResourceAction.DeleteFail {}

  @TypeDefaultAction(state)
  export class DeleteList extends ResourceAction.DeleteList {}

  @TypeDefaultAction(state)
  export class DeleteListSuccess extends ResourceAction.DeleteListSuccess {}

  @TypeDefaultAction(state)
  export class DeleteListFail extends ResourceAction.DeleteListFail {}

  @TypeDefaultAction(state)
  export class AddInList extends ResourceAction.AddInList<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class AddInListById extends ResourceAction.AddInListById {}

  @TypeDefaultAction(state)
  export class AddInListByIdSuccess extends ResourceAction.AddInListByIdSuccess<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class AddInListByIdFail extends ResourceAction.AddInListByIdFail<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class RemoveInListById extends ResourceAction.RemoveInListById {}

  @TypeDefaultAction(state)
  export class RemoveInListByListId extends ResourceAction.RemoveInListByListId {}

  @TypeDefaultAction(state)
  export class Clean extends ResourceAction.Clean {}

  @TypeDefaultAction(state)
  export class LoadNextChunkList extends ResourceAction.LoadNextChunkList {}

  @TypeDefaultAction(state)
  export class LoadNextChunkListSuccess extends ResourceAction.LoadNextChunkListSuccess<SolLoginAsProfileModel> {}

  @TypeDefaultAction(state)
  export class LoadNextChunkListFail extends ResourceAction.LoadNextChunkListFail {}
}

export const loginAsProfileActionNameSpace: ResourceNameSpace = LoginAsProfileAction;
