import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SolLogoutComponent } from "./logout.component";
import { SolLogoutRoutingModule } from "./logout-routing.module";
import { SolMessageModule } from "../../../directives/message/message.module";

@NgModule({
  imports: [CommonModule, SolLogoutRoutingModule, SolMessageModule],
  exports: [SolLogoutComponent],
  declarations: [SolLogoutComponent]
})
export class SolLogoutModule {}
