/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - resource-action.helper.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {BaseResourceType} from "../../../models/dto/base-resource.model";
import {ResourceNameSpace} from "./resource-namespace.model";
import {ResourceAction} from "./resource.action";

export class ResourceActionHelper {
  // static loadResource(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.LoadResource>): ResourceAction.LoadResource {
  //   return new resourceNameSpace.LoadResource(...args);
  // }

  static loadResource(resourceNameSpace: ResourceNameSpace): ResourceAction.LoadResource {
    return new resourceNameSpace.LoadResource();
  }

  static loadResourceSuccess(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.LoadResourceSuccess>): ResourceAction.LoadResourceSuccess {
    return new resourceNameSpace.LoadResourceSuccess(...args);
  }

  static loadResourceFail(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.LoadResourceFail>): ResourceAction.LoadResourceFail {
    return new resourceNameSpace.LoadResourceFail(...args);
  }

  static changeQueryParameters(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.ChangeQueryParameters>): ResourceAction.ChangeQueryParameters {
    return new resourceNameSpace.ChangeQueryParameters(...args);
  }

  static getAll(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetAll>): ResourceAction.GetAll {
    return new resourceNameSpace.GetAll(...args);
  }

  static getAllSuccess<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetAllSuccess>): ResourceAction.GetAllSuccess<TResource> {
    return new resourceNameSpace.GetAllSuccess(...args);
  }

  static getAllFail<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetAllFail>): ResourceAction.GetAllFail<TResource> {
    return new resourceNameSpace.GetAllFail(...args);
  }

  static getByListId(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetByListId>): ResourceAction.GetByListId {
    return new resourceNameSpace.GetByListId(...args);
  }

  static getByListIdSuccess<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetByListIdSuccess>): ResourceAction.GetByListIdSuccess {
    return new resourceNameSpace.GetByListIdSuccess(...args);
  }

  static getByListIdFail<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetByListIdFail>): ResourceAction.GetByListIdFail {
    return new resourceNameSpace.GetByListIdFail(...args);
  }

  static getById(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetById>): ResourceAction.GetById {
    return new resourceNameSpace.GetById(...args);
  }

  static getByIdSuccess<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetByIdSuccess>): ResourceAction.GetByIdSuccess<TResource> {
    return new resourceNameSpace.GetByIdSuccess(...args);
  }

  static getByIdFail<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.GetByIdFail>): ResourceAction.GetByIdFail<TResource> {
    return new resourceNameSpace.GetByIdFail(...args);
  }

  static create<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.Create>): ResourceAction.Create<TResource> {
    return new resourceNameSpace.Create(...args);
  }

  static createSuccess<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.CreateSuccess>): ResourceAction.CreateSuccess<TResource> {
    return new resourceNameSpace.CreateSuccess(...args);
  }

  static createFail<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.CreateFail>): ResourceAction.CreateFail<TResource> {
    return new resourceNameSpace.CreateFail(...args);
  }

  static update<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.Update>): ResourceAction.Update<TResource> {
    return new resourceNameSpace.Update(...args);
  }

  static updateSuccess<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.UpdateSuccess>): ResourceAction.UpdateSuccess<TResource> {
    return new resourceNameSpace.UpdateSuccess(...args);
  }

  static updateFail<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.UpdateFail>): ResourceAction.UpdateFail<TResource> {
    return new resourceNameSpace.UpdateFail(...args);
  }

  static delete(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.Delete>): ResourceAction.Delete {
    return new resourceNameSpace.Delete(...args);
  }

  static deleteSuccess(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.DeleteSuccess>): ResourceAction.DeleteSuccess {
    return new resourceNameSpace.DeleteSuccess(...args);
  }

  static deleteFail(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.DeleteFail>): ResourceAction.DeleteFail {
    return new resourceNameSpace.DeleteFail(...args);
  }

  static deleteList(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.DeleteList>): ResourceAction.DeleteList {
    return new resourceNameSpace.DeleteList(...args);
  }

  static deleteListSuccess(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.DeleteListSuccess>): ResourceAction.DeleteListSuccess {
    return new resourceNameSpace.DeleteListSuccess(...args);
  }

  static deleteListFail(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.DeleteListFail>): ResourceAction.DeleteListFail {
    return new resourceNameSpace.DeleteListFail(...args);
  }

  static addInList<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.AddInList>): ResourceAction.AddInList<TResource> {
    return new resourceNameSpace.AddInList(...args);
  }

  static addInListById(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.AddInListById>): ResourceAction.AddInListById {
    return new resourceNameSpace.AddInListById(...args);
  }

  static addInListByIdSuccess<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.AddInListByIdSuccess>): ResourceAction.AddInListByIdSuccess<TResource> {
    return new resourceNameSpace.AddInListByIdSuccess(...args);
  }

  static addInListByIdFail<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.AddInListByIdFail>): ResourceAction.AddInListByIdFail<TResource> {
    return new resourceNameSpace.AddInListByIdFail(...args);
  }

  static removeInListById(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.RemoveInListById>): ResourceAction.RemoveInListById {
    return new resourceNameSpace.RemoveInListById(...args);
  }

  static removeInListByListId(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.RemoveInListByListId>): ResourceAction.RemoveInListByListId {
    return new resourceNameSpace.RemoveInListByListId(...args);
  }

  static loadNextChunkList(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.LoadNextChunkList>): ResourceAction.LoadNextChunkList {
    return new resourceNameSpace.LoadNextChunkList(...args);
  }

  static loadNextChunkListSuccess<TResource extends BaseResourceType>(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.LoadNextChunkListSuccess>): ResourceAction.LoadNextChunkListSuccess<TResource> {
    return new resourceNameSpace.LoadNextChunkListSuccess(...args);
  }

  static loadNextChunkListFail(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.LoadNextChunkListFail>): ResourceAction.LoadNextChunkListFail {
    return new resourceNameSpace.LoadNextChunkListFail(...args);
  }

  static clean(resourceNameSpace: ResourceNameSpace, ...args: ConstructorParameters<typeof ResourceAction.Clean>): ResourceAction.Clean {
    return new resourceNameSpace.Clean(...args);
  }
}
