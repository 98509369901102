/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - ssr.util.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

export class SsrUtil {
  static readonly window: SolidifyWindow = typeof window === "undefined" ? undefined : window;
  /**
   * To use with renderer to change DOM (not work with window.document)
   */
  static readonly document: Document = undefined;

  static readonly WINDOW_PROPERTY_KEY: string = "window";
  static readonly DOCUMENT_PROPERTY_KEY: string = "document";
  static readonly PORT_PROPERTY_KEY: string = "port";
  static readonly SSR_PROPERTY_KEY: string = "ssr";
  static readonly COOKIE_SSR_PROPERTY_KEY: string = "cookieSsr";
  static readonly IS_SSR_FIRST_CALL_PROPERTY_KEY: string = "is-ssr-first-call";

  static get isBrowser(): boolean {
    return !SsrUtil.window?.[SsrUtil.SSR_PROPERTY_KEY];
  }

  static get isServer(): boolean {
    return !SsrUtil.isBrowser;
  }

  static get port(): number {
    return SsrUtil.window?.[SsrUtil.PORT_PROPERTY_KEY];
  }

  static get isSsrFirstCall(): boolean {
    return !!SsrUtil.window?.[SsrUtil.IS_SSR_FIRST_CALL_PROPERTY_KEY];
  }
}

type SolidifyWindow = typeof window & {
  clipboardData?: DataTransfer | null;
  encodeURIComponent: (val: string) => string;
  decodeURIComponent: (val: string) => string;
};
