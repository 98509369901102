import { SolPersonForSearch } from "../models/person-for-search";
import { SolSort } from "../../../models/sort";
import { SolApiRespPageInfo } from "../../../models/api-resp-page-info";
import { SolApiResponse } from "../../../models/api-response";
import { SolPerson } from "../models/person";
import { PersonSearchForm } from "../models/person-search-form";

export class SelectPerson {
  static readonly type: string = "[Persons] Select person";

  constructor(public payload: SolPersonForSearch) {}
}

export class SearchPersons {
  static readonly type: string = "[Persons] Search persons";

  constructor(public payload?: SearchPersonsPayload | undefined) {}
}

export interface SearchPersonsPayload {
  sort?: SolSort;
  pagination?: SolApiRespPageInfo;
}

export class SearchPersonsSuccess {
  static readonly type: string = "[Persons] Search persons success";

  constructor(public payload: SolApiResponse<SolPerson>) {}
}

export class SearchPersonsFail {
  static readonly type: string = "[Persons] Search persons fail";
}

export class SearchPersonsWhenSorting {
  static readonly type: string = "[Persons] Search persons when sorting";

  constructor(public payload: SearchPersonsWhenSortingPayload) {}
}

export interface SearchPersonsWhenSortingPayload {
  sort?: SolSort;
  pagination?: SolApiRespPageInfo;
}

export class SearchPersonsWhenPaginating {
  static readonly type: string = "[Persons] Search persons when paginating";

  constructor(public payload: SearchPersonsWhenPaginatingPayload) {}
}

export interface SearchPersonsWhenPaginatingPayload {
  sort?: SolSort;
  pagination?: SolApiRespPageInfo;
}

export class UpdatePagination {
  static readonly type: string = "[Persons] Update pagination";

  constructor(public payload: SolApiRespPageInfo) {}
}

export class ResetPagination {
  static readonly type: string = "[Persons] Reset pagination";
}

export class UpdateSorting {
  static readonly type: string = "[Persons] Update sorting";

  constructor(public payload: SolSort) {}
}

export class ResetSorting {
  static readonly type: string = "[Persons] Reset sorting";
}

export class UpdateFormSubmitState {
  static readonly type: string = "[Persons] Update form submit state";
  constructor(public isSubmitted: boolean, public searchForm: PersonSearchForm) {}
}

export class ResetState {
  static readonly type: string = "[Persons] Reset state";
}
