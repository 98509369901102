/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - base.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {ActionCompletion} from "@ngxs/store";
import {Observable} from "rxjs";

export abstract class BaseAction {
  parentAction?: BaseAction | undefined;

  withParentAction(parentAction: BaseAction | undefined): this {
    this.parentAction = parentAction;
    return this;
  }
}

export abstract class BaseSubAction<TParent extends BaseAction> extends BaseAction {
  parentAction?: TParent | undefined;

  constructor(parentAction: TParent) {
    super();
    this.parentAction = parentAction;
  }
}

export abstract class BaseSubActionSuccess<TParent extends BaseAction> extends BaseSubAction<TParent> {
}

export abstract class BaseSubActionFail<TParent extends BaseAction> extends BaseSubAction<TParent> {
}

export type BaseSubActionAll<TParent extends BaseAction> = BaseSubAction<TParent> | BaseSubActionSuccess<TParent> | BaseSubActionFail<TParent>;

export interface ActionSubActionCompletionsWrapper<T extends BaseAction = BaseAction> {
  action: T;
  subActionCompletions?: Observable<ActionCompletion<BaseSubActionAll<T>, Error>>[] | undefined;
}

export interface MultiActionsCompletionsResult<T extends BaseAction = BaseAction> {
  listActionFail: BaseSubAction<BaseAction>[];
  listActionSuccess: BaseSubAction<BaseAction>[];
  listActionGoalUndefined: BaseSubAction<BaseAction>[];
  listActionWithoutSubAction: BaseSubAction<BaseAction>[];
  listActionInError: BaseSubAction<BaseAction>[];
  error: Error;
  success: boolean;
  rawResult: ActionCompletion<BaseSubAction<BaseAction>, Error>[];
}
