/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - tooltip-on-ellipsis.directive.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Directive,
  ElementRef,
  HostListener,
} from "@angular/core";
import {
  isNotNullNorUndefined,
  isNullOrUndefined,
} from "../../tools/is/is.tool";
import {CoreAbstractDirective} from "../core-abstract/core-abstract.directive";

@Directive({
  selector: "[solidifyTooltipOnEllipsis]",
})
export class TooltipOnEllipsisDirective extends CoreAbstractDirective {
  private readonly _ELEMENT_TITLE_KEY: string = "title";
  private readonly _TAG_MAT_FORM_FIELD: string = "mat-form-field";
  private readonly _TAG_INPUT: string = "input";
  private readonly _TAG_MAT_SELECT: string = "mat-select";
  private readonly _TAG_MAT_OPTION: string = "mat-option";

  constructor(private readonly elementRef: ElementRef) {
    super();
  }

  @HostListener("mouseenter")
  onMouseEnter(): void {
    setTimeout(() => {
      let element;
      let valueTitle;
      const currentElement = this.elementRef.nativeElement;

      const isMatFormField = currentElement.tagName?.toLowerCase() === this._TAG_MAT_FORM_FIELD;
      if (isMatFormField) {
        element = currentElement.querySelector(this._TAG_MAT_SELECT);
        const isMatSelect = isNotNullNorUndefined(element);
        if (isMatSelect) {
          valueTitle = element.textContent;
        } else {
          const list = currentElement.querySelectorAll(this._TAG_INPUT);
          if (list.length > 0) {
            element = list[list.length - 1];
            valueTitle = element.value;
          }
        }
      } else {
        element = currentElement;
        valueTitle = element.textContent;
      }

      if (isNotNullNorUndefined(element)) {
        this._manageTitleAttribute(element, valueTitle);
      }
    }, 100);
  }

  private _manageTitleAttribute(element: any, valueTitle: string): void {
    let targetElement = element;
    if (element.disabled) {
      targetElement = this._getParentElementIfExist(element);
    } else {
      const tagName = element?.tagName?.toLowerCase();

      const isInput = tagName === this._TAG_INPUT;
      const isMatSelect = tagName === this._TAG_MAT_SELECT;
      const isMatOption = tagName === this._TAG_MAT_OPTION;

      if (isInput) {
        this._getParentElementIfExist(element).removeAttribute(this._ELEMENT_TITLE_KEY);
      } else if (isMatSelect) {
        element = element.querySelector(".mat-select-value");
      } else if (isMatOption) {
        element = this._getFirstChildElementIfExist(element);
        if (isNullOrUndefined(element)) {
          return;
        }
        targetElement = element;
      }
    }
    if (element.offsetWidth < element.scrollWidth) {
      targetElement[this._ELEMENT_TITLE_KEY] = valueTitle;
    } else if (targetElement[this._ELEMENT_TITLE_KEY]) {
      targetElement.removeAttribute(this._ELEMENT_TITLE_KEY);
    }
  }

  private _getParentElementIfExist(element: HTMLElement): HTMLElement {
    let targetElement = element?.parentElement;
    if (isNullOrUndefined(targetElement)) {
      targetElement = element;
    }
    return targetElement;
  }

  private _getFirstChildElementIfExist(element: HTMLElement): HTMLElement {
    if (isNullOrUndefined(element) || element?.children.length === 0) {
      return element;
    }
    return element.children.item(0) as HTMLElement;
  }
}
