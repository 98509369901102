// TODO integrate this functions in solidify-frontend library
export class StringUtil {
  static isInteger(value: string): boolean {
    return /^\d+$/.test(value);
  }

  static nullToEmptyString(value: string): string | null {
    return value === null ? "" : value;
  }
}
