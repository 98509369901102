import { propertyName } from "../../../decorators/property-name.decorator";
import { Moment } from "moment";

export class PersonSearchForm {
  constructor(
    public lastname?: string,
    public firstname?: string,
    public shortNameForSearch?: string,
    public birth?: Moment,
    public entityId?: string,
    public studentNumber?: string,
    public populations?: string,
    public gender?: string,
    public emailForSearch?: string
  ) {}
}

export class PersonSearcFormhDefinition {
  @propertyName() lastname: string;
  @propertyName() firstname: string;
  @propertyName() shortNameForSearch: string;
  @propertyName() birth: string;
  @propertyName() entityId: string;
  @propertyName() studentNumber: string;
  @propertyName() populations: string;
  @propertyName() gender: string;
  @propertyName() emailForSearch: string;
}
