/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - store.tool.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {Type} from "@angular/core";
import {
  ActionCompletion,
  ofAction,
  ofActionCanceled,
  ofActionCompleted,
  ofActionDispatched,
  ofActionErrored,
  ofActionSuccessful,
} from "@ngxs/store";
import {ActionContext} from "@ngxs/store/src/actions-stream";
import {ActionType} from "@ngxs/store/src/actions/symbols";
import {Observable} from "rxjs";
import {
  BaseAction,
  BaseSubActionAll,
} from "../../models/stores/base.action";

export const ofSolidifyAction = <T extends BaseSubActionAll<U>, U extends BaseAction>(...allowedTypes: Type<T>[]): (o: Observable<ActionContext<T>>) => Observable<T> => ofAction(...allowedTypes as ActionType[]);

export const ofSolidifyActionDispatched = <T extends BaseSubActionAll<U>, U extends BaseAction>(...allowedTypes: Type<T>[]): (o: Observable<ActionContext<T>>) => Observable<T> => ofActionDispatched(...allowedTypes as ActionType[]);

export const ofSolidifyActionSuccessful = <T extends BaseSubActionAll<U>, U extends BaseAction>(...allowedTypes: Type<T>[]): (o: Observable<ActionContext<T>>) => Observable<T> => ofActionSuccessful(...allowedTypes as ActionType[]);

export const ofSolidifyActionCanceled = <T extends BaseSubActionAll<U>, U extends BaseAction>(...allowedTypes: Type<T>[]): (o: Observable<ActionContext<T>>) => Observable<T> => ofActionCanceled(...allowedTypes as ActionType[]);

export const ofSolidifyActionCompleted = <T extends BaseSubActionAll<U>, U extends BaseAction>(...allowedTypes: Type<T>[]): (o: Observable<ActionContext<T>>) => Observable<ActionCompletion<T, Error>> => ofActionCompleted(...allowedTypes as ActionType[]);

export const ofSolidifyActionErrored = <T extends BaseSubActionAll<U>, U extends BaseAction>(...allowedTypes: Type<T>[]): (o: Observable<ActionContext<T>>) => Observable<T> => ofActionErrored(...allowedTypes as ActionType[]);
