import { NgModule } from "@angular/core";
import { SolStopClickPropagationDirective } from "./stop-click-propagation.directive";
import { SolCommonSharedModule } from "../../shared/shared.module";

@NgModule({
  imports: [SolCommonSharedModule],
  exports: [SolStopClickPropagationDirective],
  declarations: [SolStopClickPropagationDirective],
  providers: []
})
export class SolStopClickPropagationModule {}
