import {
  Component,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2
} from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "sol-message-title, [sol-message-content]"
})
export class SolMessageTitleDirective {
  @HostBinding("class.sol-message-title")
  solMessageTitle: boolean = true;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "sol-message-content, [sol-message-content]"
})
export class SolMessageContentDirective {
  @HostBinding("class.sol-message-content")
  solMessageContent: boolean = true;
}

/**
 * usage
 * =====
 * view demo
 *
 */
@Component({
  selector: "sol-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"]
})
export class SolMessageComponent implements OnInit {
  @Input()
  type: string = "info";
  @Input()
  contrast: string = "normal";

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) {}

  ngOnInit(): void {
    this.setHostElementClass(this.type);
    this.setHostElementClass(this.contrast);
  }

  public getIconName(): string {
    switch (this.type) {
      case "warning":
        return "warning";
      case "success":
        return "done";
      case "error":
        return "error";
      default:
        return "info";
    }
  }

  public setHostElementClass(className: string): void {
    if (className) {
      this._renderer.addClass(this._elementRef.nativeElement, className);
    }
  }
}
