import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ArchivePresentational } from "./archive.presentational";

@NgModule({
  imports: [CommonModule],
  declarations: [ArchivePresentational],
  exports: [ArchivePresentational]
})
export class ArchivePresentationalModule {}
