import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SolMaterialModule } from "../../../shared/material.module";
import {
  SolLayoutComponent,
  SolLayoutContentComponent,
  SolLayoutHeaderComponent,
  SolLayoutHeaderLinksComponent
} from "./layout.component";
import { SolRibbonModule } from "../ribbon/ribbon.module";
import { SolSearchModule } from "../../../directives/search/search.module";
import { SolContainerModule } from "../container/container.module";
import { SolSSOLoginService } from "../../../user/services/sso-login.service";
import { SolLogoutModule } from "../logout/logout.module";
import { WidgetModule } from "../../../widgets/widget.module";

@NgModule({
  imports: [
    CommonModule,
    SolSearchModule,
    SolMaterialModule,
    SolRibbonModule,
    SolContainerModule,
    SolLogoutModule,
    WidgetModule
  ],
  declarations: [
    SolLayoutComponent,
    SolLayoutHeaderComponent,
    SolLayoutContentComponent,
    SolLayoutHeaderLinksComponent
  ],
  exports: [
    SolLayoutComponent,
    SolLayoutHeaderComponent,
    SolLayoutContentComponent,
    SolLayoutHeaderLinksComponent,
    SolContainerModule
  ],
  providers: [SolSSOLoginService]
})
export class SolLayoutModule {}
