import { SolSSOLoginService, SolSSOUser } from "common-ng";
import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  private _userSubject: ReplaySubject<SolSSOUser> = new ReplaySubject<SolSSOUser>(1);

  constructor(private _loginService: SolSSOLoginService) {
    this._loginService.user.subscribe(this._userSubject);
  }

  get user$(): Observable<SolSSOUser> {
    return this._userSubject.asObservable();
  }
}
