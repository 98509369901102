/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - app-user.state.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {HttpClient} from "@angular/common/http";
import {ApmService} from "@elastic/apm-rum-angular";
import {
  Action,
  Actions,
  StateContext,
  Store,
} from "@ngxs/store";
import {Observable} from "rxjs";
import {
  catchError,
  tap,
} from "rxjs/operators";
import {SOLIDIFY_CONSTANTS} from "../../../../core/constants";
import {DefaultSolidifyEnvironment} from "../../../../core/environments/environment.solidify-defaults";
import {SolidifyStateError} from "../../../../core/errors/solidify-state.error";
import {ApiService} from "../../../../core/http/api.service";
import {CollectionTyped} from "../../../../core/models/dto/collection-typed.model";
import {SolidifyHttpErrorResponseModel} from "../../../../core/models/errors/solidify-http-error-response.model";
import {NotificationService} from "../../../../core/services/notification.service";
import {ResourceState} from "../../../../core/stores/abstract/resource/resource.state";
import {isNotNullNorUndefined} from "../../../../core/tools/is/is.tool";
import {User} from "../../../models/dto/user.model";
import {SolidifyAppUserOptions} from "./app-user-options.model";
import {SolidifyAppUserStateModel} from "./app-user-state.model";
import {SolidifyAppUserAction} from "./app-user.action";

// @dynamic
export abstract class SolidifyAppUserState<TStateModel extends SolidifyAppUserStateModel, TUser extends User = User> extends ResourceState<TStateModel, TUser> {
  protected override readonly _optionsState: SolidifyAppUserOptions;

  constructor(protected readonly _apiService: ApiService,
              protected readonly _store: Store,
              protected readonly _notificationService: NotificationService,
              protected readonly _actions$: Actions,
              protected readonly _httpClient: HttpClient,
              protected readonly _options: SolidifyAppUserOptions,
              protected readonly _environment: DefaultSolidifyEnvironment,
              protected readonly _apmService: ApmService) {
    super(_apiService, _store, _notificationService, _actions$, _options);
    // _environment.appUserNameSpace = _options.nameSpace as SolidifyAppUserNameSpace;
  }

  @Action(SolidifyAppUserAction.GetCurrentUser)
  getCurrentUser(ctx: StateContext<SolidifyAppUserStateModel>, action: SolidifyAppUserAction.GetCurrentUser): Observable<CollectionTyped<TUser>> {
    return this._apiService.getCollection<TUser>(this._urlResource + SOLIDIFY_CONSTANTS.URL_SEPARATOR + this._optionsState.apiActionEnumAuthenticated, null)
      .pipe(
        tap((user: TUser) => {
          ctx.dispatch(new SolidifyAppUserAction.GetCurrentUserSuccess(action, user));
        }),
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(new SolidifyAppUserAction.GetCurrentUserFail(action));
          throw new SolidifyStateError(this, error);
        }),
      );
  }

  @Action(SolidifyAppUserAction.GetCurrentUserSuccess)
  getCurrentUserSuccess(ctx: StateContext<SolidifyAppUserStateModel>, action: SolidifyAppUserAction.GetCurrentUserSuccess): void {
    ctx.patchState({
      current: action.user,
    });
    const apm = this._apmService.apm;
    if (isNotNullNorUndefined(apm)) {
      apm.setUserContext({
        id: action.user.resId,
        email: action.user.email,
        username: action.user.firstName + " " + action.user.lastName,
      });
    }
  }
}
