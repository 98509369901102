export class ModelUtil {
  /**
   * Convert a plain javascript object into an instance of a given class
   * @param cls the class from which a new instance will be created
   * @param object a plain javascript object or an array of plain javascript object
   * @returns an instance of the given class or an array of instances.
   */
  public static create<T>(cls: new () => T, object: any): T | T[] {
    if (object instanceof Array) {
      const array: T[] = [];
      for (const obj of <any[]>object) {
        let newObject: T = new cls();
        newObject = addProperties(newObject, obj);
        array.push(newObject);
      }
      return array;
    } else {
      return addProperties(new cls(), object);
    }

    function addProperties(newObject: any, obj: any): any {
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          newObject[prop] = obj[prop];
        }
      }
      return newObject;
    }
  }
}
