/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - app.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {LanguagePartialEnum} from "../../../enums/partial/language-partial.enum";
import {StatePartialEnum} from "../../../enums/partial/state-partial.enum";
import {ThemePartialEnum} from "../../../enums/partial/theme-partial.enum";
import {FrontendVersion} from "../../../models/frontend-version.model";
import {
  BaseAction,
  BaseSubAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
  MultiActionsCompletionsResult,
} from "../../../models/stores/base.action";
import {ExtendEnum} from "../../../types/extend-enum.type";
import {SolidifyAppNameSpace} from "./app-namespace.model";

const state = StatePartialEnum.application;

export namespace SolidifyAppAction {
  export class PreventExit extends BaseAction {
    static readonly type: string = `[${state}] Prevent Exit`;

    constructor(public reasonToTranslate?: string) {
      super();
    }
  }

  export class CancelPreventExit extends BaseAction {
    static readonly type: string = `[${state}] Cancel Prevent Exit`;
  }

  export class InitApplication extends BaseAction {
    static readonly type: string = `[${state}] Init Application`;
  }

  export class InitApplicationSuccess extends BaseSubActionSuccess<InitApplication> {
    static readonly type: string = `[${state}] Init Application Success`;

    constructor(public parentAction: InitApplication, public result: MultiActionsCompletionsResult) {
      super(parentAction);
    }
  }

  export class InitApplicationFail extends BaseSubActionFail<InitApplication> {
    static readonly type: string = `[${state}] Init Application Fail`;

    constructor(public parentAction: InitApplication, public result: MultiActionsCompletionsResult) {
      super(parentAction);
    }
  }

  export class InitApplicationParallel extends BaseAction {
    static readonly type: string = `[${state}] Init Application Parallel`;
  }

  export class InitApplicationParallelSuccess extends BaseSubActionSuccess<InitApplicationParallel> {
    static readonly type: string = `[${state}] Init Application Parallel Success`;
  }

  export class InitApplicationParallelFail extends BaseSubActionFail<InitApplicationParallel> {
    static readonly type: string = `[${state}] Init Application Fail`;

    constructor(public parentAction: InitApplicationParallel, public result: MultiActionsCompletionsResult) {
      super(parentAction);
    }
  }

  export class DefineFallbackLanguage extends BaseAction {
    static readonly type: string = `[${state}] Set Default Language`;
  }

  export class SetMomentLocal extends BaseAction {
    static readonly type: string = `[${state}] Set Moment Local`;
  }

  export class ChangeAppLanguage extends BaseAction {
    static readonly type: string = `[${state}] Change App Language`;

    constructor(public language: ExtendEnum<LanguagePartialEnum>) {
      super();
    }
  }

  export class ChangeAppLanguageSuccess extends BaseSubActionSuccess<ChangeAppLanguage> {
    static readonly type: string = `[${state}] Change App Language Success`;
  }

  export class ChangeAppTheme extends BaseAction {
    static readonly type: string = `[${state}] Change App Theme`;

    constructor(public theme: ExtendEnum<ThemePartialEnum>) {
      super();
    }
  }

  export class ChangeDarkMode extends BaseAction {
    static readonly type: string = `[${state}] Change Dark Mode`;

    constructor(public enabled: boolean) {
      super();
    }
  }

  export class Login extends BaseAction {
    static readonly type: string = `[${state}] Login`;
  }

  export class LoginSuccess extends BaseSubActionSuccess<Login> {
    static readonly type: string = `[${state}] Login Success`;
  }

  export class LoginFail extends BaseSubAction<Login> {
    static readonly type: string = `[${state}] Login Fail`;
  }

  export class GetAppVersion extends BaseAction {
    static readonly type: string = `[${state}] Get App Version`;
  }

  export class GetAppVersionSuccess extends BaseSubActionSuccess<GetAppVersion> {
    static readonly type: string = `[${state}] Get App Version Success`;

    constructor(parentAction: GetAppVersion, public appVersion: FrontendVersion) {
      super(parentAction);
    }
  }

  export class GetAppVersionFail extends BaseSubAction<GetAppVersion> {
    static readonly type: string = `[${state}] Get App Version Fail`;
  }

  export class Logout extends BaseAction {
    static readonly type: string = `[${state}] Logout`;
  }

  export class ChangeDisplaySidebarUserGuide extends BaseAction {
    static readonly type: string = `[${state}] Change Display Sidebar UserGuide`;

    constructor(public isOpen: boolean) {
      super();
    }
  }

  export class ChangeDisplayBannerCookieConsent extends BaseAction {
    static readonly type: string = `[${state}] Change Display Banner Cookie Consent`;

    constructor(public isOpen: boolean, public newCookiesAvailable: number | undefined = undefined) {
      super();
    }
  }

  export class ChangeDisplaySidebarCookieConsent extends BaseAction {
    static readonly type: string = `[${state}] Change Display Sidebar Cookie Consent`;

    constructor(public isOpen: boolean) {
      super();
    }
  }

  export class SetIsInTourMode extends BaseAction {
    static readonly type: string = `[${state}] Set Is In Tour Mode`;

    constructor(public isInTourMode: boolean) {
      super();
    }
  }

  export class SetIsTouchInterface extends BaseAction {
    static readonly type: string = `[${state}] Set Is Touch Interface`;

    constructor(public isTouchInterface: boolean) {
      super();
    }
  }

  export class InitiateNewLogin extends BaseAction {
    static readonly type: string = `[${state}] Initiate New Login`;
  }
}

export const solidifyAppActionNameSpace: SolidifyAppNameSpace = SolidifyAppAction;
