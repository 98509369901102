/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - abstract-core-internal.component.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  ChangeDetectorRef,
  Directive,
  Injector,
} from "@angular/core";
import {
  FloatLabelType,
  MatFormFieldAppearance,
} from "@angular/material/form-field";
import {LabelTranslateInterface} from "../../../label-translate-interface.model";
import {IconNamePartialEnum} from "../../enums/partial/icon-name-partial.enum";
import {DefaultSolidifyEnvironment} from "../../environments/environment.solidify-defaults";
import {ENVIRONMENT} from "../../injection-tokens/environment.injection-token";
import {LABEL_TRANSLATE} from "../../injection-tokens/label-to-translate.injection-token";
import {CoreAbstractAngularElement} from "../../models/angular/core-abstract-angular-element.model";
import {SsrUtil} from "../../utils/ssr.util";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractCoreInternalComponent extends CoreAbstractAngularElement {
  environment: DefaultSolidifyEnvironment;
  labelTranslateInterface: LabelTranslateInterface;
  changeDetector: ChangeDetectorRef;

  get appearanceInputMaterial(): MatFormFieldAppearance {
    return this.environment.appearanceInputMaterial;
  }

  get displayEmptyRequiredFieldInError(): boolean {
    return this.environment.displayEmptyRequiredFieldInError;
  }

  get positionLabelInputMaterial(): FloatLabelType {
    return this.environment.positionLabelInputMaterial;
  }

  get iconNameEnum(): typeof IconNamePartialEnum {
    return IconNamePartialEnum;
  }

  get ssrUtil(): typeof SsrUtil {
    return SsrUtil;
  }

  constructor(protected readonly _injector: Injector) {
    super();
    this.environment = _injector.get(ENVIRONMENT);
    this.labelTranslateInterface = _injector.get(LABEL_TRANSLATE);
    this.changeDetector = _injector.get(ChangeDetectorRef);
  }

  externalDetectChanges(): void {
    this.changeDetector.detectChanges();
  }
}
