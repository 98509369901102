import { NgModule } from "@angular/core";
import { FilterBarContainer } from "./filter-bar.container";
import { SharedModule } from "../../../../shared/shared.module";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { SliderModule } from "primeng/slider";
import { FormsModule } from "@angular/forms";
import { DaysSelectorPresentational } from "../presentationals/selectors/days/days.selector.presentational";
import { PeriodicitiesSelectorPresentational } from "../presentationals/selectors/periodicities/periodicities.selector.presentational";
import { TimesSliderPresentational } from "../presentationals/sliders/times/times-slider.presentational";
import { LanguagesSelectorPresentational } from "../presentationals/selectors/languages/languages.selector.presentational";

@NgModule({
  declarations: [
    FilterBarContainer,
    LanguagesSelectorPresentational,
    DaysSelectorPresentational,
    PeriodicitiesSelectorPresentational,
    TimesSliderPresentational
  ],
  imports: [SharedModule, NgxsFormPluginModule, SliderModule, FormsModule],
  exports: [FilterBarContainer]
})
export class FilterBarContainerModule {}
