/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - core-abstract-angular-element.model.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  DoCheck,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  Observable,
  Subscription,
} from "rxjs";
import {
  isFunction,
  isTruthyObject,
} from "../../tools/is/is.tool";
import {SubscriptionManager} from "../managers/subscription-manager.model";
import {CounterSubject} from "../observables/counter-subject.model";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class CoreAbstractAngularElement implements OnChanges, OnInit, DoCheck, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked, OnDestroy {

  /* Subscription manager */

  protected readonly _subscriptionManager: SubscriptionManager = new SubscriptionManager();

  /* Angular state */

  get changed(): boolean {
    return this._ngOnChangesCS.hasCount();
  }

  get changesCount(): number {
    return this._ngOnChangesCS.getValue();
  }

  private _initialized: boolean = false;

  get initialized(): boolean {
    return this._initialized;
  }

  get checked(): boolean {
    return this._ngDoCheckCS.hasCount();
  }

  get checksCount(): number {
    return this._ngDoCheckCS.getValue();
  }

  private _contentInitialized: boolean = false;

  get contentInitialized(): boolean {
    return this._contentInitialized;
  }

  get contentChecked(): boolean {
    return this._ngAfterContentCheckedCS.hasCount();
  }

  get contentChecksCount(): number {
    return this._ngAfterContentCheckedCS.getValue();
  }

  private _viewInitialized: boolean = false;

  get viewInitialized(): boolean {
    return this._viewInitialized;
  }

  get viewChecked(): boolean {
    return this._ngAfterViewCheckedCS.hasCount();
  }

  get viewChecksCount(): number {
    return this._ngAfterViewCheckedCS.getValue();
  }

  private _destroyed: boolean = false;

  get destroyed(): boolean {
    return this._destroyed;
  }

  /* Angular frequent lifecycle hooks counter subjects */

  private readonly _ngOnChangesCS: CounterSubject = new CounterSubject();

  private readonly _ngDoCheckCS: CounterSubject = new CounterSubject();

  private readonly _ngAfterContentCheckedCS: CounterSubject = new CounterSubject();

  private readonly _ngAfterViewCheckedCS: CounterSubject = new CounterSubject();

  /* Angular occasional lifecycle hooks event emitters */

  private readonly _ngOnInitEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _ngAfterContentInitEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _ngAfterViewInitEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _ngOnDestroyEventEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  /* Angular lifecycle hooks value event emitters */

  private readonly _ngOnChangesValueEventEmitter: EventEmitter<SimpleChanges> = new EventEmitter<SimpleChanges>();

  /* Angular lifecycle hooks base implementation */

  ngOnChanges(changes: SimpleChanges): void {
    // Fix ngOnInit
    if (this.changed && !this.initialized) {
      this.ngOnInit();
    }
    this._ngOnChangesValueEventEmitter.emit(changes);
    this._ngOnChangesCS.increment();
  }

  ngOnInit(): void {
    if (!this._initialized) {
      this._initialized = true;
      this._ngOnInitEventEmitter.emit(true);
    } else {
      this._ngOnInitEventEmitter.emit(false);
    }
  }

  ngDoCheck(): void {
    this._ngDoCheckCS.increment();
  }

  ngAfterContentInit(): void {
    if (!this._contentInitialized) {
      this._contentInitialized = true;
      this._ngAfterContentInitEventEmitter.emit(true);
    } else {
      this._ngAfterContentInitEventEmitter.emit(false);
    }
  }

  ngAfterContentChecked(): void {
    this._ngAfterContentCheckedCS.increment();
  }

  ngAfterViewInit(): void {
    if (!this._viewInitialized) {
      this._viewInitialized = true;
      this._ngAfterViewInitEventEmitter.emit(true);
    } else {
      this._ngAfterViewInitEventEmitter.emit(false);
    }
  }

  ngAfterViewChecked(): void {
    this._ngAfterViewCheckedCS.increment();
  }

  ngOnDestroy(): void {
    if (!this._destroyed) {
      this._destroyed = true;
      this._ngOnDestroyEventEmitter.emit(true);
    } else {
      this._ngOnDestroyEventEmitter.emit(false);
    }
    this._subscriptionManager.clearAndUnsubscribeAll();
  }

  /* Angular lifecycle hooks observables */

  readonly ngOnChangesObs: Observable<number> = this._ngOnChangesCS.asObservable();

  readonly ngOnChangesValueObs: Observable<SimpleChanges> = this._ngOnChangesValueEventEmitter.asObservable();

  readonly ngOnInitObs: Observable<boolean> = this._ngOnInitEventEmitter.asObservable();

  readonly ngDoCheckObs: Observable<number> = this._ngDoCheckCS.asObservable();

  readonly ngAfterContentInitObs: Observable<boolean> = this._ngAfterContentInitEventEmitter.asObservable();

  readonly ngAfterContentCheckedObs: Observable<number> = this._ngAfterContentCheckedCS.asObservable();

  readonly ngAfterViewInitObs: Observable<boolean> = this._ngAfterViewInitEventEmitter.asObservable();

  readonly ngAfterViewCheckedObs: Observable<number> = this._ngAfterViewCheckedCS.asObservable();

  readonly ngOnDestroyObs: Observable<boolean> = this._ngOnDestroyEventEmitter.asObservable();

  /* Detect changes */

  detectChanges(changeDetectorRef: ChangeDetectorRef): boolean {
    if (this.initialized
      && !this.destroyed
      && isTruthyObject(changeDetectorRef)
      && isFunction(changeDetectorRef.detectChanges)) {
      changeDetectorRef.detectChanges();
      return true;
    }
    return false;
  }

  /* Subscribe */

  subscribe<T>(observable: Observable<T>,
               onNext?: (value: T) => void,
               onError?: (error: Error) => void,
               onComplete?: () => void): Subscription {
    return this._subscriptionManager.subscribe(observable, onNext, onError, onComplete);
  }

}
