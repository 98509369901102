import { Teaching } from "../models/teaching.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { SolApiResponse, SolApiRespPageInfo, SolAppConfigService, SolSort } from "common-ng";
import { Observable } from "rxjs";
import { SearchForm } from "../models/search-form.model";
import { SearchServiceBase } from "./search-service-base";

@Injectable({
  providedIn: "root"
})
export class TeachingService extends SearchServiceBase {
  constructor(private _http: HttpClient, private _appConfig: SolAppConfigService) {
    super();
  }

  public searchTeachings(
    filters: SearchForm,
    pagination?: SolApiRespPageInfo,
    sorting?: SolSort
  ): Observable<SolApiResponse<Teaching>> {
    const httpParams = this.createHttpParams(filters, pagination);

    return this._http.get<SolApiResponse<Teaching>>(
      this._appConfig.config.apiRootUrl + "teachings/find",
      {
        params: httpParams
      }
    );
  }

  public getTeachingById(id: string): Observable<Teaching> {
    return this._http.get<Teaching>(this._appConfig.config.apiRootUrl + "teachings/" + id);
  }

  public getTeachingListFromTeachingListId(teachingListId: string[]): Observable<Teaching[]> {
    return this._http.post<Teaching[]>(
      this._appConfig.config.apiRootUrl + "teachings/by-ids",
      teachingListId
    );
  }
}
