/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - form-control-element-ref.directive.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from "@angular/core";
import {
  AbstractControl,
  NgControl,
  NgModel,
} from "@angular/forms";
import {isNotNullNorUndefined} from "../../tools/is/is.tool";
import {CoreAbstractDirective} from "../core-abstract/core-abstract.directive";

export const FORM_CONTROL_ELEMENT_REF: string = "elementRef";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[solidifyFormControlElementRef], [ngModel], [formControl], [formGroup], [formArray]",
})
export class FormControlElementRefDirective extends CoreAbstractDirective implements OnInit, OnDestroy {
  private _formControl: AbstractControl;

  @Input("solidifyFormControlElementRef")
  set formControl(value: AbstractControl) {
    this._formControl = value;
    if (isNotNullNorUndefined(this.formControl)) {
      this.formControl[FORM_CONTROL_ELEMENT_REF] = this._elementRef;
    }
  }

  get formControl(): AbstractControl {
    return this._formControl;
  }

  constructor(private readonly _elementRef: ElementRef,
              @Optional() private readonly _ngControl: NgControl,
              @Optional() private readonly _ngModel: NgModel) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (isNotNullNorUndefined(this._ngModel?.control)) {
      this._ngModel.control[FORM_CONTROL_ELEMENT_REF] = this._elementRef;
    } else if (isNotNullNorUndefined(this._ngControl?.control)) {
      this._ngControl.control[FORM_CONTROL_ELEMENT_REF] = this._elementRef;
    }
  }
}

