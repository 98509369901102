/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - resource-logo.action.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {
  BaseAction,
  BaseSubActionFail,
  BaseSubActionSuccess,
} from "../../../models/stores/base.action";
import {ResourceLogoNameSpace} from "./resource-logo-namespace.model";

export namespace ResourceLogoAction {
  export class GetPhoto extends BaseAction {
    static readonly type: string = `[{0}] Get Photo`;

    constructor(public resId: string) {
      super();
    }
  }

  export class GetPhotoSuccess extends BaseSubActionSuccess<GetPhoto> {
    static readonly type: string = `[{0}] Get Photo Success`;

    constructor(public parentAction: GetPhoto, public blob: Blob) {
      super(parentAction);
    }
  }

  export class GetPhotoFail extends BaseSubActionFail<GetPhoto> {
    static readonly type: string = `[{0}] Get Photo Fail`;
  }

  export class GetPhotoByResId extends BaseAction {
    static readonly type: string = `[{0}] Get Photo By ResId`;

    constructor(public resId: string) {
      super();
    }
  }

  export class GetPhotoByResIdSuccess extends BaseSubActionSuccess<GetPhotoByResId> {
    static readonly type: string = `[{0}] Get Photo By ResId Success`;

    constructor(public parentAction: GetPhotoByResId, public blob: Blob) {
      super(parentAction);
    }
  }

  export class GetPhotoByResIdFail extends BaseSubActionFail<GetPhotoByResId> {
    static readonly type: string = `[{0}] Get Photo By ResId Fail`;

    constructor(public parentAction: GetPhotoByResId) {
      super(parentAction);
    }
  }

  export class UploadPhoto extends BaseAction {
    static readonly type: string = `[{0}] Upload Photo`;

    constructor(public resId: string, public file: File) {
      super();
    }
  }

  export class UploadPhotoSuccess extends BaseSubActionSuccess<UploadPhoto> {
    static readonly type: string = `[{0}] Upload Photo Success`;

    constructor(public parentAction: UploadPhoto) {
      super(parentAction);
    }
  }

  export class UploadPhotoFail extends BaseSubActionFail<UploadPhoto> {
    static readonly type: string = `[{0}] Upload Photo Fail`;
  }

  export class DeletePhoto extends BaseAction {
    static readonly type: string = `[{0}] Delete Photo`;

    constructor(public resId: string, public file: File) {
      super();
    }
  }

  export class DeletePhotoSuccess extends BaseSubActionSuccess<DeletePhoto> {
    static readonly type: string = `[{0}] Delete Photo Success`;

    constructor(public parentAction: DeletePhoto) {
      super(parentAction);
    }
  }

  export class DeletePhotoFail extends BaseSubActionFail<DeletePhoto> {
    static readonly type: string = `[{0}] Delete Photo Fail`;
  }
}

export const resourceLogoActionNameSpace: ResourceLogoNameSpace | any = ResourceLogoAction;
