/*-
 * %%----------------------------------------------------------------------------------------------
 * Solidify Framework - Solidify Frontend - resource.state.ts
 * SPDX-License-Identifier: GPL-2.0-or-later
 * %----------------------------------------------------------------------------------------------%
 * Copyright (C) 2017 - 2023 University of Geneva
 * %----------------------------------------------------------------------------------------------%
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as
 * published by the Free Software Foundation, either version 2 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public
 * License along with this program.  If not, see
 * <http://www.gnu.org/licenses/gpl-2.0.html>.
 * ----------------------------------------------------------------------------------------------%%
 */

import {Type} from "@angular/core";
import {Navigate} from "@ngxs/router-plugin";
import {
  Actions,
  StateContext,
  Store,
} from "@ngxs/store";
import {
  Observable,
  of,
  pipe,
} from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import {RegisterDefaultAction} from "../../../decorators/store.decorator";
import {DispatchMethodEnum} from "../../../enums/stores/dispatch-method.enum";
import {SolidifyStateError} from "../../../errors/solidify-state.error";
import {ApiService} from "../../../http/api.service";
import {BaseResourceType} from "../../../models/dto/base-resource.model";
import {CollectionTyped} from "../../../models/dto/collection-typed.model";
import {SolidifyHttpErrorResponseModel} from "../../../models/errors/solidify-http-error-response.model";
import {QueryParameters} from "../../../models/query-parameters/query-parameters.model";
import {NotifierService} from "../../../models/services/notifier-service.model";
import {BaseStateModel} from "../../../models/stores/base-state.model";
import {ActionSubActionCompletionsWrapper} from "../../../models/stores/base.action";
import {
  isEmptyArray,
  isFalse,
  isFunction,
  isNonEmptyArray,
  isNullOrUndefined,
  isTrue,
} from "../../../tools/is/is.tool";
import {ObjectUtil} from "../../../utils/object.util";
import {QueryParametersUtil} from "../../../utils/query-parameters.util";
import {MemoizedUtil} from "../../../utils/stores/memoized.util";
import {ofSolidifyActionCompleted} from "../../../utils/stores/store.tool";
import {StoreUtil} from "../../../utils/stores/store.util";
import {BaseOptions} from "../base/base-options.model";
import {
  BaseState,
  defaultBaseStateInitValue,
} from "../base/base.state";
import {ResourceActionHelper} from "./resource-action.helper";
import {ResourceNameSpace} from "./resource-namespace.model";
import {ResourceOptions} from "./resource-options.model";
import {ResourceStateModel} from "./resource-state.model";
import {ResourceAction} from "./resource.action";

export const defaultResourceStateInitValue: <TResourceType extends BaseResourceType> () => ResourceStateModel<TResourceType> = () =>
  ({
    ...defaultBaseStateInitValue(),
    total: 0,
    list: undefined,
    current: undefined,
    queryParameters: new QueryParameters(), // TODO FIX IS NOT RESET
    listTemp: undefined,
    isLoadingChunk: false,
    listPendingResId: [],
    uploadStatus: [],
    listCurrentStatus: {},
    isLoadingCurrentStatus: 0,
  });

// @dynamic
export abstract class ResourceState<TStateModel extends BaseStateModel, TResource extends BaseResourceType> extends BaseState<TStateModel> {
  protected override readonly _nameSpace: ResourceNameSpace;
  protected override readonly _optionsState: ResourceOptions;

  protected constructor(protected _apiService: ApiService,
                        protected _store: Store,
                        protected _notificationService: NotifierService,
                        protected _actions$: Actions,
                        protected _options: ResourceOptions) {
    super(_apiService, _store, _notificationService, _actions$, _options, ResourceState);
  }

  protected static _getDefaultOptions(): ResourceOptions | any {
    let defaultOptions: ResourceOptions | any = {
      keepCurrentStateAfterCreate: false,
      keepCurrentStateAfterUpdate: false,
      keepCurrentStateAfterDelete: false,
      keepCurrentStateAfterDeleteList: false,
      routeReplaceAfterSuccessCreateAction: false,
      routeReplaceAfterSuccessUpdateAction: false,
      routeReplaceAfterSuccessDeleteAction: false,
      routeReplaceAfterSuccessDeleteListAction: false,
      autoScrollToFirstValidationError: true,
      updateSubResourceDispatchMethod: DispatchMethodEnum.SEQUENCIAL,
    } as ResourceOptions;
    defaultOptions = Object.assign(BaseState._getDefaultOptions(), defaultOptions);
    return defaultOptions;
  }

  protected static override _checkOptions(stateName: string, options: BaseOptions): void {
    BaseState._checkOptions(stateName, options);
  }

  // static isLoading<TStateModel extends BaseStateModel>(store: Store, ctor: Type<BaseState<TStateModel>>): Observable<boolean> {
  //   return MemoizedUtil.isLoading(store, ctor);
  // }

  static list<TStateModel extends ResourceStateModel<TResource>, TResource extends BaseResourceType>(store: Store, ctor: Type<ResourceState<TStateModel, TResource>>): Observable<TResource[]> {
    return MemoizedUtil.select(store, ctor, state => state.list, true);
  }

  static listSnapshot<TStateModel extends ResourceStateModel<TResource>, TResource extends BaseResourceType>(store: Store, ctor: Type<ResourceState<TStateModel, TResource>>): TResource[] {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.list);
  }

  static total<TStateModel extends ResourceStateModel<TResource>, TResource extends BaseResourceType>(store: Store, ctor: Type<ResourceState<TStateModel, TResource>>): Observable<number> {
    return MemoizedUtil.select(store, ctor, state => state.total, true);
  }

  static totalSnapshot<TStateModel extends ResourceStateModel<TResource>, TResource extends BaseResourceType>(store: Store, ctor: Type<ResourceState<TStateModel, TResource>>): number {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.total);
  }

  static current<TStateModel extends ResourceStateModel<TResource>, TResource extends BaseResourceType>(store: Store, ctor: Type<ResourceState<TStateModel, TResource>>): Observable<TResource> {
    return MemoizedUtil.select(store, ctor, state => state.current, true);
  }

  static currentSnapshot<TStateModel extends ResourceStateModel<TResource>, TResource extends BaseResourceType>(store: Store, ctor: Type<ResourceState<TStateModel, TResource>>): TResource {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.current);
  }

  static queryParameters<TStateModel extends ResourceStateModel<TResource>, TResource extends BaseResourceType>(store: Store, ctor: Type<ResourceState<TStateModel, TResource>>): Observable<QueryParameters> {
    return MemoizedUtil.select(store, ctor, state => state.queryParameters, true);
  }

  static queryParametersSnapshot<TStateModel extends ResourceStateModel<TResource>, TResource extends BaseResourceType>(store: Store, ctor: Type<ResourceState<TStateModel, TResource>>): QueryParameters {
    return MemoizedUtil.selectSnapshot(store, ctor, state => state.queryParameters);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.LoadResourceSuccess)
  loadResourceSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.LoadResourceSuccess): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.LoadResourceFail)
  loadResourceFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.LoadResourceFail): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.ChangeQueryParameters)
  changeQueryParameters(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.ChangeQueryParameters): void {
    ctx.patchState({
      queryParameters: action.queryParameters,
    });
    if (isTrue(action.getAllAfterChange)) {
      ctx.dispatch(ResourceActionHelper.getAll(this._nameSpace, undefined, action.keepCurrentContext));
    }
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetAll)
  getAll(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetAll): Observable<CollectionTyped<TResource>> {
    let reset = {};
    if (!action.keepCurrentContext) {
      reset = {
        list: undefined,
        total: 0,
      };
    }
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + 1,
      queryParameters: StoreUtil.getQueryParametersToApply(action.queryParameters, ctx),
      ...reset,
    });
    const baseUrl = isFunction(this._optionsState.apiPathGetAll) ? this._optionsState.apiPathGetAll() : this._urlResource;
    return this._apiService.getCollection<TResource>(baseUrl, ctx.getState().queryParameters)
      .pipe(
        action.cancelIncomplete ? StoreUtil.cancelUncompleted(ctx, this._actions$, [this._nameSpace.GetAll, Navigate]) : pipe(),
        tap((collection: CollectionTyped<TResource>) => {
          ctx.dispatch(ResourceActionHelper.getAllSuccess<TResource>(this._nameSpace, action, collection));
        }),
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(ResourceActionHelper.getAllFail(this._nameSpace, action));
          throw new SolidifyStateError(this, error);
        }),
      );
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetAllSuccess)
  getAllSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetAllSuccess<TResource>): void {
    const queryParameters = StoreUtil.updateQueryParameters(ctx, action.list);

    ctx.patchState({
      total: action.list._page?.totalItems,
      list: action.list._data,
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
      queryParameters,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetAllFail)
  getAllFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetAllFail<TResource>): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetByListId)
  getByListId(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetByListId): void {
    if (isNullOrUndefined(action.listResId) || isEmptyArray(action.listResId)) {
      return;
    }
    let listTemp = undefined;
    let reset = {};
    if (!action.keepCurrentContext) {
      reset = {
        list: undefined,
        listTemp: undefined,
      };
    } else {
      listTemp = ctx.getState().list;
    }

    const listSubAction = [];
    action.listResId.forEach(resId => {
      if (!isNullOrUndefined(listTemp) && !isEmptyArray(listTemp)) {
        const existingItem = listTemp.find(item => item.resId === resId);
        if (!isNullOrUndefined(existingItem)) {
          return;
        }
      }
      listSubAction.push({
        action: ResourceActionHelper.getById(this._nameSpace, resId, true, true),
        subActionCompletions: [
          this._actions$.pipe(ofSolidifyActionCompleted(this._nameSpace.GetByIdSuccess)),
          this._actions$.pipe(ofSolidifyActionCompleted(this._nameSpace.GetByIdFail)),
        ],
      });
    });

    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + 1,
      listTemp: listTemp,
      ...reset,
    });

    this.subscribe(StoreUtil.dispatchParallelActionAndWaitForSubActionsCompletion(ctx, listSubAction).pipe(
      tap(result => {
        if (result.success) {
          ctx.dispatch(ResourceActionHelper.getByListIdSuccess(this._nameSpace, action));
        } else {
          ctx.dispatch(ResourceActionHelper.getByListIdFail(this._nameSpace, action));
        }
      }),
    ));
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetByListIdSuccess)
  getByListIdSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetByListIdSuccess): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
      list: ctx.getState().listTemp,
      listTemp: undefined,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetByListIdFail)
  getByListIdFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetByListIdFail): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
      listTemp: undefined,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetById)
  getById(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetById): Observable<TResource> {
    let reset = {};
    if (!action.keepCurrentContext) {
      reset = {
        current: undefined,
      };
    }
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + 1,
      ...reset,
    });

    let apiResultObs = undefined;
    if (isFunction(this._optionsState.apiPathGetById)) {
      apiResultObs = this._apiService.get<TResource>(this._optionsState.apiPathGetById(action.id));
    } else {
      apiResultObs = this._apiService.getById<TResource>(this._urlResource, action.id);
    }
    return apiResultObs
      .pipe(
        tap((model: TResource) => {
          ctx.dispatch(ResourceActionHelper.getByIdSuccess(this._nameSpace, action, model));
        }),
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(ResourceActionHelper.getByIdFail(this._nameSpace, action));
          throw new SolidifyStateError(this, error);
        }),
      );
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetByIdSuccess)
  getByIdSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetByIdSuccess<TResource>): void {
    if (!isNullOrUndefined(action.parentAction) && isTrue(action.parentAction.addInListTemp)) {
      let list = ctx.getState().listTemp;
      if (isNullOrUndefined(list)) {
        list = [];
      }
      list = [...list, action.model];
      ctx.patchState({
        listTemp: list,
        isLoadingCounter: ctx.getState().isLoadingCounter - 1,
      });
      return;
    }

    ctx.patchState({
      current: action.model,
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.GetByIdFail)
  getByIdFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.GetByIdFail<TResource>): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.Create)
  create(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.Create<TResource>): Observable<TResource> {
    return this._internalCreate(ctx, action)
      .pipe(
        switchMap((model: TResource) =>
          this._updateSubResource(model, action, ctx).pipe(
            map(success => {
              if (success) {
                ctx.dispatch(ResourceActionHelper.createSuccess(this._nameSpace, action, model));
              } else {
                ctx.dispatch(ResourceActionHelper.createFail(this._nameSpace, action));
              }
              return model;
            }),
          )),
      );
  }

  protected _internalCreate(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.Create<TResource>): Observable<TResource> {
    this._cleanCurrentStateIfDefined(ctx, this._optionsState.keepCurrentStateBeforeCreate);
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + 1,
    });

    const model = action.modelFormControlEvent?.model;

    const baseUrl = isFunction(this._optionsState.apiPathCreate) ? this._optionsState.apiPathCreate() : this._urlResource;
    return this._apiService.post<TResource>(baseUrl, model)
      .pipe(
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(ResourceActionHelper.createFail(this._nameSpace, action));
          throw new SolidifyStateError(this, error);
        }),
        StoreUtil.catchValidationErrors(ctx, action.modelFormControlEvent, this._notificationService, this._optionsState.autoScrollToFirstValidationError),
      );
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.CreateSuccess)
  createSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.CreateSuccess<TResource>): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
      current: action.model,
    });

    StoreUtil.navigateIfDefined(ctx, this._optionsState.routeRedirectUrlAfterSuccessCreateAction, action.model.resId, this._optionsState.routeReplaceAfterSuccessCreateAction);
    StoreUtil.notifySuccess(this._notificationService, this._optionsState.notificationResourceCreateSuccessTextToTranslate);
    this._cleanCurrentStateIfDefined(ctx, this._optionsState.keepCurrentStateAfterCreate);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.CreateFail)
  createFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.CreateFail<TResource>): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
    StoreUtil.notifyError(this._notificationService, this._optionsState.notificationResourceCreateFailTextToTranslate);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.Update)
  update(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.Update<TResource>): Observable<TResource> {
    return this._internalUpdate(ctx, action)
      .pipe(
        switchMap((model: TResource) =>
          this._updateSubResource(model, action, ctx).pipe(
            map(success => {
              if (success) {
                ctx.dispatch(ResourceActionHelper.updateSuccess(this._nameSpace, action, model));
              } else {
                ctx.dispatch(ResourceActionHelper.updateFail(this._nameSpace, action));
              }
              return model;
            }),
          ),
        ),
      );
  }

  protected _internalUpdate(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.Update<TResource>): Observable<TResource> {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + 1,
    });

    const model = action.modelFormControlEvent?.model;

    let apiResultObs = undefined;
    if (isFunction(this._optionsState.apiPathUpdate)) {
      apiResultObs = this._apiService.patch<TResource>(this._optionsState.apiPathUpdate(model?.resId), model);
    } else {
      apiResultObs = this._apiService.patchById<TResource>(this._urlResource, model?.resId, model);
    }
    return apiResultObs
      .pipe(
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(ResourceActionHelper.updateFail(this._nameSpace, action));
          throw new SolidifyStateError(this, error);
        }),
        StoreUtil.catchValidationErrors(ctx, action.modelFormControlEvent, this._notificationService, this._optionsState.autoScrollToFirstValidationError),
      );
  }

  protected _updateSubResource(model: TResource, action: ResourceAction.Create<TResource> | ResourceAction.Update<TResource>, ctx: StateContext<ResourceStateModel<TResource>>): Observable<boolean> {
    const actions: ActionSubActionCompletionsWrapper[] = this._getListActionsUpdateSubResource(model, action, ctx);

    if (isNonEmptyArray(actions)) {
      if (this._optionsState.updateSubResourceDispatchMethod === DispatchMethodEnum.PARALLEL) {
        return StoreUtil.dispatchParallelActionAndWaitForSubActionsCompletion(ctx, actions).pipe(
          map(result => result.success),
        );
      } else if (this._optionsState.updateSubResourceDispatchMethod === DispatchMethodEnum.SEQUENCIAL) {
        return StoreUtil.dispatchSequentialActionAndWaitForSubActionsCompletion(ctx, actions).pipe(
          map(result => result.success),
        );
      }
    }
    return of(true);
  }

  protected _getListActionsUpdateSubResource(model: TResource, action: ResourceAction.Create<TResource> | ResourceAction.Update<TResource>, ctx: StateContext<ResourceStateModel<TResource>>): ActionSubActionCompletionsWrapper[] {
    return [];
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.UpdateSuccess)
  updateSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.UpdateSuccess<TResource>): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
      current: action.model,
    });

    StoreUtil.navigateIfDefined(ctx, this._optionsState.routeRedirectUrlAfterSuccessUpdateAction, action.model.resId, this._optionsState.routeReplaceAfterSuccessUpdateAction);
    StoreUtil.notifySuccess(this._notificationService, this._optionsState.notificationResourceUpdateSuccessTextToTranslate);
    this._cleanCurrentStateIfDefined(ctx, this._optionsState.keepCurrentStateAfterUpdate);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.UpdateFail)
  updateFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.UpdateFail<TResource>): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
    StoreUtil.notifyError(this._notificationService, this._optionsState.notificationResourceUpdateFailTextToTranslate);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.Delete)
  delete(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.Delete): Observable<TResource> {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + 1,
    });

    let apiResultObs = undefined;
    if (isFunction(this._optionsState.apiPathDelete)) {
      apiResultObs = this._apiService.delete<TResource>(this._optionsState.apiPathDelete(action.resId));
    } else {
      apiResultObs = this._apiService.deleteById<TResource>(this._urlResource, action.resId);
    }
    return apiResultObs
      .pipe(
        tap(() => {
          ctx.dispatch(ResourceActionHelper.deleteSuccess(this._nameSpace, action));
        }),
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(ResourceActionHelper.deleteFail(this._nameSpace, action));
          throw new SolidifyStateError(this, error);
        }),
      );
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.DeleteSuccess)
  deleteSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.DeleteSuccess): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });

    StoreUtil.navigateIfDefined(ctx, this._optionsState.routeRedirectUrlAfterSuccessDeleteAction, undefined, this._optionsState.routeReplaceAfterSuccessDeleteAction);
    StoreUtil.notifySuccess(this._notificationService, this._optionsState.notificationResourceDeleteSuccessTextToTranslate);
    this._cleanCurrentStateIfDefined(ctx, this._optionsState.keepCurrentStateAfterDelete);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.DeleteFail)
  deleteFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.DeleteFail): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
    StoreUtil.notifyError(this._notificationService, this._optionsState.notificationResourceDeleteFailTextToTranslate);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.DeleteList)
  deleteList(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.DeleteList): Observable<string[]> {
    if (action.listResId.length === 0) {
      ctx.dispatch(ResourceActionHelper.deleteListSuccess(this._nameSpace, action));
      return;
    }
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + 1,
    });
    return this._apiService.delete<string[]>(this._urlResource, action.listResId)
      .pipe(
        tap(() => {
          ctx.dispatch(ResourceActionHelper.deleteListSuccess(this._nameSpace, action));
        }),
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(ResourceActionHelper.deleteListFail(this._nameSpace, action));
          throw new SolidifyStateError(this, error);
        }),
      );
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.DeleteListSuccess)
  deleteListSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.DeleteListSuccess): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });

    StoreUtil.navigateIfDefined(ctx, this._optionsState.routeRedirectUrlAfterSuccessDeleteListAction, undefined, this._optionsState.routeReplaceAfterSuccessDeleteListAction);
    StoreUtil.notifySuccess(this._notificationService, this._optionsState.notificationResourceDeleteListSuccessTextToTranslate);
    this._cleanCurrentStateIfDefined(ctx, this._optionsState.keepCurrentStateAfterDeleteList);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.DeleteListFail)
  deleteListFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.DeleteListFail): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
    });
    StoreUtil.notifyError(this._notificationService, this._optionsState.notificationResourceDeleteListFailTextToTranslate);
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.AddInList)
  addInList(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.AddInList<TResource>): void {
    if (isNullOrUndefined(action.model)) {
      return;
    }
    let list = ctx.getState().list;
    if (isNullOrUndefined(list)) {
      list = [];
    }
    list = [...list, action.model];
    ctx.patchState({
      list: list,
      total: list.length,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.AddInListById)
  addInListById(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.AddInListById): Observable<TResource> {
    let indexAlreadyExisting = -1;
    if (!isNullOrUndefined(ctx.getState().list) && isTrue(action.avoidDuplicate)) {
      indexAlreadyExisting = ctx.getState().list.findIndex(item => item.resId === action.resId);
      if (indexAlreadyExisting !== -1 && isFalse(action.replace)) {
        return;
      }
    }
    if (ctx.getState().listPendingResId.find(pending => pending === action.resId)) {
      return;
    }
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter + 1,
      listPendingResId: [...(isNullOrUndefined(ctx.getState().listPendingResId) ? [] : ctx.getState().listPendingResId), action.resId],
    });
    return this._apiService.getById<TResource>(this._urlResource, action.resId)
      .pipe(
        tap((model: TResource) => {
          ctx.dispatch(ResourceActionHelper.addInListByIdSuccess(this._nameSpace, action, model, indexAlreadyExisting));
        }),
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(ResourceActionHelper.addInListByIdFail(this._nameSpace, action));
          throw new SolidifyStateError(this, error);
        }),
      );
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.AddInListByIdSuccess)
  addInListByIdSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.AddInListByIdSuccess<TResource>): void {
    if (isNullOrUndefined(action.model)) {
      return;
    }
    let list = ctx.getState().list;
    if (isNullOrUndefined(list)) {
      list = [];
    }
    if (action.indexAlreadyExisting !== -1 && isTrue(action.parentAction.replace)) {
      list = [...list];
      list[action.indexAlreadyExisting] = action.model;
    } else {
      const duplicateIndex = list.findIndex(s => s.resId === action.model.resId);
      if (duplicateIndex !== -1 && action.parentAction.avoidDuplicate) {
        list = [...list];
      } else {
        list = [...list, action.model];
      }
    }
    ctx.patchState({
      list: list,
      total: list.length,
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
      listPendingResId: this._getListPendingResIdWithValueRemoved(ctx, action.parentAction.resId),
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.AddInListByIdFail)
  addInListByIdFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.AddInListByIdFail<TResource>): void {
    ctx.patchState({
      isLoadingCounter: ctx.getState().isLoadingCounter - 1,
      listPendingResId: this._getListPendingResIdWithValueRemoved(ctx, action.parentAction.resId),
    });
  }

  private _getListPendingResIdWithValueRemoved(ctx: StateContext<ResourceStateModel<TResource>>, resId: string): string[] {
    let listPendingResId = ctx.getState().listPendingResId;
    const indexOf = listPendingResId.indexOf(resId);
    if (indexOf === -1) {
      return listPendingResId;
    }
    listPendingResId = [...listPendingResId];
    listPendingResId.splice(indexOf, 1);
    return listPendingResId;
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.RemoveInListById)
  removeInListById(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.RemoveInListById): void {
    const list = [...ctx.getState().list];
    if (isNullOrUndefined(action.resId) || isNullOrUndefined(list) || isEmptyArray(list)) {
      return;
    }
    const indexOfItemToRemove = list.findIndex(i => i.resId === action.resId);
    if (indexOfItemToRemove === -1) {
      return;
    }
    list.splice(indexOfItemToRemove, 1);
    ctx.patchState({
      list: list,
      total: list.length,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.RemoveInListByListId)
  removeInListByListId(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.RemoveInListByListId): void {
    const list = [...ctx.getState().list];
    if (isNullOrUndefined(action.listResId) || isEmptyArray(action.listResId) || isNullOrUndefined(list) || isEmptyArray(list)) {
      return;
    }
    action.listResId.forEach(resId => {
      const indexOfItemToRemove = list.findIndex(i => i.resId === resId);
      if (indexOfItemToRemove === -1) {
        return; // continue to iterate
      }
      list.splice(indexOfItemToRemove, 1);
    });
    ctx.patchState({
      list: list,
      total: list.length,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.LoadNextChunkList)
  loadNextChunkList(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.LoadNextChunkList): Observable<CollectionTyped<TResource>> {
    const queryParameters = QueryParametersUtil.clone(ctx.getState().queryParameters);
    if (!StoreUtil.isNextChunkAvailable(queryParameters)) {
      return;
    }

    queryParameters.paging.pageIndex = queryParameters.paging.pageIndex + 1;
    ctx.patchState({
      isLoadingChunk: true,
      queryParameters: queryParameters,
    });

    const baseUrl = isFunction(this._optionsState.apiPathGetAll) ? this._optionsState.apiPathGetAll() : this._urlResource;
    return this._apiService.getCollection<TResource>(baseUrl, ctx.getState().queryParameters)
      .pipe(
        action.cancelIncomplete ? StoreUtil.cancelUncompleted(ctx, this._actions$, [this._nameSpace.GetAll, Navigate]) : pipe(),
        tap((collection: CollectionTyped<TResource>) => {
          ctx.dispatch(ResourceActionHelper.loadNextChunkListSuccess<TResource>(this._nameSpace, action, collection));
        }),
        catchError((error: SolidifyHttpErrorResponseModel) => {
          ctx.dispatch(ResourceActionHelper.loadNextChunkListFail(this._nameSpace, action));
          throw new SolidifyStateError(this, error);
        }),
      );
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.LoadNextChunkListSuccess)
  loadNextChunkListSuccess(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.LoadNextChunkListSuccess<TResource>): void {
    const queryParameters = StoreUtil.updateQueryParameters(ctx, action.list);

    ctx.patchState({
      list: [...ctx.getState().list, ...action.list._data],
      total: action.list._page.totalItems,
      isLoadingChunk: false,
      queryParameters,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.LoadNextChunkListFail)
  loadNextChunkListFail(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.LoadNextChunkListFail): void {
    ctx.patchState({
      isLoadingChunk: false,
    });
  }

  @RegisterDefaultAction((resourceNameSpace: ResourceNameSpace) => resourceNameSpace.Clean)
  clean(ctx: StateContext<ResourceStateModel<TResource>>, action: ResourceAction.Clean): void {
    this._resetStateToDefault(ctx, isNullOrUndefined(action.preserveList) ? true : action.preserveList, action.queryParameter);
  }

  private _cleanCurrentStateIfDefined(ctx: StateContext<ResourceStateModel<TResource>>, keepCurrentState: boolean): void {
    if (isNullOrUndefined(keepCurrentState) || isFalse(keepCurrentState)) {
      ctx.dispatch(ResourceActionHelper.clean(this._nameSpace, true));
    }
  }

  private _resetStateToDefault(ctx: StateContext<ResourceStateModel<TResource>>, preserveListData: boolean, newQueryParameters?: QueryParameters): ResourceStateModel<TResource> {
    const oldList = ctx.getState().list;
    const oldTotal = ctx.getState().total;
    const oldQueryParameters = ctx.getState().queryParameters;
    const newState = this._getDefaultData();
    if (isTrue(preserveListData)) {
      newState.list = oldList;
      newState.total = oldTotal;
      newState.queryParameters = oldQueryParameters;
    }
    if (!isNullOrUndefined(newQueryParameters)) {
      newState.queryParameters = newQueryParameters;
    }
    return ctx.patchState(newState);
  }

  private _getDefaultData(): ResourceStateModel<TResource> {
    return ObjectUtil.clone(StoreUtil.getDefaultData(this.constructor), true);
  }
}
