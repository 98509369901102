import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { filter } from "rxjs/operators";
import { Range } from "./Range";

@Component({
  selector: "app-times-slider",
  templateUrl: "./times-slider.presentational.html",
  styleUrls: ["./times-slider.presentational.scss"]
})
export class TimesSliderPresentational implements OnInit {
  readonly MINIMUM: number = Range.MINIMUM;
  readonly MAXIMUM: number = Range.MAXIMUM;

  values: number[] = [this.MINIMUM, this.MAXIMUM];

  @Input() minControl: UntypedFormControl;
  @Input() maxControl: UntypedFormControl;

  ngOnInit(): void {
    this.minControl.valueChanges.pipe(filter(value => !value)).subscribe(() => {
      this.values = [this.MINIMUM, this._upperRange];
    });
    this.maxControl.valueChanges.pipe(filter(value => !value)).subscribe(() => {
      this.values = [this._lowerRange, this.MAXIMUM];
    });
  }

  displayTimesRange(): string {
    if (this._isMinimumRange() && this._isMaximumRange()) {
      return "tous les horaires";
    }
    if (this._isMinimumRange()) {
      return `jusqu'à ${this._upperRange}h`;
    }
    if (this._isMaximumRange()) {
      return `à partir de ${this._lowerRange}h`;
    }
    return `de ${this._lowerRange}h à ${this._upperRange}h`;
  }

  updateControlValue(): void {
    const minValue = this._isMinimumRange() ? null : this._lowerRange;
    const maxValue = this._isMaximumRange() ? null : this._upperRange;

    // updateControlValue called to often by p-slider onChange
    // so we update controls only when value really change to avoid useless requests
    if (this.minControl.value !== minValue) {
      this.minControl.setValue(minValue);
    }
    if (this.maxControl.value !== maxValue) {
      this.maxControl.setValue(maxValue);
    }
  }

  private get _lowerRange(): number {
    return this.values[0];
  }

  private get _upperRange(): number {
    return this.values[1];
  }

  private _isMinimumRange(): boolean {
    return this._lowerRange === this.MINIMUM;
  }

  private _isMaximumRange(): boolean {
    return this._upperRange === this.MAXIMUM;
  }
}
