<div
  class="chip-container"
  [class.has-error]="
    (emailErrorMessage && control.hasError('wrongMailFormat') && control.touched) ||
    getNeedToShowError()
  "
>
  <mat-label class="chip-label">{{ label }}</mat-label>

  <div class="chip-input-values">
    <div class="chip-list">
      <div *ngFor="let chipItem of chipList">
        <div class="chip-item" [class.has-error]="!checkRules(chipItem)">
          <div class="chip-item-value">{{ chipItem }}</div>

          <mat-icon
            *ngIf="!disableRemoveElement"
            class="chip-item-icon"
            (click)="onRemoveItem(chipItem)"
            >cancel
          </mat-icon>
        </div>
      </div>

      <div class="input-chip">
        <input
          [(ngModel)]="newChipItem"
          matInput
          data-test="guest-email-input"
          (ngModelChange)="onValueChanged($event)"
          (keydown.enter)="onBlurChange()"
          (blur)="onBlurChange()"
          [placeholder]="placeholder"
        />

        <mat-icon
          *ngIf="title"
          class="help-tooltip"
          [matTooltip]="title"
          matTooltipPosition="right"
          data-test="guest-email-input-help-tooltip"
          >help
        </mat-icon>
      </div>
    </div>
  </div>
</div>

<mat-error *ngIf="getNeedToShowError()">{{ errorMessage }}</mat-error>

<mat-error *ngIf="emailErrorMessage && control.hasError('wrongMailFormat') && control.touched">{{
  emailErrorMessage
}}</mat-error>
