<solidify-base-action-dialog [paramMessage]="data.paramTitle"
                             [titleToTranslate]="data.titleToTranslate"
                             [innerHtmlOnTitle]="data.innerHtmlOnTitle"
>
  <ng-template [ngIf]="data.messageToTranslate | isNonEmptyString">
    <ng-template [ngIf]="data.innerHtmlOnMessage | isTrue"
                 [ngIfElse]="normalMessage"
    >
      <p [innerHTML]="data.messageToTranslate | translate:data.paramMessage"></p>
    </ng-template>
    <ng-template #normalMessage>
      <p>{{data.messageToTranslate | translate:data.paramMessage}}</p>
    </ng-template>
  </ng-template>
  <form *ngIf="data.withInput | isNotNullNorUndefinedNorWhiteString"
        [formGroup]="form"
        class="form"
  >
    <mat-form-field *ngIf="getFormControl(formDefinition.textOrComment) as fd"
                    [appearance]="appearanceInputMaterial"
    >
      <mat-label *ngIf="data.inputLabelToTranslate | isNonEmptyString">{{data.inputLabelToTranslate | translate}}</mat-label>
      <textarea *ngIf="data.withInput === confirmDialogInputEnum.TEXTAREA"
                [formControl]="fd"
                [required]="formValidationHelper.hasRequiredField(fd)"
                [solidifyValidation]="errors"
                [maxlength]="data.inputMaxLimit"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="6"
                cdkTextareaAutosize
                solidifyAutoFocus
                matInput
      ></textarea>
      <input *ngIf="data.withInput === confirmDialogInputEnum.INPUT_TEXT"
             [formControl]="fd"
             [required]="formValidationHelper.hasRequiredField(fd)"
             [solidifyValidation]="errors"
             [maxlength]="data.inputMaxLimit"
             solidifyAutoFocus
             matInput
      >
      <mat-error #errors
                 solidifyTooltipOnEllipsis
      ></mat-error>
      <mat-hint *ngIf="data.inputMaxLimit">{{labelTranslateInterface.coreCharacters | translate}} :
        {{getFormControl(formDefinition.textOrComment).value.length}} /
        {{data.inputMaxLimit}}</mat-hint>
    </mat-form-field>
  </form>
</solidify-base-action-dialog>
<div [solidifyFocusFirstElement]="true"
     cdkTrapFocus
     mat-dialog-actions
>
  <button *ngIf="data.cancelButtonToTranslate | isNonEmptyString"
          [mat-dialog-close]=""
          mat-button
          [color]="colorCancel"

  >{{data.cancelButtonToTranslate | translate}}</button>
  <button *ngIf="data.confirmButtonToTranslate | isNonEmptyString"
          (click)="confirm()"
          [disabled]="data.withInput && form.invalid"
          [color]="colorConfirm"
          [solidifyMatButtonTheme]=""
          mat-flat-button
  >{{data.confirmButtonToTranslate | translate}}</button>
</div>
